import React, {useState} from 'react'
import $ from "jquery";
import toastr from 'toastr';
import 'toastr/build/toastr.css';
import { updateCollaborator } from '../../Models/Collaborators_model';

const ModalChangeEmail = ({setModalChangeEmailCollab, modalChangeEmailCollab, setRefreshTriggerToCanalizationData}) => {
    const { dataCollab, dataCollabAux } = modalChangeEmailCollab;
    const [data, setData] = useState({
        collaboratorId: dataCollab.idCollab,
        enterpriseId: dataCollab.idEnterprise,
        email: ""
    });

    const closeModal = () => {
        setModalChangeEmailCollab(prevState => {
            return {
                ...prevState,
                typeModal: 0,
                dataCollab: [],
                dataCollabAux:[],
                showModalEmail: false
            }
        })
    }

    const getValue = e => {
        // let regex = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
        let regex = /^\w+([.+-]?\w+)*@\w+(\.\w+)+$/;
        let value = e.target.value.trim();
        if (regex.test(value)) {
          $("#email").removeClass("border-danger-guay");
          setData({
            ...data,
            email: value
          });
        } else {
          $("#email").addClass("border-danger-guay");
          setData({
            ...data,
            email: value
          });
        }
    }

    const validatingFields = () => {
        let regex = /^\w+([.+-]?\w+)*@\w+(\.\w+)+$/;
        let flag = true;        
        if(data.collaboratorId === 0){
            toastr.warning('Hubo un problema en obtener la información del colaborador.', '¡Ooops!');
            flag = false;
        }else if(data.enterpriseId === 0){
            toastr.warning('Hubo un problema en obtener la información del colaborador.', '¡Ooops!');
            flag = false;
        }else if(data.email === "" || data.email.trim() === ""){
            toastr.warning('El Correo Electrónico Nuevo no puede estar vacio.', '¡Ooops!');
            flag = false;
        }else if(!regex.test(data.email)){
            toastr.warning('El campo Correo Electrónico Nuevo tiene un formato incorrecto.', '¡Ooops!');
            flag = false;
        }else if(data.email.trim().length > 100){
            toastr.warning('El campo Correo Electrónico Nuevo no tiene que tener más de 100 caracteres.', '¡Ooops!');
            flag = false;
        }else if(data.email.trim() === dataCollabAux.email){
            toastr.warning('El campo Correo Electrónico Nuevo es el mismo que Correo Electrónico Actual.', '¡Ooops!');
            flag = false;
        }else{
            flag = true;
        }
        return flag;
    }

    const handleUpdateCollaborator = () => {
        if(validatingFields() === true){
            let objData = {
                collaboratorId: data.collaboratorId,
                enterpriseId: data.enterpriseId,
                email: data.email,
            }

            console.log('objData: ',objData);
            updateCollaborator(data).then((response) => {
                if (response) {
                    if (response.validation) {
                        toastr.success(`El correo se actualizó correctamente.`, '¡Éxito!');
                        closeModal();
                        setRefreshTriggerToCanalizationData(prev => prev + 1); // Incrementa el estado para forzar la actualización del useEffect

                    }
                }

            })

        }
    }

  return (
    <div
        className="modal"
        id="collabChangeEmailModal"
        style={{ backgroundColor: 'rgba(255,255,255,0.5)', display: 'Block' }}
        data-backdrop="false"
    >
        <div className="modal-dialog modal-dialog-centered modal-lg">
            <div
                className="modal-content col-12"
                style={{ backgroundColor:'rgb(215, 219, 221)', border: 'none',  borderRadius: '1.5rem' }}
            >
                <div className="btn-close-4 d-flex justify-content-center flex-wrap align-content-center">
                    <button
                        type="button"
                        className="close text-white"
                        onClick={() => { closeModal()}}>
                        &times;
                    </button>
                </div>
                <div className="row my-2">
                    <div className="col-lg-12 d-flex justify-content-center align-items-center">
                        <h2 className="align-middle font-weight-bold text-blue-sec">Datos del colaborador</h2>
                    </div>
                    <div className="col-12 mt-1 px-3">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="text-blue-sec font-weight-bold fs-0875em">Nombre</label>
                                    <input
                                    type="text"
                                    name="nameCollab"
                                    className="form-control bg-gray input_border_radius fs-075em"
                                    disabled
                                    value={dataCollab.nameCollab} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="text-blue-sec font-weight-bold fs-0875em">Correo Electrónico Actual</label>
                                    <input
                                    type="email"
                                    name="emailActual"
                                    id="emailActual"
                                    placeholder="_@_._"
                                    className="form-control bg-gray input_border_radius fs-075em"
                                    disabled
                                    value={dataCollab.email} />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label className="text-blue-sec font-weight-bold fs-0875em">Correo Electrónico Nuevo</label>
                                    <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="_@_._"
                                    className="form-control text-blue-sec form-control input_border_radius fs-075em"
                                    maxLength="100"
                                    onChange={getValue}
                                    value={data.email}
                                     />
                                </div>
                            </div>
                            <div className="col-12">
                                <button
                                className="btn btn-blue-3 btn-sm pl-5 pr-5 pointer float-left"
                                onClick={() => closeModal()}>Cancelar</button>
                                <button
                                className="btn btn-pink btn-sm pl-5 pr-5 pointer float-right"
                                onClick={() => handleUpdateCollaborator()}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
      
    </div>
  )
}

export default ModalChangeEmail
