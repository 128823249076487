import React, {
    useState,
    useEffect,
} from 'react';
import { useParams } from 'react-router-dom';
// TODO: API Calls
import {
    validationAccessFCHTK,
    saveResponseHTK,
    updFinishSurveyHTK,
} from '../../Models/ClinicalFilterHTK_model';
// TODO: Dependencies
import * as toastr from 'toastr';
import { subDays } from 'date-fns';
import $ from 'jquery';
// TODO: Functions
import {
    //handleScroll,
    handleScrollStart,
    convertDateToYYYYMMDD,
} from '../../../helpers/helpers';
// TODO: Local Components
import LoaderSurvey from '../../LoaderSurvey';
import ModalLoaderGen from '../../ModalLoaderGen';

const HealthTracking = () => {
    // State
    // Array
    const [evaluationStructure, setEvaluationStructure] = useState([]);
    const [progressBarStructure, setProgressBarStructure] = useState([]);
    const [evaluationDates, setEvaluationDates] = useState([]);
    const [optionsStructure, setOptionsStructure] = useState([]);
    // Object
    const [generalData, setGeneralData] = useState({});
    // Boolean
    const [showEvaluation, setShowEvaluation] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [inProcess, setInProcess] = useState(false);
    // Other
    const [collabName, setCollabName] = useState('');

    // Obtener parametros de la liga
    const { identity } = useParams();
    useEffect(() => {
        let parser = "0-0-0-0";
        // Desencriptar Base64
        try {
            parser = atob(identity);
        } catch (error) {
            console.error(error);
        }
        parser = parser.split("-");
        // Asignar valores
        const idCollaborator = parser[0];
        const idEnterprise = parser[1];
        // Obtener estructura de evaluación
        validationAccessFCHTK(idCollaborator, idEnterprise).then(response => {
            switch (response.code) {
                case 1:
                    setCollabName(response.collabName);
                    // Valida si ya está contestado la encuesta de Filtro Clinico
                    if (response.statusCompleteFC === null) {
                        toastr.info("Se necesita contestar la encuesta de Filtro Clinico primero para mostrar la evaluación de Health Tracking.", "¡Ooops!");
                        setShowLoader(false);
                        return;
                    }
                    // Valida si ya está contestado la encuesta de HTK
                    if (response.statusCompleteHTK === 1) {
                        //toastr.info("Ya has respondido la evaluación de Health Tracking por hoy. Puedes empezar una nueva el día de mañana.", "¡Ooops!");
                        setShowLoader(false);
                        // Redirecciona a Preguntas Extra
                        window.location = "/habits/" + identity;
                        return;
                    }
                    // Guarda los datos generales del colaborador
                    setGeneralData({
                        idEnterprise: parseInt(idEnterprise),
                        idCollabSurveyControlFCHTK: response.idCollabSurveyControlFCHTK,
                        idCollaborator: parseInt(idCollaborator),
                    });
                    // Bandera para saber si ya tiene respuestas contestadas
                    const responsesFlag = response.responses.length > 0 ? true : false;
                    /**
                     * Función para crear objeto de array. Cada array tiene las respuestas guardadas por día de todas las secciones
                     * @param {*} responses 
                     * @returns objects
                     */
                    const getResponsesByDay = (responses) => {
                        // Crear un objeto para almacenar los arrays filtrados por cada key
                        const arraysPorKey = {
                            idAnswerDay1: [],
                            idAnswerDay2: [],
                            idAnswerDay3: [],
                            idAnswerDay4: [],
                            idAnswerDay5: [],
                        };
                        // Iterar sobre los registros y agruparlos en los arrays correspondientes
                        responses.forEach(respuesta => {
                            // Verificar si los campos idAnswerDay están definidos
                            if (respuesta.hasOwnProperty('idAnswerDay1')) {
                                arraysPorKey.idAnswerDay1.push({
                                    day: 1,
                                    answer: respuesta.idAnswerDay1,
                                    question: respuesta.IDResponseHTKInde.idQuestion,
                                });
                            }
                            if (respuesta.hasOwnProperty('idAnswerDay2')) {
                                arraysPorKey.idAnswerDay2.push({
                                    day: 2,
                                    answer: respuesta.idAnswerDay2,
                                    question: respuesta.IDResponseHTKInde.idQuestion,
                                });
                            }
                            if (respuesta.hasOwnProperty('idAnswerDay3')) {
                                arraysPorKey.idAnswerDay3.push({
                                    day: 3,
                                    answer: respuesta.idAnswerDay3,
                                    question: respuesta.IDResponseHTKInde.idQuestion,
                                });
                            }
                            if (respuesta.hasOwnProperty('idAnswerDay4')) {
                                arraysPorKey.idAnswerDay4.push({
                                    day: 4,
                                    answer: respuesta.idAnswerDay4,
                                    question: respuesta.IDResponseHTKInde.idQuestion,
                                });
                            }
                            if (respuesta.hasOwnProperty('idAnswerDay5')) {
                                arraysPorKey.idAnswerDay5.push({
                                    day: 5,
                                    answer: respuesta.idAnswerDay5,
                                    question: respuesta.IDResponseHTKInde.idQuestion,
                                });
                            }
                        });
                        return arraysPorKey;
                    }
                    // Obtiene las respuestas de la evaluación completa por día
                    const getAnswers = getResponsesByDay(response.responses);
                    //console.table(getAnswers);
                    /**
                     * Función para obtener la fecha con formato con base a los días especificados
                     * @param {*} date 
                     * @param {*} days 
                     * @returns array
                     */
                    const getPreviuosDays = (date, days) => {
                        // Obtener la fecha actual
                        const fechaActual = date;
                        let datesArray = [
                            {
                                dayId: 1,
                                day: convertDateToYYYYMMDD(fechaActual),
                            },
                        ];
                        for (let i = 1; i < days + 1; i++) {
                            const fullDay = subDays(fechaActual, parseInt(i));
                            datesArray.push({
                                dayId: parseInt(i) + 1,
                                day: convertDateToYYYYMMDD(fullDay),
                            });
                        }
                        return datesArray;
                    }
                    // Obtiene las fechas de los días a evaluar con el formato yyyy/mm/dd
                    const datesArray = getPreviuosDays(new Date(response.beginDate), 4);
                    //console.table(datesArray);
                    // Guardar las fechas con formato en el state
                    setEvaluationDates(datesArray);
                    /**
                     * Obtiene los días pasados de acuerdo a los parametros con el formato día - mes
                     * @param {*} date 
                     * @param {*} days 
                     * @returns 
                     */
                    const getPreviuosDaysFormat = (date, days) => {
                        // Obtener la fecha actual
                        const fechaActual = date;
                        let datesArray = [];
                        for (let i = 1; i < days + 1; i++) {
                            const fullDay = subDays(fechaActual, parseInt(i));
                            const monthsCatalog = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];
                            const mes = fullDay.getMonth();
                            const dia = fullDay.getDate();
                            datesArray.push({
                                dayId: parseInt(i) + 1,
                                day: `${dia} - ${monthsCatalog[mes]}`,
                            });
                        }
                        return datesArray;
                    }
                    const datesArrayFormat = getPreviuosDaysFormat(new Date(response.beginDate), 4);
                    //console.table(datesArrayFormat);
                    // Obtener la estrucutra: Seccción -> Preguntas -> Respuestas
                    const getEvaluationStructure = response.sections.map((section, index, fullArray) => {
                        // Titulos por sección
                        const title = [
                            'Estado de ánimo',
                            'Síntomas físicos',
                        ];
                        // Parrafos por sección
                        const parrafo = [
                            <p className='m-0'>Identifica <span className='font-weight-bold'>con qué intensidad has sentido</span> las siguientes emociones o y sentimientos durante el día</p>,
                            <p className='m-0'>¿Con qué intensidad has vivido los siguientes síntomas o respuestas físicas?</p>
                        ];
                        // Obtiene las opciones para responder agregando la bandera check
                        const optionsArray = response.options.map(option => { return { ...option, check: false } });
                        /**
                         * Devuelve un array con las respuestas de acuerdo a los días asignados en los argumentos
                         * @param {*} days 
                         * @returns 
                         */
                        const setAnswersStructure = (days) => {
                            let answersArray = [];
                            for (let i = 1; i < days + 1; i++) {
                                answersArray.push({
                                    day: parseInt(i),
                                    answers: optionsArray,
                                });
                            }
                            return answersArray;
                        }
                        // Obtiene las respuestas guardadas por día
                        const answersArray = setAnswersStructure(5);
                        //console.table(answersArray);
                        /**
                         * Devuelve un array para registrar los días completados de acuerdo con los días asignados en el argumento
                         * @param {*} days 
                         * @returns array
                         */
                        const setCompleteDayFlag = (days) => {
                            let completeDayArray = [];
                            for (let i = 1; i < days + 1; i++) {
                                completeDayArray.push({
                                    day: parseInt(i),
                                    status: false,
                                });
                            }
                            return completeDayArray;
                        }
                        /**
                         * Función para marcar los días completados por sección
                         * @param {*} sectionId 
                         * @param {*} flag 
                         * @param {*} answers 
                         * @returns 
                         */
                        const setCompleteDay = (sectionId, flag, answers) => {
                            if (flag) {
                                // Obtiene la estructura para marcar los días completados por sección
                                const completeDayArray = setCompleteDayFlag(5);
                                //console.log(completeDayArray)
                                const days = 5; // Días a evaluar
                                let newCompleteDayArray = []; // Array para guardar los días completados
                                for (let i = 0; i < days; i++) {
                                    if (sectionId === 1) {
                                        //console.log(answers[`idAnswerDay${parseInt(i) + 1}`])
                                        // Obtiene las respuestas guardadas por día
                                        const questionCounter = answers[`idAnswerDay${parseInt(i) + 1}`].filter(objeto => objeto.question <= 10);
                                        //console.log(questionCounter)
                                        // Evalua si las respuestas obtenidas son igual al total de preguntas.
                                        // TODO: Si se cambia el total de preguntas para la sección se debe modificar el número por default
                                        if (questionCounter.length === 10) {
                                            newCompleteDayArray.push({
                                                ...completeDayArray[i],
                                                status: true,
                                            });
                                        } else {
                                            newCompleteDayArray.push({ ...completeDayArray[i] });
                                        }
                                    }
                                    if (sectionId === 2) {
                                        //console.log(answers[`idAnswerDay${parseInt(i) + 1}`])
                                        // Obtiene las respuestas guardadas por día
                                        const questionCounter = answers[`idAnswerDay${parseInt(i) + 1}`].filter(objeto => objeto.question > 10);
                                        //console.log(questionCounter)
                                        // Evalua si las respuestas obtenidas son igual al total de preguntas.
                                        // TODO: Si se cambia el total de preguntas para la sección se debe modificar el número por default
                                        if (questionCounter.length === 10) {
                                            newCompleteDayArray.push({
                                                ...completeDayArray[i],
                                                status: true,
                                            });
                                        } else {
                                            newCompleteDayArray.push({ ...completeDayArray[i] });
                                        }
                                    }
                                }
                                return newCompleteDayArray;
                            } else {
                                return setCompleteDayFlag(5);
                            }
                        }
                        // Obtiene la estructura de días a evaluar por sección
                        const getCompleteDay = setCompleteDay(section.idSectionHTKInde, responsesFlag, getAnswers);
                        //console.log(getCompleteDay);
                        /**
                         * Función para marcar las respuestas guardadas con su formato por sección
                         * @param {*} sectionId 
                         * @param {*} flag 
                         * @param {*} answers 
                         * @returns 
                         */
                        const setQuestionsBySection = (sectionId, flag, answers) => {
                            let questionArray = [];
                            response.questions.forEach(question => {
                                if (question.idSectionHTKInde === sectionId) {
                                    if (flag) {
                                        const days = 5; // Días a evaluar
                                        // Obtiene las opciones para responder agregando la bandera check
                                        const genericOptionsArray = response.options.map(option => { return { ...option, check: false } });
                                        let newAnswersArray = [];
                                        for (let i = 1; i < days + 1; i++) {
                                            //console.log(answers[`idAnswerDay${parseInt(i)}`].length);
                                            if (answers[`idAnswerDay${parseInt(i)}`].length > 0) {
                                                let newOptionsArray = [];
                                                //console.table(answers[`idAnswerDay${parseInt(i)}`]);
                                                answers[`idAnswerDay${parseInt(i)}`].forEach(answerKey => {
                                                    //console.log(answerKey.question);
                                                    //console.log(question.idQuestionHTKInde);
                                                    if (answerKey.question === question.idQuestionHTKInde) {
                                                        const checkOptionsArray = response.options.map(option => {
                                                            if (option.idAnswerHTKInde === answerKey.answer) {
                                                                return { ...option, check: true }
                                                            } else {
                                                                return { ...option, check: false }
                                                            }
                                                        });
                                                        //console.table(checkOptionsArray);
                                                        newOptionsArray = checkOptionsArray;
                                                    }
                                                });
                                                //console.table(newOptionsArray);
                                                if (newOptionsArray.length === 0) {
                                                    newAnswersArray.push({
                                                        day: parseInt(i),
                                                        answers: genericOptionsArray,
                                                    });
                                                } else {
                                                    newAnswersArray.push({
                                                        day: parseInt(i),
                                                        answers: newOptionsArray,
                                                    });
                                                }
                                            } else {
                                                newAnswersArray.push({
                                                    day: parseInt(i),
                                                    answers: genericOptionsArray,
                                                });
                                            }
                                        }
                                        //console.table(newAnswersArray);
                                        questionArray.push({
                                            ...question,
                                            answers: newAnswersArray,
                                        });
                                    } else {
                                        questionArray.push({
                                            ...question,
                                            answers: answersArray,
                                        });
                                    }
                                }
                            });
                            return questionArray;
                        }
                        // Obtiene las preguntas con las respuestas por seccción
                        const questionArray = setQuestionsBySection(section.idSectionHTKInde, responsesFlag, getAnswers);
                        //console.table(questionArray);
                        /**
                         * Función para agregar los colores por sección
                         * @param {*} idSection 
                         * @returns array
                         */
                        const setColorSection = (idSection) => {
                            let colorArray = [];
                            switch (idSection) {
                                case 1:
                                    colorArray.push({
                                        background: '#76B1CA',
                                        border: '#76B1CA',
                                        barprogress: '#2182AC',
                                        barprogressAnswered: '#93BDD0',
                                        paragraph: '#D5E5EC',
                                        backgroundBullet1: '#C1D8F0',
                                        backgroundBullet2: '#94ACE9',
                                        backgroundBullet3: '#6166DC',
                                        backgroundBullet4: '#321092',
                                        borderBullet1: '#C1D8F0',
                                        borderBullet2: '#94ACE9',
                                        borderBullet3: '#6166DC',
                                        borderBullet4: '#321092',
                                    });
                                    break;
                                case 2:
                                    colorArray.push({
                                        background: '#CEDA9E',
                                        border: '#CEDA9E',
                                        barprogress: '#9CB537',
                                        barprogressAnswered: '#C8D693',
                                        paragraph: '#EDF4D4',
                                        backgroundBullet1: '#C1D8F0',
                                        backgroundBullet2: '#94ACE9',
                                        backgroundBullet3: '#6166DC',
                                        backgroundBullet4: '#321092',
                                        borderBullet1: '#C1D8F0',
                                        borderBullet2: '#94ACE9',
                                        borderBullet3: '#6166DC',
                                        borderBullet4: '#321092',
                                    });
                                    break;
                                default:
                                    colorArray.push({
                                        background: '#76B1CA',
                                        border: '#76B1CA',
                                        barprogress: '#2182AC',
                                        paragraph: '#D5E5EC',
                                        backgroundBullet1: '#C1D8F0',
                                        backgroundBullet2: '#94ACE9',
                                        backgroundBullet3: '#6166DC',
                                        backgroundBullet4: '#321092',
                                        borderBullet1: '#C1D8F0',
                                        borderBullet2: '#94ACE9',
                                        borderBullet3: '#6166DC',
                                        borderBullet4: '#321092',
                                    });
                                    break;
                            }
                            return colorArray;
                        }
                        // Obtiene los colores por sección
                        const colorSection = setColorSection(section.idSectionHTKInde);
                        //console.table(colorSection);
                        /**
                         * Función para obtener el día que falta por contestar de acuerdo a las respuestas guardadas
                         * @param {*} sectionId 
                         * @param {*} flag 
                         * @param {*} answers 
                         * @returns integer
                         */
                        const setDay = (sectionId, flag, answers) => {
                            if (flag) {
                                const days = 5;
                                let counter = 0;
                                let question = 10;
                                switch (sectionId) {
                                    case 2:
                                        question = 10;
                                        break;
                                    default:
                                        question = 10;
                                        break;
                                }
                                for (let i = 1; i < days + 1; i++) {
                                    //console.log(answers[`idAnswerDay${parseInt(i)}`].length);
                                    if (sectionId === 1) {
                                        // Obtener respuestas de la sección por día
                                        //console.log(answers[`idAnswerDay${parseInt(i)}`])
                                        const questionCounter = answers[`idAnswerDay${parseInt(i)}`].filter(objeto => objeto.question <= question);
                                        //console.log(questionCounter)
                                        if (questionCounter.length === 10) {
                                            counter = counter + 1;
                                        } else if (questionCounter.length > 0) {
                                            counter = counter + 1;
                                        }
                                    }
                                    if (sectionId === 2) {
                                        //console.log(answers[`idAnswerDay${parseInt(i)}`])
                                        const questionCounter = answers[`idAnswerDay${parseInt(i)}`].filter(objeto => objeto.question > question);
                                        //console.log(questionCounter)
                                        if (questionCounter.length === 10) {
                                            counter = counter + 1;
                                        } else if (questionCounter.length > 0) {
                                            counter = counter + 1;
                                        }
                                    }
                                }
                                return counter === 0 ? 1 : counter;
                            } else {
                                return 1
                            }
                        }
                        // Obtener día a evaluar por sección
                        const getDay = setDay(section.idSectionHTKInde, responsesFlag, getAnswers);
                        //console.log(getDay)
                        /**
                         * Función para marcar si la sección ha sido completada
                         * @param {*} completeDayArray 
                         * @returns boolean
                         */
                        /* const setCompleteSection = (completeDayArray) => {
                            const getCompletedDays = completeDayArray.filter(dayKey => dayKey.status);
                            const flag = getCompletedDays.length === 5 ? true : false;
                            return flag;
                        } */
                        //console.log(setCompleteSection(getCompleteDay));
                        /**
                         * Función para marcar la bandera para habilitar la sección
                         * @param {*} sectionId 
                         * @param {*} flag 
                         * @param {*} answers 
                         * @returns boolean
                         */
                        const setShowSection = (sectionId, flag, answers) => {
                            if (flag) {
                                if (sectionId === 1) {
                                    return true;
                                }
                                let question = 10;
                                switch (sectionId) {
                                    case 2:
                                        question = 10;
                                        break;
                                    default:
                                        question = 10;
                                        break;
                                }
                                // Se valida que la primera sección tenga todas las respuestas del ultimo día para habilitar la sección 2
                                const questionCounter = answers.idAnswerDay5.filter(objeto => objeto.question <= question);
                                const showSection = questionCounter.length === question ? true : false;
                                return showSection;
                            } else {
                                if (sectionId === 1) {
                                    return true;
                                } else {
                                    return false;
                                }
                            }

                        }
                        //console.log(setShowSection(section.idSectionHTKInde, responsesFlag, getAnswers));
                        // Obtener la bandera showSection
                        const getShowSection = setShowSection(section.idSectionHTKInde, responsesFlag, getAnswers);
                        // Estructura de la sección
                        const sectionObj = {
                            ...section,
                            dates: datesArrayFormat,
                            questions: questionArray,
                            day: getDay,
                            completeDayFlag: getCompleteDay,
                            colorSection: colorSection,
                            paragraph: parrafo[index],
                            completeSection: getDay === 5 ? true : false,
                            showSection: getShowSection,
                            description: title[index],
                        };
                        return sectionObj;
                    });
                    //console.table(getEvaluationStructure);
                    /**
                     * Funcipon para la estructura de la barra de progreso
                     * @param {*} flag 
                     * @param {*} responseStructure 
                     * @param {*} structure 
                     * @returns array
                     */
                    const getProgressBar = (flag, responseStructure, structure) => {
                        // Titulos por sección
                        const title = [
                            'Estado de ánimo',
                            'Síntomas físicos',
                        ];
                        // Set base structure
                        const getProgressStructure = responseStructure.map((section, index) => {
                            return {
                                description: title[index],
                                idSectionHTKInde: section.idSectionHTKInde,
                                progress: 0,
                            }
                        });
                        //console.table(getProgressStructure);
                        if (flag) {
                            // TODO: Proceso para actualizar la barra de porcentaje
                            const newEvaluationStructureCopy = structure;
                            // Obtener respuestas contestadas
                            const getAnsweredQuestions = newEvaluationStructureCopy.map(sectionKey => {
                                let answersArray = [];
                                sectionKey.questions.forEach(questionKey => {
                                    questionKey.answers.forEach(answerKey => {
                                        answerKey.answers.forEach(answer => {
                                            if (answer.check) {
                                                answersArray.push({
                                                    ...answerKey,
                                                });
                                            }
                                        });
                                    });
                                });
                                return { section: sectionKey.idSectionHTKInde, answers: answersArray };
                            });
                            //console.table(getAnsweredQuestions);
                            /**
                             * Función para contar las respuestas del array getAnswers
                             * @param {*} days 
                             * @param {*} answers 
                             * @param {*} sections 
                             * @returns array
                             */
                            const counterAnswers = (days, answers, sections) => {
                                let answerCounter = [];
                                sections.forEach(section => {
                                    for (let i = 1; i < days + 1; i++) {
                                        let counter = 0;
                                        answers.forEach(key => {
                                            if (key.section === section.idSectionHTKInde) {
                                                key.answers.forEach(keykey => {
                                                    if (keykey.day === parseInt(i)) {
                                                        counter = counter + 1;
                                                    }
                                                });
                                                answerCounter.push({
                                                    section: section.idSectionHTKInde,
                                                    day: parseInt(i),
                                                    answers: counter,
                                                });
                                            }
                                        });
                                    }
                                });
                                return answerCounter;
                            }
                            const getCounterAnswers = counterAnswers(5, getAnsweredQuestions, newEvaluationStructureCopy);
                            //console.table(getCounterAnswers);
                            // Obtiene el porcentaje de acuerdo con las respuestas contadas
                            const getCountAnswers = newEvaluationStructureCopy.map(sectionKey => {
                                let counter = 0;
                                getCounterAnswers.forEach(answersKey => {
                                    if (sectionKey.idSectionHTKInde === answersKey.section) {
                                        counter = counter + answersKey.answers
                                    }
                                });
                                const numeroTotalPreguntas = sectionKey.questions.length * 5; // Se pone el 5 porque son 5 días por sección
                                const numeroPreguntasContestadas = counter;
                                const porcentajeContestado = (numeroPreguntasContestadas / numeroTotalPreguntas) * 100;
                                return { section: sectionKey.idSectionHTKInde, porcentaje: Math.round(porcentajeContestado) };
                            });
                            // Copia de la estructura
                            const progressBarStructureCopy = getProgressStructure;
                            let getProgressBarStructure = [];
                            // Se actualiza la estructura de progressBarStructure con el porcentaje actual
                            progressBarStructureCopy.forEach(progressBar => {
                                getCountAnswers.forEach(counter => {
                                    if (progressBar.idSectionHTKInde === counter.section) {
                                        getProgressBarStructure.push({
                                            ...progressBar,
                                            progress: counter.porcentaje
                                        });
                                    }
                                });
                            });
                            //console.table(getProgressBarStructure);
                            return getProgressBarStructure;
                        } else {
                            return getProgressStructure;
                        }
                    }
                    // Obtener la estructura de la barra de progreso
                    const getProgressBarStructure = getProgressBar(responsesFlag, response.sections, getEvaluationStructure);
                    //console.table(getProgressBarStructure);
                    // Se guarda la estructura inicial de la evaluación en el state
                    setEvaluationStructure(getEvaluationStructure);
                    // Se guarda la estructura inicial de la barra de progreso en el state
                    setProgressBarStructure(getProgressBarStructure);
                    // Se guarda la estructura inicial de las opciones disponibles en el state
                    setOptionsStructure(response.options);
                    // Se muestra la evaluación
                    setShowEvaluation(true);
                    // Se quita el loader
                    setShowLoader(false);
                    // Muestra la sección a responder
                    if (responsesFlag) {
                        let sectionKey = 1;
                        getEvaluationStructure.forEach(key => {
                            if (key.showSection) {
                                sectionKey = key.idSectionHTKInde
                            }
                        });
                        //handleScroll(`sectionId=${sectionKey}`);
                        if (sectionKey === 1) {
                            handleScrollStart(`sectionId=${0}`);
                        } else {
                            handleScrollStart(`sectionId=${sectionKey - 1}`);
                        }
                    }
                    break;
                case 1001:
                    toastr.info("La empresa o el colaborador no están activos. Por favor verificar que ambos casos estén activos.", "¡Ooops!");
                    setShowLoader(false);
                    break;
                default:
                    toastr.error(`Error: ${response.code}. Intente nuevamente. Si el error persiste contactar al equipo de soporte.`, "¡Ooops!");
                    setShowLoader(false);
                    break;
            }
        });
    }, [identity]);
    /**
     * Maneja la función de los botones para cambiar de día
     * @param {*} sectionId 
     * @param {*} selected 
     */
    function handleSectionDay(sectionId, selected) {
        // selected = 1 significa día anterior
        // selected = 2 significa día siguiente
        if (selected === 1) {
            const copyEvaluationStructure = evaluationStructure;
            const newEvaluationStructure = copyEvaluationStructure.map(section => {
                const selectedDay = section.day === 1 || section.day === 2 ? 1 : section.day - 1;
                if (section.idSectionHTKInde === sectionId) {
                    return { ...section, day: selectedDay }
                } else {
                    return section
                }
            });
            setEvaluationStructure(newEvaluationStructure);
            if (sectionId === 1) {
                handleScrollStart(`sectionId=${0}`);
            } else {
                handleScrollStart(`sectionId=${sectionId - 1}`);
            }
        } else {
            const copyEvaluationStructure = evaluationStructure;
            const newEvaluationStructure = copyEvaluationStructure.map(section => {
                const selectedDay = section.day === 5 ? 5 : section.day + 1;
                if (section.idSectionHTKInde === sectionId) {
                    return { ...section, day: selectedDay }
                } else {
                    return section
                }
            });
            setEvaluationStructure(newEvaluationStructure);
            if (sectionId === 1) {
                handleScrollStart(`sectionId=${0}`);
            } else {
                handleScrollStart(`sectionId=${sectionId - 1}`);
            }
        }
    }
    /**
     * Meneja la función de seleccionar una respuesta
     * @param {*} day 
     * @param {*} section 
     * @param {*} question 
     * @param {*} option 
     */
    const handleSelectOption = (day, section, question, option) => {
        // Validación para permitir guardar otra respuesat mientras está en proceso de guardado
        /* if (inProcess) {
            return;
        } */
        // Obtiene la fecha que se evalua
        const getDayDate = evaluationDates.filter(dayKey => dayKey.dayId === day);
        // Objeto para endpoint
        const requestObj = {
            ...generalData,
            idAnswerHTKInde: option,
            idQuestionHTKInde: question,
            day: day,
            dateDay: getDayDate[0].day,
        };
        //console.log(requestObj)
        // Endpoint para guardar la respuesta
        saveResponseHTK(requestObj).then(response => {
            if (response) {
                if (response.validation) {
                    // TODO: Proceso para actualizar las respuestas seleccionadas
                    //console.log(day, section, question, option);
                    //console.log(evaluationStructure);
                    /**
                     * Se crea la función para marcar la respuesta
                     * @param {*} arr 
                     * @param {*} idAnswer 
                     * @returns array
                     */
                    const checkAnswer = (arr, idAnswer) => {
                        //console.log(arr);
                        // Copia del array original
                        const answersArr = arr;
                        // Pone los check en false
                        const cleanAnswersArr = answersArr.map(answer => { return { ...answer, check: false } });
                        // Marca el check seleccionado en true
                        const checkAnswerArr = cleanAnswersArr.map(answer => {
                            if (answer.idAnswerHTKInde === idAnswer) {
                                return { ...answer, check: true }
                            } else {
                                return answer;
                            }
                        });
                        return checkAnswerArr;
                    }
                    /**
                     * Se crea la función para verificar si existe respuesta y ayudar al contador de respuestas completas por día
                     * @param {*} arr 
                     * @param {*} counter 
                     * @param {*} day 
                     * @returns boolean
                     */
                    const verifyAnswer = (arr, counter, day) => {
                        //console.log(arr)
                        let flag = false;
                        arr.forEach(answerKey => {
                            if (answerKey.day === day) {
                                const getCheck = answerKey.answers.filter(key => key.check === true);
                                //console.log(getCheck)
                                flag = getCheck.length > 0 ? true : false;
                            }
                        });
                        //console.log(flag);
                        return flag;
                    }
                    /**
                     * Se crea la función para marcar el día que tenga todas las preguntas contestadas
                     * @param {*} arr 
                     * @param {*} day 
                     * @returns array
                     */
                    const setDayComplete = (arr, day) => {
                        const newArr = arr.map(key => {
                            if (key.day === day) {
                                return { ...key, status: true };
                            } else {
                                return key;
                            }
                        })
                        return newArr;
                    }
                    /**
                     * Función para evaluar si la sección se ha completado
                     * @param {*} answerCounter 
                     * @param {*} completeDayCounter 
                     * @param {*} day 
                     * @param {*} daysArray 
                     * @param {*} totalDays 
                     * @returns 
                     */
                    const setCompleteSection = (answerCounter, completeDayCounter, day, daysArray, totalDays) => {
                        const currentDaysArray = answerCounter === completeDayCounter ? setDayComplete(daysArray, day) : daysArray;
                        //console.table(currentDaysArray);
                        // Agrupa en un array los días completados
                        const completeDays = currentDaysArray.filter(dayKey => dayKey.status === true);
                        //console.table(completeDays);
                        // Cambia la bandera de completeSection
                        const completeSection = completeDays.length === totalDays ? true : false;
                        //console.table(completeSection);
                        return completeSection;
                    }
                    // Obtener una copia de la estructura de la evaluación del state
                    const evaluationStructureCopy = evaluationStructure;
                    let newEvaluationStructure = [];
                    // Se registra la respuesta y se actualiza la estructura de la encuesta
                    evaluationStructureCopy.forEach(sectionKey => {
                        if (sectionKey.idSectionHTKInde === section) {
                            let completeDay = 0;
                            let questionArray = [];
                            sectionKey.questions.forEach(questionKey => {
                                if (questionKey.idQuestionHTKInde === question) {
                                    completeDay = completeDay + 1;
                                    let answersArray = [];
                                    questionKey.answers.forEach(answerKey => {
                                        if (answerKey.day === day) {
                                            // Guarda la respuesta del día en el array de respuestas
                                            answersArray.push({
                                                ...answerKey,
                                                answers: checkAnswer(answerKey.answers, option)
                                            })
                                        } else {
                                            // Guarda la respuesta del día en el array de respuestas
                                            answersArray.push(answerKey);
                                        }
                                    });
                                    // Guarda el array de respuestas en el array de preguntas
                                    questionArray.push({
                                        ...questionKey,
                                        answers: answersArray,
                                    });
                                } else {
                                    // Contador de respuestas completas por día
                                    if (verifyAnswer(questionKey.answers, completeDay, day)) {
                                        completeDay = completeDay + 1
                                    }
                                    questionArray.push(questionKey);
                                }
                            });
                            // Guarda las preguntas de la sección y registra el día si ya está completo
                            newEvaluationStructure.push({
                                ...sectionKey,
                                questions: questionArray,
                                completeDayFlag: questionArray.length === completeDay ? setDayComplete(sectionKey.completeDayFlag, sectionKey.day) : sectionKey.completeDayFlag,
                                completeSection: setCompleteSection(questionArray.length, completeDay, sectionKey.day, sectionKey.completeDayFlag, 5),
                            });
                        } else {
                            newEvaluationStructure.push(sectionKey);
                        }
                    });
                    //console.table(newEvaluationStructure);
                    // Guarda la nueva estructura del cuestionario en el state
                    setEvaluationStructure(newEvaluationStructure);
                    // TODO: Proceso para actualizar la barra de porcentaje
                    // Obtener una copia de la estructura de la evaluación actualizada
                    const newEvaluationStructureCopy = newEvaluationStructure;
                    // Obtener respuestas contestadas
                    const getAnswers = newEvaluationStructureCopy.map(sectionKey => {
                        let answersArray = [];
                        sectionKey.questions.forEach(questionKey => {
                            questionKey.answers.forEach(answerKey => {
                                answerKey.answers.forEach(answer => {
                                    if (answer.check) {
                                        answersArray.push({
                                            ...answerKey,
                                        });
                                    }
                                });
                            });
                        });
                        return { section: sectionKey.idSectionHTKInde, answers: answersArray };
                    });
                    //console.table(getAnswers);
                    /**
                     * Función para contar las respuestas del array getAnswers
                     * @param {*} days 
                     * @param {*} answers 
                     * @param {*} sections 
                     * @returns array
                     */
                    const counterAnswers = (days, answers, sections) => {
                        let answerCounter = [];
                        sections.forEach(section => {
                            for (let i = 1; i < days + 1; i++) {
                                let counter = 0;
                                answers.forEach(key => {
                                    if (key.section === section.idSectionHTKInde) {
                                        key.answers.forEach(keykey => {
                                            if (keykey.day === parseInt(i)) {
                                                counter = counter + 1;
                                            }
                                        });
                                        answerCounter.push({
                                            section: section.idSectionHTKInde,
                                            day: parseInt(i),
                                            answers: counter,
                                        });
                                    }
                                });
                            }
                        });
                        return answerCounter;
                    }
                    const getCounterAnswers = counterAnswers(5, getAnswers, newEvaluationStructureCopy);
                    //console.table(getCounterAnswers);
                    // Obtiene el porcentaje de acuerdo con las respuestas contadas
                    const getCountAnswers = newEvaluationStructureCopy.map(sectionKey => {
                        let counter = 0;
                        getCounterAnswers.forEach(answersKey => {
                            if (sectionKey.idSectionHTKInde === answersKey.section) {
                                counter = counter + answersKey.answers
                            }
                        });
                        const numeroTotalPreguntas = sectionKey.questions.length * 5; // Se pone el 5 porque son 5 días por sección
                        const numeroPreguntasContestadas = counter;
                        const porcentajeContestado = (numeroPreguntasContestadas / numeroTotalPreguntas) * 100;
                        return { section: sectionKey.idSectionHTKInde, porcentaje: Math.round(porcentajeContestado) };
                    });
                    // Obtener una copia de la estructura de la barra de progreso del state
                    const progressBarStructureCopy = progressBarStructure;
                    let getProgressBarStructure = [];
                    // Se actualiza la estructura de progressBarStructure con el porcentaje actual
                    progressBarStructureCopy.forEach(progressBar => {
                        getCountAnswers.forEach(counter => {
                            if (progressBar.idSectionHTKInde === counter.section) {
                                getProgressBarStructure.push({
                                    ...progressBar,
                                    progress: counter.porcentaje
                                });
                            }
                        });
                    });
                    //console.table(getProgressBarStructure);
                    // Guarda la nueva estructura de la barra de porcentaje en el state
                    setProgressBarStructure(getProgressBarStructure);
                    // Actualiza la bandera de proceso para permitir otra petición
                    //setInProcess(false);
                }
            }
        });
    }
    /**
     * Valida si puede avanzar al siguiente día
     * @param {*} section 
     * @param {*} day 
     * @returns boolean
     */
    const handleNextDay = (section, day) => {
        const evaluationStructureCopy = evaluationStructure;
        const getSection = evaluationStructureCopy.filter(key => key.idSectionHTKInde === section);
        const getCompleteDay = getSection[0].completeDayFlag.filter(key => key.day === day);
        return getCompleteDay[0].status;
    }
    /**
     * Función para mostrar la siguiente sección
     * @param {*} section 
     * @returns 
     */
    const handleNextSection = (section, day) => {
        // Obtener una copia de la estructura de la evaluación del state
        const evaluationStructureCopy = evaluationStructure;
        const nextSection = section + 1;
        let newEvaluationStructure = [];
        // Validación para terminar evaluación
        if (evaluationStructureCopy.length === section) {
            if (day === 5) {
                // Validación para permitir guardar otra respuesat mientras está en proceso de guardado
                if (inProcess) {
                    return;
                }
                $('#modalLoaderGen').show();
                updFinishSurveyHTK(generalData).then(response => {
                    if (response) {
                        if (response.validation) {
                            // Redirecciona a Preguntas Extra
                            window.location = "/habits/" + identity;
                        } else {
                            // Actualiza la bandera de proceso para permitir otra petición
                            setInProcess(false);
                            $('#modalLoaderGen').hide();
                        }
                    } else {
                        // Actualiza la bandera de proceso para permitir otra petición
                        setInProcess(false);
                        $('#modalLoaderGen').hide();
                    }
                });
                return;
            } else {
                // Actualiza la bandera de proceso para permitir otra petición
                setInProcess(false);
            }
        } else {
            // Actualiza la bandera de proceso para permitir otra petición
            setInProcess(false);
        }
        // Obtener la sección siguiente a la actual
        const getSection = evaluationStructureCopy.filter(sectionkey => sectionkey.idSectionHTKInde === nextSection && sectionkey.showSection);
        // Se muestra la sección
        if (getSection.length > 0) {
            //handleScroll(`sectionId=${nextSection}`);
            if (nextSection === 1) {
                handleScrollStart(`sectionId=${0}`);
            } else {
                handleScrollStart(`sectionId=${nextSection - 1}`);
            }
            return;
        }
        // Se actualiza la bandera showSection de la siguiente sección para que se muestre
        evaluationStructureCopy.forEach(sectionKey => {
            if (sectionKey.idSectionHTKInde === nextSection) {
                newEvaluationStructure.push({
                    ...sectionKey,
                    showSection: true,
                });
            } else {
                newEvaluationStructure.push(sectionKey);
            }
        });
        //console.table(newEvaluationStructure);
        // Guardar la estructura de la evaluación en el state
        setEvaluationStructure(newEvaluationStructure);
        // Se muestra la sección
        //handleScroll(`sectionId=${nextSection}`);
        if (nextSection === 1) {
            handleScrollStart(`sectionId=${0}`);
        } else {
            handleScrollStart(`sectionId=${nextSection - 1}`);
        }
    }
    return (
        showLoader ? <LoaderSurvey />
            : showEvaluation && (
                <div className='col-12 bg-white-smoke'>
                    <ModalLoaderGen />
                    <div className='row'>
                        <div className='col-sm-12 col-md-11 col-lg-8 col-xl-8 mx-auto sticky-top bg-white-smoke'>
                            <div className='row'>
                                <div className='col-12'>
                                    <p className='text-center font-weight-bold text-primary-navy fs-1-3em'>Síntomas emocionales y físicos</p>
                                </div>
                                {progressBarStructure.map(bar => (
                                    <div
                                        className='col-6'
                                        style={{
                                            paddingRight: bar.idSectionHTKInde % 2 !== 0 ? '2.5px' : '15px',
                                            paddingLeft: bar.idSectionHTKInde % 2 !== 0 ? '15px' : '2.5px',
                                        }}
                                    >
                                        <p className='font-small-3 text-primary-grey m-0'>{bar.description}</p>
                                        <div className="progress progress-bar-1" style={{ backgroundColor: '#c3c3c3' }}>
                                            <div className="progress-bar bg-success" style={{ width: `${bar.progress}%` }}></div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='col-sm-12 col-md-11 col-lg-8 col-xl-8 mx-auto'>
                            <div className='card'>
                                <div className='card-body'>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                                            <img
                                                className='img-fluid'
                                                src='../img/filtroClinico/4-FaltaPoco.png'
                                                alt='Falta Poco'
                                            />
                                        </div>
                                        <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center flex-wrap align-content-center'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <p className="text-center fs-1-5em">¡Hola <span className='font-weight-bold'>{collabName}</span>!</p>
                                                    <p className='text-center fs-1-5em'>¿<span className='font-weight-bold'>Cómo te sentiste en los últimos 5</span> días?</p>
                                                    <p className='text-center fs-1-4em'>Completa todas las preguntas para continuar</p>
                                                    <p className='font-small-3'>Tiempo aproximado 15 Min</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                id={`sectionId=0`}
                                style={{
                                    width: '100%',
                                    height: '10px',
                                    position: 'absolute',
                                    bottom: 80,
                                    zIndex: -1000
                                }}
                            ></div>
                        </div>
                        {
                            evaluationStructure.map(section => (
                                <div
                                    className='col-sm-12 col-md-11 col-lg-8 col-xl-8 mx-auto'
                                    key={`sectionKey=${section.idSectionHTKInde}`}
                                    /* id={`sectionId=${section.idSectionHTKInde}`} */
                                    style={{
                                        opacity: section.showSection ? 1 : '0.5',
                                        zIndex: 1000,
                                    }}
                                >
                                    <div
                                        className='card'
                                        style={{
                                            border: `3px solid ${section.colorSection[0].border}`,
                                            borderRadius: 15
                                        }}
                                    >
                                        <div
                                            className='card-header p-0'
                                            style={{ backgroundColor: section.colorSection[0].background }}
                                        /* id={`sectionId=${section.idSectionHTKInde}`} */
                                        >
                                            <h1 className='text-center fs-1-4em'>{section.description}</h1>
                                        </div>
                                        <div className='card-body p-0'>
                                            <div className='col-12 pt-1'>
                                                <div className='row'>
                                                    <div
                                                        className='col text-center'
                                                        style={{ padding: '0 2.5px' }}
                                                    >
                                                        <span className={`${section.day === 1 ? 'font-weight-bold' : 'text-muted'} pb-1`} >Hoy</span>
                                                        <div
                                                            className="progress progress-bar-1"
                                                            style={{ marginTop: '.5rem' }}
                                                        >
                                                            <div
                                                                className="progress-bar"
                                                                style={{
                                                                    width: '100%',
                                                                    backgroundColor: section.day === 1 ? section.colorSection[0].barprogress : section.colorSection[0].barprogressAnswered
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                    {
                                                        section.dates.map(day => (
                                                            <div
                                                                className='col text-center'
                                                                style={{ padding: '0 2px' }}
                                                                key={`dateKey=${section.idSectionHTKInde}-${day.dayId}`}
                                                            >
                                                                <span className={`${section.day === day.dayId ? 'font-weight-bold' : 'text-muted'} pb-1`}>{day.day}</span>
                                                                <div
                                                                    className="progress progress-bar-1"
                                                                    style={{
                                                                        marginTop: '.5rem',
                                                                        borderRadius: '0px !important',
                                                                    }}
                                                                >
                                                                    <div
                                                                        className="progress-bar"
                                                                        style={{
                                                                            width: section.day >= day.dayId ? '100%' : '0%',
                                                                            backgroundColor: section.day === day.dayId ? section.colorSection[0].barprogress : section.colorSection[0].barprogressAnswered
                                                                        }}
                                                                    ></div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                            <div
                                                className='col-12 text-center py-1 mb-1 fs-1em'
                                                style={{ backgroundColor: section.colorSection[0].paragraph }}
                                            >
                                                {section.paragraph}
                                            </div>
                                            <div className='col-12 mb-1'>
                                                <div className='row'>
                                                    <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4'></div>
                                                    <div className='col-sm-12 col-md-6 col-lg-8 col-xl-8 mobile-paddin-htk'>
                                                        <div className='row'>
                                                            {
                                                                optionsStructure.map(optionKey => (
                                                                    <div
                                                                        className='col-3 d-flex flex-wrap align-content-center justify-content-center'
                                                                        key={`optionLabelKey=${section.idSectionHTKInde}-${optionKey.idAnswerHTKInde}`}
                                                                    >
                                                                        <p className='m-0 text-center fs-1em'
                                                                        >{optionKey.description}</p>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                section.questions.map(question => (
                                                    <div
                                                        className='col-12 mb-1'
                                                        key={`questionKey=${question.idQuestionHTKInde}`}
                                                    >
                                                        <div className='row'>
                                                            <div className='col-sm-12 col-md-6 col-lg-4 col-xl-4'>
                                                                <p className='font-weight-bold fs-1em align-top'>{question.description}</p>
                                                            </div>
                                                            <div className='col-sm-12 col-md-6 col-lg-8 col-xl-8 mobile-paddin-htk'>
                                                                <div className='row'>
                                                                    {
                                                                        question.answers.map(day => (
                                                                            day.day === section.day && (
                                                                                day.answers.map(answer => (
                                                                                    <div
                                                                                        className='col d-flex justify-content-center'
                                                                                        key={`optionKey=${question.idQuestionHTKInde}-${answer.idAnswerHTKInde}`}
                                                                                    >
                                                                                        <label
                                                                                            className="container-check-htk">
                                                                                            <input
                                                                                                type="radio"
                                                                                                name={`optionKey=${question.idQuestionHTKInde}-${answer.idAnswerHTKInde}`}
                                                                                                checked={answer.check}
                                                                                                onChange={() => {
                                                                                                    /* setInProcess(true); */
                                                                                                    handleSelectOption(day.day, section.idSectionHTKInde, question.idQuestionHTKInde, answer.idAnswerHTKInde);
                                                                                                }}
                                                                                                disabled={!section.showSection}
                                                                                            />
                                                                                            <span className={`checkmark-htk-${answer.idAnswerHTKInde}`}></span>
                                                                                            <span className={`checkmark-htk-${answer.idAnswerHTKInde}`}></span>
                                                                                        </label>
                                                                                    </div>
                                                                                ))
                                                                            )
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <div className='col-12 mb-1'>
                                                <div className='row'>
                                                    <div className='col d-flex flex-wrap align-content-center py-2'>
                                                        {section.day > 1 && (
                                                            <>
                                                                <i
                                                                    className="bi bi-chevron-left font-weight-bold font-large-1 pointer"
                                                                    style={{
                                                                        position: 'absolute',
                                                                        left: '0px',
                                                                        top: '5px'
                                                                    }}
                                                                ></i>
                                                                <i
                                                                    className="bi bi-chevron-left font-weight-bold font-medium-3 pointer"
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '12.5px'
                                                                    }}
                                                                ></i>
                                                                <span
                                                                    className='font-weight-bold pointer fs-1-05em '
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: '12.5px',
                                                                        left: '35px',
                                                                    }}
                                                                    onClick={() => handleSectionDay(section.idSectionHTKInde, 1)}
                                                                >día anterior</span>
                                                            </>
                                                        )}
                                                    </div>

                                                    {section.day < 5 ? (
                                                        <div className='col d-flex justify-content-end flex-wrap align-content-center py-2'
                                                            style={{
                                                                opacity: `${handleNextDay(section.idSectionHTKInde, section.day) === false ? '0.25' : 1}`
                                                            }}
                                                        >
                                                            <span
                                                                className='font-weight-bold pointer fs-1-05em '
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '12.5px',
                                                                    right: '35px',
                                                                }}
                                                                onClick={() => {
                                                                    if (handleNextDay(section.idSectionHTKInde, section.day)) {
                                                                        handleSectionDay(section.idSectionHTKInde, 2);
                                                                    } else {
                                                                        toastr.info("Aún faltan respuestas para habilitar el siguiente día.", "¡Ooops!");
                                                                    }
                                                                }}
                                                            >siguiente día</span>
                                                            <i
                                                                className='bi bi-chevron-right font-weight-bold font-medium-3 pointer'
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '12.5px'
                                                                }}
                                                            />
                                                            <i
                                                                className='bi bi-chevron-right font-weight-bold font-large-1 pointer'
                                                                style={{
                                                                    position: 'absolute',
                                                                    right: '0px',
                                                                    top: '5px'
                                                                }}
                                                            />
                                                        </div>
                                                    ) : (
                                                        section.completeSection && (
                                                            <div className='col d-flex justify-content-end flex-wrap align-content-center'>
                                                                <button
                                                                    className='bg-primary-1 text-white font-weight-bold rounded-pill-1 px-1 fs-1-05em pointer'
                                                                    onClick={() => {
                                                                        setInProcess(true);
                                                                        handleNextSection(section.idSectionHTKInde, section.day);
                                                                    }}
                                                                >Continuar</button>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={`sectionId=${section.idSectionHTKInde}`}
                                        style={{
                                            width: '100%',
                                            height: '10px',
                                            position: 'absolute',
                                            bottom: 80,
                                            zIndex: -1000
                                        }}
                                    ></div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            ) 
    );
};

export default HealthTracking;