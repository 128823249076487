import React from 'react'
import $ from "jquery"

const CultureInstructions = ({
    enterpriseLogo,
    privacy,
    privacyCheck,
    collabName,
}) => {
  return (
        <div
        className="modal"
        id="modal-3"
        style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
        data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto" style={{ backgroundColor: 'transparent', border: 'none' }}>
                    <div className="modal-body modal-div-bodered container-style-1 pb-5">
                        <div className="col-12 d-flex justify-content-end">
                            {enterpriseLogo !== '' &&
                                <img
                                src={enterpriseLogo}
                                className="w-15"
                                alt='logo'
                                />
                            }
                            
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <h1 className="font-weight-bold">Cultura Organizacional</h1>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <h1 className="font-weight-bold">Instrucciones</h1>
                        </div>
                        <div className="col-12 font-medium-1 mt-1">
                          <p className="text-justify">
                              Hola <span className='font-weight-bold'>{collabName}</span>,
                          </p>
                            <p className="text-justify">
                                Este cuestionario  <span className='text-pink-mine font-weight-bold'>mide las prácticas y los comportamientos</span> que ejemplifican y
                                refuerzan las <span className='text-pink-mine font-weight-bold'>creencias, valores y supuestos</span> que tienen los miembros de una organización.
                            </p>
                            <p>La encuesta de <span className='text-pink-mine font-weight-bold'>Cultura Organizacional</span> responderá cuatro preguntas clave sobre nuestra organización:</p>
                            <div className="col-12 pl-5">
                                 <p>
                                    <span className='font-weight-bold'>Misión:</span> {''}
                                    ¿Sabemos a dónde vamos?
                                </p>
                                <p>
                                    <span className='font-weight-bold'>Adaptabilidad:</span> {''}
                                    ¿Estamos escuchando a nuestros clientes o usuarios?
                                </p>
                                <p className='mt-1'>
                                    <span className='font-weight-bold'>Paticipación:</span> {''}
                                    ¿Estamos alineados, comprometidos y capacitados?
                                </p>
                                <p>
                                    <span className='font-weight-bold'>Consistencia:</span> {''}
                                    ¿Contamos con los valores, sistemas y procesos para ejecutar la visión?
                                </p>
                            </div>
                            <p className="text-justify">
                                Al final preguntaremos algunos {''}
                                <span className='text-pink-mine font-weight-bold'>datos demográficos</span> {''}
                                que nos permitirán contextualizar tus respuestas.
                            </p>
                            <p className="text-justify">
                                <span className='text-pink-mine font-weight-bold'>Recuerda</span> que <span className='text-pink-mine font-weight-bold'>la encuesta mide opiniones y percepciones basadas en la {''}
                                experiencia de tu día en el trabajo</span>, por lo cual no hay respuestas “correctas” o “incorrectas”. La información será utilizada para ayudarnos a planificar nuestro futuro, específicamente en la forma en que gestionamos nuestro negocio y construimos relaciones. {''}
                            </p>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <p className="font-weight-bold">Valoramos tus comentarios.</p>
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <p className="font-weight-bold">¡Gracias por tomarte el tiempo para completar la encuesta!</p>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                            <div className="form-group form-check">
                                <label className="form-check-label pointer font-medium-1">
                                    <input 
                                        type="checkbox" 
                                        className="form-check-input" 
                                        value="" 
                                        onChange={() => privacyCheck()} /> 
                                        Al dar click aceptas nuestro 
                                        <span 
                                            className="text-blue font-weight-bold" 
                                            onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}
                                        > aviso de privacidad
                                        </span>
                                </label>
                            </div>
                        </div>
                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                            {privacy === true ? (<button className="btn btn-sm btn-blue-3 float-right font-medium-1" onClick={() => $('#modal-3').hide()}>Encuesta</button>) : (null)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default CultureInstructions
