import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { validationAccessFCHTK, saveResponseClinic, updFinishSurveyFC } from '../../Models/ClinicalFilterHTK_model';
import LoaderSurvey from '../../LoaderSurvey';
import SurveyError from '../../SurveyError';
import PregnantMessage from './PregnantMessage';
import NoConsentMessage from './NoConsentMessage';
import Intro from './Intro';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import $ from "jquery"; 
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import ModalLoaderGen from '../../ModalLoaderGen';
import SurveyComplete from './SurveyComplete';
// TODO: Functions
import {
    handleScroll,
} from '../../../helpers/helpers';
// import IntroModalV1 from './IntroModalV1';
const ClinicalFilter = () => {
     //obtener identificador
     const { identity } = useParams();
     let parser = "0-0-0-0";
 
     //parsear identificador
     try {
         parser = atob(identity);
     } catch (error) {
         console.error(error);
     }
     parser = parser.split("-");
 
     //asignar datos parseados
     const collaboratorId = parser[0];
     const idEnterprise = parser[1];
 
     //getters & setters
     const [loading, setLoading] = useState(true);
     const [showMessage, setShowMessage] = useState(false);
     const [errorPage, setErrorPage] = useState(false);
     const [questions, setQuestions] = useState([]);
     const [answers, setAnswers] = useState([]);
     const [answerAnswered, setAnswerAnswered] = useState([]);
     const [subanswerAnswered, setSubanswerAnswered] = useState([]);
     // eslint-disable-next-line
     const [openQuestions, setOpenQuestions] = useState([]);
     const [idQuestionSelected, setidQuestionSelected] = useState([]);
     // const [update, setUpdate] = useState(0);
     const [userDate, setUserDate] = useState("");
     const [coordinates, setCoordinates] = useState("");
     const [browser, setBrowser] = useState("");
     const [os, setOs] = useState("");
     const [pregnantMess, setPregnantMess] = useState(false);
     const [noConsent, setNoConsent] = useState(false);
     // eslint-disable-next-line
     const [showSurvey, setShowSurvey] = useState(false);
     const [idCollabSurveyControlFCHTK, setIdCollabSurveyControlFCHTK] = useState(0);
     const [percentageBar, setPercentageBar] = useState(0);
     const [questionsOpacity, setQuestionsOpacity] = useState([]);
     const [consent, setConsent] = useState(false);

    const [inProcess, setInProcess] = useState(false);
    const [collabName, setCollabName] = useState('');


     useEffect(() => {
        validationAccessFCHTK(collaboratorId, idEnterprise).then((response) => {
            switch (response?.code) {
                case 1:
                    setCollabName(response.collabName);
                    if((response.statusCompleteFC === null) || (response.statusCompleteFC -1) || (response.consent === null)){
                        const flagResponses = response.listAnswerClinic.length === 0 ? false : true;
                        /**
                         * Función para obtener las respuestas guardadas en BD
                         * @param {*} responsesArray 
                         * @param {*} answersArray 
                         * @returns array
                         */
                        const getAnsweredQuestions = (responsesArray, answersArray) => {
                            // Obtener los id de las respuestas guardadas
                            const getResponsesId = responsesArray.map(responseKey => responseKey.idAnswer);
                            // Filtrar los objetos de answersArray que coincidan con los valores de getResponsesId
                            const answeredQuestions = answersArray.filter(objeto => getResponsesId.includes(objeto.idCatAnswerClinicInde.idAnswer));
                            return answeredQuestions;
                        }
                        const answeredQuestions = getAnsweredQuestions(response.listAnswerClinic, response.listCatAnswers);
                        //console.log(answeredQuestions)
                        /**
                         * Función para obtener la estructura base que controla la habilitación de preguntas
                         * @param {*} questionsArray 
                         * @param {*} flag 
                         * @param {*} answerAnsweredArray 
                         * @returns array
                         */
                        const questionOpacity = (questionsArray, flag, answerAnsweredArray) => {
                            let structureArray = [];
                            // Se crea la estructura base
                            for (let i = 0; i < questionsArray.length; i++) {
                                const element = questionsArray[i];
                                structureArray.push({
                                    idQuestion: element.idQuestion,
                                    isAnswered: false,
                                });
                            }
                            // Se valida si tiene respuestas guardadas
                            if (flag) {
                                let newStructureArray = [];
                                structureArray.forEach(structureKey => {
                                    let flagMatch = false;
                                    answerAnsweredArray.forEach(answerKey => {
                                        if (structureKey.idQuestion === answerKey.idQuestion) {
                                            flagMatch = true;
                                        }
                                    });
                                    if (flagMatch) {
                                        newStructureArray.push({
                                            ...structureKey,
                                            isAnswered: true,
                                        });
                                    } else {
                                        newStructureArray.push(structureKey);
                                    }
                                });
                                return newStructureArray;
                            } else {
                                return structureArray;
                            }
                        }
                        const getQuestionOpacityStructure = questionOpacity(response.listCatQuestions, flagResponses, answeredQuestions);
                        //console.table(getQuestionOpacityStructure);
                        setQuestionsOpacity(getQuestionOpacityStructure);
                        /**
                         * Función que devuelve el número de la pregunta pendiente
                         * @param {*} questionsArray 
                         * @param {*} answerAnsweredArray 
                         * @returns int
                         */
                        const scrollQuestion = (questionsArray, answerAnsweredArray) => {
                            if (questionsArray.length === answerAnsweredArray.length) {
                                return questionsArray.length
                            }
                            // Filtrar los objetos de questionsArray que no coincidan con answerAnsweredArray
                            const resultados = questionsArray.filter(objeto =>
                                !answerAnsweredArray.some(answer => answer.idCatAnswerClinicInde.idQuestion === objeto.idQuestion)
                            );
                            const question = resultados[0].idQuestion;
                            return question;
                        }
                        setIdCollabSurveyControlFCHTK(response.idCollabSurveyControlFCHTK)
                        setQuestions(response.listCatQuestions);
                        setAnswers(response.listCatAnswers);
                        setAnswerAnswered(response.listAnswerClinic);
                        let arraySubanswers = [];
                        response.listAnswerClinic.forEach(element => {
                            if (element.idSubAnswer !== "" && element.idSubAnswer !== undefined) {
                                let arregloSubanswers = element.idSubAnswer.split(",");
                                arregloSubanswers.forEach(elementString => {
                                    if (elementString !== "") {
                                        arraySubanswers.push({ idQuestion: element.IDAnswerClinicInde.idQuestion, idAnswer: element.idAnswer, idSubAnswer: parseInt(elementString, 10) });
                                    }
                                });
                            }
                        });
                        setSubanswerAnswered(arraySubanswers);
                        const arrayOpenQ = [];
                        const arrayOpenQId = [];
                        response.listAnswerClinic.forEach(element => {
                            if ("answerText" in element) {
                                arrayOpenQ.push({ idQuestion: element.IDAnswerClinicInde.idQuestion, answerText: element.answerText });
                                arrayOpenQId.push({ idQuestion: element.IDAnswerClinicInde.idQuestion });
                            }
                        });
                        setOpenQuestions(arrayOpenQ);
                        setidQuestionSelected(arrayOpenQId);
                        setLoading(false);
                        // Valida si existen respuestas guardadas
                        if (flagResponses) {
                            // Maneja el scroll para  mostrar la pregunta pendiente
                            setTimeout(() => {
                                handleScroll(`section-${scrollQuestion(response.listCatQuestions, answeredQuestions)}`)
                            }, 1000);
                        }
                        if (response.consent === 1) {
                            setConsent(true)
                        }
                    }else if((response.statusCompleteFC === 1) && (response.statusCompleteHTK === null || response.statusCompleteHTK === -1)){
                        //Se redirecciona a HTK
                        console.log('se redirecciona a HTK');
                        window.location = "/health-tracking/"+identity;
                    }else if((response.statusCompleteFC === 1 && response.statusCompleteHTK === 1) && (response.statusCompleteHabits === null || response.statusCompleteHabits === -1) ){
                        //Se redirecciona a Habits
                        console.log('se redirecciona a HTK');
                        window.location = "/habits/"+identity;
                    }else{
                        //encuesta contestada
                        setLoading(false);
                        setShowMessage(true);
                        setErrorPage(false);
                        setNoConsent(false);
                        setPregnantMess(false);
                    }
                    
                    break;
                case 1001:
                    //no se puede continuar debido a que la empresa no existe o no esta activa
                    setLoading(false);
                    setShowMessage(false);
                    setErrorPage(true);
                    setNoConsent(false);
                    setPregnantMess(false);
                    break;
                case 1002:
                    //no se puede continuar debido a que el colaborador no existe o no esta activo
                    setLoading(false);
                    setShowMessage(false);
                    setErrorPage(true);
                    setNoConsent(false);
                    setPregnantMess(false);
                    break;
                case 1003:
                    //no se encontro colaborador para la encuesta
                    setLoading(false);
                    setShowMessage(false);
                    setErrorPage(true);
                    setNoConsent(false);
                    setPregnantMess(false);
                    break;
                default:
                    setLoading(false);
                    setShowMessage(false);
                    setErrorPage(true);
                    setNoConsent(false);
                    setPregnantMess(false);
                    console.error("Error desconocido");
                    break;
            }
        })
     
       return () => {
       };
     }, [collaboratorId, idEnterprise, identity]);

    useEffect(() => {
        if(answerAnswered.length > 0){
            validateQuestionsAnswered(answerAnswered)
        }
    }, [answerAnswered])
    
     
    const data = () => {
        // fecha, horas, minutos y segundos
        let fechaActual = new Date();
        let today = new Date();
        let y = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
        let mm_2 = mm < 10 ? "0" + mm : mm;
        let hours = today.getHours() < 10 ? "0" + today.getHours() : today.getHours();
        let minutes = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
        let seconds = today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds();
        fechaActual = "" + y + "-" + mm_2 + "-" + dd + " " + hours + ":" + minutes + ":" + seconds;
        setUserDate(fechaActual);
        // navegador
        const brow = (function (agent) {
            switch (true) {
                case agent.indexOf("edge") > -1:
                    return "edge";
                case agent.indexOf("edg") > -1:
                    return "chromium based edge (dev or canary)";
                case agent.indexOf("opr") > -1 && !!window.opr:
                    return "opera";
                case agent.indexOf("chrome") > -1 && !!window.chrome:
                    return "chrome";
                case agent.indexOf("trident") > -1:
                    return "ie";
                case agent.indexOf("firefox") > -1:
                    return "firefox";
                case agent.indexOf("safari") > -1:
                    return "safari";
                default:
                    return "";
            }
        })(window.navigator.userAgent.toLowerCase());
        setBrowser(brow);
        // sistema operativo
        let so = (function (navInfo) {
            switch (true) {
                case navInfo.indexOf("win") !== -1:
                    return "Windows";
                case navInfo.indexOf("linux") !== -1:
                    return "Linux";
                case navInfo.indexOf("mac") !== -1:
                    return "Macintosh";
                default:
                    return "";
            }
        })(window.navigator.appVersion.toLowerCase());
        setOs(so);
        // coordenadas geograficas
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;
                let coordinates = "" + latitude + "," + longitude + "";
                setCoordinates(coordinates);
            });
        }
    }

    const HandleChangeTextArea  =(idQuestion, e) => {
        if (!idQuestionSelected.some(question => question.idQuestion === idQuestion)) {
            const arrayOpenQId = idQuestionSelected;
            arrayOpenQId.push({ idQuestion: idQuestion });
            setidQuestionSelected(arrayOpenQId);
        }
        validateQuestionsAnswered(answerAnswered);
    }

    const HandleChange = (answerData,txt="") =>{
        console.log('answerData: ',answerData, ' txt: ',txt);
        data();  
        saveResponseClinic(answerData.idCatAnswerClinicInde.idAnswer, "", answerData.idCatAnswerClinicInde.idQuestion, idEnterprise, idCollabSurveyControlFCHTK, parseInt(collaboratorId, 10), txt, JSON.stringify(userDate), JSON.stringify(coordinates), JSON.stringify(browser), JSON.stringify(os)).then((response) => {
            switch (response?.code) {
                case 1:
                    //guardado de respuesta correcto 
                    //eliminar alguna respuesta con el mismo id de pregunta
                    let arrayanswerAnswered = answerAnswered.filter(element => element.IDAnswerClinicInde.idQuestion !== answerData.idCatAnswerClinicInde.idQuestion);
                    //agregar nuevo elemento al arreglo let
                    arrayanswerAnswered.push({
                        idAnswer: answerData.idCatAnswerClinicInde.idAnswer,
                        idSubAnswer: "",
                        active: answerData.active,
                        IDAnswerClinicInde: {
                            idcollab: parseInt(collaboratorId, 10),
                            serialversionuid: answerData.idCatAnswerClinicInde.serialversionuid,
                            idCollabSurveyControlFCHTK: idCollabSurveyControlFCHTK,
                            idQuestion: answerData.idCatAnswerClinicInde.idQuestion,
                            idEnterprise: idEnterprise,
                        },
                        answerText:txt,
                        status: answerData.status,
                    });
                    //substituir el antiguo arreglo de respuestas por el actualizado
                    setAnswerAnswered(arrayanswerAnswered);
                    //eliminar las subrespuestas de esa pregunta
                    let arraysubanswerAnswered = subanswerAnswered.filter(element => element.idQuestion !== answerData.idCatAnswerClinicInde.idQuestion);
                    //substituir el antiguo arreglo de subrespuestas por el actualizado
                    setSubanswerAnswered(arraysubanswerAnswered);
                    try {
                        // element which needs to be scrolled to
                        const element = document.getElementById(`Q-${answerData.idCatAnswerClinicInde.idQuestion + 1}`);
                        // scroll to element
                        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                        // validate questions answered to manage de bar 
                        validateQuestionsAnswered(arrayanswerAnswered);
                    } catch (error) {
            
                    }
                    break;
                case 1001:
                    toastr.error("La empresa o colaborador no se encuentra activa.");
                    setErrorPage(true);
                    break;
                case 1003:
                    toastr.info('La evaluación ya fue contestada. Si no fue por ti, contacta a tu administrador.', '¡Ooops!');
                    setErrorPage(true);
                    break;
                default:
                    toastr.error("No se pudo guardar la respuesta, recargue la página y vuelva a intentarlo");
                    // setErrorPage(true);
                    // console.error("Error sin documentar");
                    break;
            }
        });

        // //eliminar alguna respuesta con el mismo id de pregunta
        // let arrayanswerAnswered = answerAnswered.filter(element => element.IDAnswerClinicInde.idQuestion !== answerData.idCatAnswerClinicInde.idQuestion);
        // //agregar nuevo elemento al arreglo let
        // arrayanswerAnswered.push({
        //     idAnswer: answerData.idCatAnswerClinicInde.idAnswer,
        //     idSubAnswer: "",
        //     active: answerData.active,
        //     IDAnswerClinicInde: {
        //         idcollab: parseInt(collaboratorId, 10),
        //         serialversionuid: answerData.idCatAnswerClinicInde.serialversionuid,
        //         idCollabSurveyControlFCHTK: idCollabSurveyControlFCHTK,
        //         idQuestion: answerData.idCatAnswerClinicInde.idQuestion,
        //         idEnterprise: idEnterprise,
        //     },
        //     answerText:txt,
        //     status: answerData.status,
        // });
        // //substituir el antiguo arreglo de respuestas por el actualizado
        // setAnswerAnswered(arrayanswerAnswered);
        // //eliminar las subrespuestas de esa pregunta
        // let arraysubanswerAnswered = subanswerAnswered.filter(element => element.idQuestion !== answerData.idCatAnswerClinicInde.idQuestion);
        // //substituir el antiguo arreglo de subrespuestas por el actualizado
        // setSubanswerAnswered(arraysubanswerAnswered);

        // try {
        //     // element which needs to be scrolled to
        //     const element = document.getElementById(`Q-${answerData.idCatAnswerClinicInde.idQuestion + 1}`);
        //     // scroll to element
        //     element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
        //     // validate questions answered to manage de bar 
        //     validateQuestionsAnswered(arrayanswerAnswered);
        // } catch (error) {

        // }
    }

    const HandleClickEndSurvey = () => {
        // Validación que no permite hacer otra petición mientras una está en proceso
        if (inProcess) {
            return;
        }

        let errors = 0;

        if (answerAnswered.length < (questions.length - 1)) {
            toastr.error("Aún hay preguntas sin contestar");
            errors = errors + 1;
        } else {
            const array = [];
            answers.forEach((element) => {
                if (answerAnswered.some(answer => answer.idAnswer === element.idCatAnswerClinicInde.idAnswer)) {
                    array.push(element);
                }
            });
            array.forEach((element) => {
                if (element.flagSubanswer === 1) {
                    if (!subanswerAnswered.some(answer => answer.idAnswer === element.idCatAnswerClinicInde.idAnswer)) {
                        toastr.error("Hay subrespuestas sin contestar");
                        errors = errors + 1;
                    }
                }
            })
        }

        if (errors === 0) {
            $('#modalLoaderGen').show();
            updFinishSurveyFC(collaboratorId, idEnterprise, idCollabSurveyControlFCHTK).then((response) => {
                switch (response?.code) {
                    case 1:
                        //Redirect to HTK
                        window.location = "/health-tracking/"+identity;
                        break;
                    case 1000:
                        toastr.error("Hubo un problema en obtener los parámetros, recargue la página y vuelva a intentarlo.");
                        setInProcess(false);
                        $('#modalLoaderGen').hide();
                        setErrorPage(true);
                        break;
                    case 1001:
                        toastr.error("La empresa o colaborador no se encuentra activa.");
                        setInProcess(false);
                        $('#modalLoaderGen').hide();
                        setErrorPage(true);
                        break;
                    default:
                        setInProcess(false);
                        $('#modalLoaderGen').hide();
                        setErrorPage(true);
                        console.error("Error no documentado")
                        break;
                }
            });
        }
        
    }

    const handleClickUp = () => {
        // element which needs to be scrolled to
        const element = document.getElementById("start");
        // scroll to element
        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
    }

    const barOptions = (percentage=0) => {
        let width = 0;
        let backgroundColor = "#c3c3c3";
        if (percentage > 0){
            //green
            width = `${percentage}%`;
            backgroundColor = "#9cb537";
        }
        
        return {
            width,
            backgroundColor
        }
    };

    const validateQuestionsAnswered = (arrayanswerAnswered) => {
        let totalQuestionsAnswered = 0;
        let arrAnswered = [];
        questions.forEach((element) => { //recorremos las preguntas 
            if(element.idQuestion === 11){
                if (($(`#TAQ-${element.idQuestion}`).val() !== undefined && $(`#TAQ-${element.idQuestion}`).val() !== "") || (answerAnswered.answerText !== undefined && answerAnswered.answerText !== "")) {
                    // totalQuestionsAnswered = totalQuestionsAnswered + 1;
                    arrAnswered.push({idQuestion: element.idQuestion, isAnswered: true});
                }else{
                    arrAnswered.push({idQuestion: element.idQuestion, isAnswered: false});
                }
            }else{
                arrayanswerAnswered.forEach((answerAnswered) => { // recorremos lo que hemos contestado
                    if(answerAnswered.IDAnswerClinicInde.idQuestion === element.idQuestion){ // verificamos si hemos respondido la pregunta 
                        answers.forEach((elementAnswer) => {
                            if(elementAnswer.idCatAnswerClinicInde.idQuestion === element.idQuestion && elementAnswer.idCatAnswerClinicInde.idAnswer === answerAnswered.idAnswer){ //Verificamos la respuesta que selecciono
                                if(elementAnswer.flagSubanswer === 1){ // Validamos si la respuesta que selecciono tiene subrespuestas
                                    if(answerAnswered.idSubAnswer !== ''){ //Validamos que contestara la subrespuesta
                                        let subanswerSinUltimoComa = answerAnswered.idSubAnswer.substring(0, answerAnswered.idSubAnswer.length - 1);
                                        let  arregloSubanswer = subanswerSinUltimoComa.split(',');
                                        // console.log('arregloSubanswer: ',arregloSubanswer);
                                        // console.log('element.idQuestion: ',element.idQuestion,' answerAnswered.answerText: ',answerAnswered.answerText);
                                        switch (element.idQuestion) {
                                            case 1:
                                                    if (arregloSubanswer.includes('6')) {
                                                        if (($(`#TAQ-${element.idQuestion}`).val() !== undefined && $(`#TAQ-${element.idQuestion}`).val() !== "") || (answerAnswered.answerText !== undefined && answerAnswered.answerText !== "")) {
                                                            totalQuestionsAnswered = totalQuestionsAnswered + 1;
                                                            arrAnswered.push({idQuestion: element.idQuestion, isAnswered: true});
                                                        }else{
                                                            arrAnswered.push({idQuestion: element.idQuestion, isAnswered: false});
                                                        }
                                                    }else{
                                                        totalQuestionsAnswered = totalQuestionsAnswered + 1;
                                                        arrAnswered.push({idQuestion: element.idQuestion, isAnswered: true});
                                                    }
                                                break;
                                            case 2:
                                                    if (arregloSubanswer.includes('12')) {
                                                        if (($(`#TAQ-${element.idQuestion}`).val() !== undefined && $(`#TAQ-${element.idQuestion}`).val() !== "") || (answerAnswered.answerText !== undefined && answerAnswered.answerText !== "")) {
                                                            totalQuestionsAnswered = totalQuestionsAnswered + 1;
                                                            arrAnswered.push({idQuestion: element.idQuestion, isAnswered: true});
                                                        }else{
                                                            arrAnswered.push({idQuestion: element.idQuestion, isAnswered: false});
                                                        }
                                                    }else{
                                                        totalQuestionsAnswered = totalQuestionsAnswered + 1;
                                                        arrAnswered.push({idQuestion: element.idQuestion, isAnswered: true});
                                                    }
                                                break;
                                            case 3:
                                                    if (arregloSubanswer.includes('23')) {
                                                        if (($(`#TAQ-${element.idQuestion}`).val() !== undefined && $(`#TAQ-${element.idQuestion}`).val() !== "") || (answerAnswered.answerText !== undefined && answerAnswered.answerText !== "")) {
                                                            totalQuestionsAnswered = totalQuestionsAnswered + 1;
                                                            arrAnswered.push({idQuestion: element.idQuestion, isAnswered: true});
                                                        }else{
                                                            arrAnswered.push({idQuestion: element.idQuestion, isAnswered: false});
                                                        }
                                                    }else{
                                                        totalQuestionsAnswered = totalQuestionsAnswered + 1;
                                                        arrAnswered.push({idQuestion: element.idQuestion, isAnswered: true});
                                                    }
                                                break;
                                            default:
                                                break;
                                        }
                                        
                                    
                                    }else{
                                        arrAnswered.push({idQuestion: element.idQuestion, isAnswered: false});
                                    }
                                    
                                }else if(elementAnswer.flagText === 1){
                                    if (($(`#TAQ-${element.idQuestion}`).val() !== undefined && $(`#TAQ-${element.idQuestion}`).val() !== "") || (answerAnswered.answerText !== undefined && answerAnswered.answerText !== "")) {
                                        totalQuestionsAnswered = totalQuestionsAnswered + 1;
                                        arrAnswered.push({idQuestion: element.idQuestion, isAnswered: true});
                                    }else{
                                        arrAnswered.push({idQuestion: element.idQuestion, isAnswered: false});
                                    }
                                }else{
                                    totalQuestionsAnswered = totalQuestionsAnswered + 1;
                                    arrAnswered.push({idQuestion: element.idQuestion, isAnswered: true});
                                }
                            }
                        })

                    }
                })
            }
            
            
            
        })

        questions.forEach(element => {
            if(!arrayanswerAnswered.some(answerAnswered => answerAnswered.IDAnswerClinicInde.idQuestion === element.idQuestion)){
                if(element.idQuestion !== 11){
                    arrAnswered.push({idQuestion: element.idQuestion, isAnswered: false});
                }
            }
        })

        // console.log('validateQuestionsAnswered answerAnswered: ',arrayanswerAnswered);
        console.log('arrAnswered ordenado: ',arrAnswered.sort((a, b) => a.idQuestion - b.idQuestion))
        let percentage = (totalQuestionsAnswered * 100) / (questions.length-1)
        setPercentageBar(percentage)
        setQuestionsOpacity(arrAnswered.sort((a, b) => a.idQuestion - b.idQuestion))
        // console.log('percentage: ',percentage);
        // console.log('totalQuestionsAnswered: ',totalQuestionsAnswered);
    }




     if (loading) {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-5">
                <LoaderSurvey />
            </div>
        )
    } else if (showMessage) {
        return (
                <SurveyComplete />
        )
    } else if (errorPage) {
        return (
            <div className="container-fluid">
                <SurveyError />
            </div>
        )
    } else if (pregnantMess) {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-2">
                <PregnantMessage />
            </div>
        )
    } else if (noConsent) {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-2">
                <NoConsentMessage />
            </div>
        )
    } else if (!consent) {
        return (
            <Intro 
                collaboratorId={collaboratorId}
                idEnterprise={idEnterprise}
                setIdCollabSurveyControlFCHTK={setIdCollabSurveyControlFCHTK}
                idCollabSurveyControlFCHTK={idCollabSurveyControlFCHTK}
                setPregnantMess={setPregnantMess}
                setNoConsent={setNoConsent}
                setShowSurvey={setShowSurvey}
                setConsent={setConsent}
                handleClickUp={handleClickUp}
                collabName={collabName}
            />
        )
    }else {
        return (
            <div className="col-12 bg-white-smoke" >
                <ModalLoaderGen />
                <div className='row'>
                    <div className='col-sm-12 col-md-11 col-lg-8 col-xl-8 mx-auto sticky-top bg-white-smoke'>
                        <div className="row align-self-center">
                            <div className="col-12 mx-auto d-flex justify-content-start">
                                <span className="text-blue-sec font-weight-normal fs-1-3em">Antecedentes de bienestar mental</span>
                            </div>
                            <div className="col-12 mx-auto ">
                                <div className="progress" style={{backgroundColor:'#c3c3c3', height: '.5rem'}}>
                                    <div className="progress-bar" style={barOptions(percentageBar)}></div>
                                </div>
                            </div>
                            <div className="col-12 mx-auto my-0 d-flex justify-content-end">
                                {percentageBar > 0 &&
                                    <span className="text-blue-sec fs-0-95em">{`${percentageBar}%`}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-11 col-lg-8 col-xl-8 mx-auto'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6' id="start">
                                        <img
                                            className='img-fluid'
                                            src='../img/filtroClinico/3-Comencemos.png'
                                            alt='Comencemos'
                                        />
                                    </div>
                                    <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center flex-wrap align-content-center'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <p className="fs-1-2em text-center">¡Hola <span className='font-weight-bold'>{collabName}</span>!</p>
                                                <p className="fs-1-2em text-center">Selecciona la respuesta que corresponda a cada pregunta. {''}</p>
                                                <p className="fs-1-2em text-center">(Tiempo aproximado 3 Min)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-11 col-lg-8 col-xl-8 mx-auto'>
                        {questions.map((question, indiceQuestion) => {
                            if (question.idType === 1 || question.idType === 2 || question.idType === 3) {
                                return (
                                    <div key={indiceQuestion} className={`${indiceQuestion === 0 ? 'col-12 mt-1 px-0':questionsOpacity.some(questionOpacity => questionOpacity.idQuestion === question.idQuestion-1 && !questionOpacity.isAnswered) ? 'col-12 mt-1 px-0 div-dissable-style-2':'col-12 mt-1 px-0'}`} id={`section-${question.idQuestion}`}>
                                        <div className={`${indiceQuestion === 0 ? 'card shadow-card-2' : questionsOpacity.some(questionOpacity => questionOpacity.idQuestion === question.idQuestion-1 && !questionOpacity.isAnswered) ? 'card' : 'card shadow-card-2' }`}>
                                            <div className="card-header bg-white-guay font-weight-bold fs-1-2em border-bottom-black-opacity py-1" id={`Q-${question.idQuestion}`}>
                                                <span>{question.description}</span>
                                            </div>
                                            <div className="card-body">
                                                <RadioGroup>
                                                    {answers.map((answer, indiceAnswer) => {
                                                        if (question.idQuestion === answer.idCatAnswerClinicInde.idQuestion) {
                                                            return (
                                                                <div key={indiceAnswer}>
                                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                        <FormControlLabel value={answer.idCatAnswerClinicInde.idAnswer} onChange={() => HandleChange(answer)}
                                                                            control={
                                                                                <Radio icon={<i className="ft ft-circle text-blue-sec align-middle" />}
                                                                                    checkedIcon={<i className="ft ft-check-circle text-blue-sec align-middle" />}
                                                                                    disabled={indiceQuestion === 0 ? false : questionsOpacity.some(questionOpacity => questionOpacity.idQuestion === question.idQuestion-1 && !questionOpacity.isAnswered) ? true : false }
                                                                                    checked={answerAnswered.some(answerAns => answerAns.idAnswer === answer.idCatAnswerClinicInde.idAnswer)}
                                                                                    size="small" />
                                                                            }
                                                                            label={<span className='fs-1-2em'>{answer.description}</span>} />
                                                                    </div>
                                                                    {answerAnswered.some(answerAns => answerAns.idAnswer === answer.idCatAnswerClinicInde.idAnswer) && answer.flagText === 1 ? <div className="col-sm-12 col-md-11 col-lg-8 col-xl-8">
                                                                        <textarea id={`TAQ-${question.idQuestion}`} className="form-control" name="textarea" maxLength="100" placeholder="Escriba aquí..." defaultValue={answerAnswered.find(element => element.idAnswer === answer.idCatAnswerClinicInde.idAnswer)?.answerText} onChange={(e) => HandleChangeTextArea(question.idQuestion, e)}></textarea>
                                                                    </div>
                                                                        : null}
                                                                </div>
                                                            )
                                                        } else {
                                                            return (null)
                                                        }
                                                    })}
                                                </RadioGroup>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (question.idType === 4) {
                                return (
                                    <div key={indiceQuestion} className={`${questionsOpacity.some(questionOpacity => questionOpacity.idQuestion === question.idQuestion-1 && !questionOpacity.isAnswered) ? 'col-12 mt-1 px-0 div-dissable-style-2':'col-12 mt-1 px-0'}`} id={`section-${question.idQuestion}`}>
                                        <div className={`${indiceQuestion === 0 ? 'card shadow-card-2' : questionsOpacity.some(questionOpacity => questionOpacity.idQuestion === question.idQuestion-1 && !questionOpacity.isAnswered) ? 'card' : 'card shadow-card-2' }`}>
                                            <div className="card-header bg-white-guay font-weight-bold fs-1-2em border-bottom-black-opacity py-1" id={`Q-${question.idQuestion}`}>
                                                <span>{question.description}</span>
                                            </div>
                                            <div className="card-body">
                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                    <textarea id={`TAQ-${question.idQuestion}`} className="form-control" name="textarea" rows="3" maxLength="200" placeholder="Escriba aquí..." defaultValue={answerAnswered.find(element => element.IDAnswerClinicInde.idQuestion === question.idQuestion)?.answerText} onBlur={(e) => HandleChange(answers.filter(ans => ans.idCatAnswerClinicInde.idQuestion === question.idQuestion)[0], e.target.value)} disabled={indiceQuestion === 0 ? false : questionsOpacity.some(questionOpacity => questionOpacity.idQuestion === question.idQuestion-1 && !questionOpacity.isAnswered) ? true : false}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return null;
                            }
                        })}
                    </div>
                    <div
                        className={
                            `${percentageBar === 100
                                ? 'col-12 mx-auto my-2 font-medium-3 d-flex justify-content-center'
                                : 'col-12 mx-auto my-2 font-medium-3 d-flex justify-content-center div-dissable-style-2'}`
                        }
                    >
                        <button
                            onClick={() => {
                                setInProcess(true);
                                HandleClickEndSurvey();
                            }}
                            className={
                                `${percentageBar === 100
                                    ? 'border-primary-1 bg-primary-1 text-white px-3 rounded-pill-1 float-right pointer'
                                    : 'border-primary-1 bg-primary-1 text-white px-3 rounded-pill-1 float-right'}`
                            }
                        >Terminar</button>
                    </div>

                </div>
                <button className="arrow-up-blue-navy" onClick={handleClickUp}><i className="ft-arrow-up icon-medium-size" /></button>

            </div>

        )
    }
}

export default ClinicalFilter