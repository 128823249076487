import React, { useState, useEffect } from "react";
// Components
import ATSSection1 from './ATSSection1';
import ATSSection2 from './ATSSection2';
import ATSSection3 from './ATSSection3';
import ATSSection4 from './ATSSection4';
import ATSSection5 from './ATSSection5';
import LanguageInput from "./LanguageSwitch";
import SurveyComplete from '../SurveyComplete';
import LoaderSurvey from "../LoaderSurvey";
import SurveyError from '../SurveyError';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import $ from "jquery";
import ATSFRPDEMO from "./IntroText/ATSFRPDEMO";
import ATSDEMO from "./IntroText/ATSDEMO";
import ATS from "./IntroText/ATS";
import ATSFRP from "./IntroText/ATSFRP";
// API calls
import { getEnterpriseById } from "../enterprises/Model";
import { validationAccessEE, updFinishSurveyEE } from '../Models/SurveysModel';

const ATSController = props => {

    /* const [survey, setSurvey] = useState([]); */
    const [section_1, setSection_1] = useState([]);
    const [section_2, setSection_2] = useState([]);
    const [section_3, setSection_3] = useState([]);
    const [section_4, setSection_4] = useState([]);
    const [section_5, setSection_5] = useState([]);
    const [showSection_5, setShowSection_5] = useState(false);
    const [showSections, setShowSections] = useState(false);
    // eslint-disable-next-line
    const [surveyComplete15, setSurveyComplete] = useState(false);
    const [section_1Complete, setSection_1Complete] = useState(false);
    const [section_2Complete, setSection_2Complete] = useState(false);
    const [section_3Complete, setSection_3Complete] = useState(false);
    const [section_4Complete, setSection_4Complete] = useState(false);
    const [section_5Complete, setSection_5Complete] = useState(false);
    const [todayDate, setTodayDate] = useState("");
    const [browser, setBrowser] = useState("");
    const [OS, setOS] = useState("");
    const [coordinates, setCoordinates] = useState("");
    const [idEnterprise, setIdEnterprise] = useState(0);
    const [idGroup, setIdGroup] = useState(0);
    const [idCollab, setIdCollab] = useState(0);
    const [surveyId, setSurveyId] = useState(0);
    const [privacy, setPrivacy] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [errorPage, setErrorPage] = useState(false);
    const [surveyTailCounter, setSurveyTailCounter] = useState(0)
    const [enterpriseData, setEnterpriseData] = useState({})
    const [linkedsurvey, setLinkedSurvey] = useState(false)
    const [language, setLanguage] = useState('es');
    const [collabName, setCollabName] = useState('');

    useEffect(() => {
        const structureData = (data) => {
            // console.log('structureData: ',data);

            const arrQuestions = data.questions.map(question => {
                const arrAnswers = question.answers.map(answer => {
                    return {
                        check: answer.check,
                        description: language === 'es' ? answer.description : answer.description_en,
                        description_es: answer.description,
                        description_en: answer.description_en,
                        idanswer: answer.idanswer
                    }
                });
                return {
                    idquestion: question.idquestion,
                    description: language === 'es' ? question.description : question.description_en,
                    description_es: question.description,
                    description_en: question.description_en,
                    answers: arrAnswers
                }
            });

            const arrSurvey = {
                description: language === 'es' ? data.description : data.description_en,
                idsection: data.idsection,
                questions: arrQuestions,
                description_es: data.description,
                description_en: data.description_en,
            }
            return arrSurvey;

        }
        const getURLParameters = props.match.params;
        let parameters = "0-0-0-0";
        try {
            parameters = atob(getURLParameters.db);
        } catch (error) {
            console.error(error)
        }
        let parameter = parameters.split("-");
        let idCollab = parameter[0];
        let idEnterprise = parameter[1];
        let surveyId = parameter[2];
        let idGroup = parameter[3];
        setIdCollab(parseInt(idCollab));
        setIdEnterprise(parseInt(idEnterprise));
        setSurveyId(parseInt(surveyId));
        setIdGroup(parseInt(idGroup));
        getEnterpriseById(parseInt(idEnterprise)).then(response => {
            setEnterpriseData(response)
        })
        validationAccessEE(parseInt(idCollab), parseInt(idEnterprise), parseInt(surveyId), parseInt(idGroup)).then((response) => {
            if (response) {
                if (response.code === 1) {
                    setCollabName(response.collabName);
                    if (response.surveysCollab) {
                        let surveysTail = response.surveysCollab.split("-");
                        if (surveysTail.length === 1) {
                            setSurveyTailCounter(1)
                        }
                        if (surveysTail.length === 2) {
                            let missedSuv = response.missedSurveys.split("-")
                            if (missedSuv.length > 1) {
                                if (surveysTail[0] === 'EE' && surveysTail[1] === 'EL') {
                                    setSurveyTailCounter(4)
                                    setLinkedSurvey(true)
                                }
                                if (surveysTail[0] === 'EE' && surveysTail[1] === 'DEMO') {
                                    setSurveyTailCounter(2)
                                    setLinkedSurvey(true)
                                }
                            } else {
                                setSurveyTailCounter(1)
                            }
                        }
                        if (surveysTail.length === 3) {
                            setSurveyTailCounter(3)
                            setLinkedSurvey(true)
                        }
                    }
                    /* setSurvey(response.survey); */
                    // console.log('priemra restructurada',structureData(response.survey[0]));

                    // setSection_1([response.survey[0]]);
                    // setSection_2([response.survey[1]]);
                    // setSection_3([response.survey[2]]);
                    // setSection_4([response.survey[3]]);
                    // setSection_5([response.survey[4]]);

                    setSection_1([structureData(response.survey[0])]);
                    setSection_2([structureData(response.survey[1])]);
                    setSection_3([structureData(response.survey[2])]);
                    setSection_4([structureData(response.survey[3])]);
                    setSection_5([structureData(response.survey[4])]);
                    // Validat privacity message
                    if (response.privacity === 0 && !privacy) {
                        $('#modal-1').show();
                    }
                    let seccion_1 = [response.survey[0]];
                    let unanswerSection_1 = 0;
                    let yesSection_1 = 0;
                    let noSection_1 = 0;
                    for (let x in seccion_1) {
                        unanswerSection_1 = seccion_1[x].questions.length;
                        for (let z in seccion_1[x].questions) {
                            for (let y in seccion_1[x].questions[z].answers) {
                                if (seccion_1[x].questions[z].answers[y].idanswer === 1 && seccion_1[x].questions[z].answers[y].check === 1) {
                                    yesSection_1 = yesSection_1 + 1;
                                }
                                if (seccion_1[x].questions[z].answers[y].idanswer === 2 && seccion_1[x].questions[z].answers[y].check === 1) {
                                    noSection_1 = noSection_1 + 1;
                                }
                            }
                        }
                    }
                    let totalAnsweredSeccion_1 = yesSection_1 + noSection_1;
                    let totalUnanswerSection_1 = unanswerSection_1 - totalAnsweredSeccion_1;
                    if (noSection_1 === 6) {
                        setShowSection_5(true);
                        setSection_1Complete(true);
                    }
                    if (yesSection_1 > 0) {
                        setShowSections(true);
                    }
                    if (totalUnanswerSection_1 === 0) {
                        setSection_1Complete(true);
                    }
                    let seccion_2 = [response.survey[1]];
                    let unanswerSection_2 = 0;
                    let yesSection_2 = 0;
                    let noSection_2 = 0;
                    for (let x in seccion_2) {
                        unanswerSection_2 = seccion_2[x].questions.length;
                        for (let z in seccion_2[x].questions) {
                            for (let y in seccion_2[x].questions[z].answers) {
                                if (seccion_2[x].questions[z].answers[y].idanswer === 1 && seccion_2[x].questions[z].answers[y].check === 1) {
                                    yesSection_2 = yesSection_2 + 1;
                                }
                                if (seccion_2[x].questions[z].answers[y].idanswer === 2 && seccion_2[x].questions[z].answers[y].check === 1) {
                                    noSection_2 = noSection_2 + 1;
                                }
                            }
                        }
                    }
                    let totalAnsweredSeccion_2 = yesSection_2 + noSection_2;
                    let totalUnanswerSection_2 = unanswerSection_2 - totalAnsweredSeccion_2;
                    if (totalUnanswerSection_2 === 0) {
                        setSection_2Complete(true);
                    }
                    let seccion_3 = [response.survey[2]];
                    let unanswerSection_3 = 0;
                    let yesSection_3 = 0;
                    let noSection_3 = 0;
                    for (let x in seccion_3) {
                        unanswerSection_3 = seccion_3[x].questions.length;
                        for (let z in seccion_3[x].questions) {
                            for (let y in seccion_3[x].questions[z].answers) {
                                if (seccion_3[x].questions[z].answers[y].idanswer === 1 && seccion_3[x].questions[z].answers[y].check === 1) {
                                    yesSection_3 = yesSection_3 + 1;
                                }
                                if (seccion_3[x].questions[z].answers[y].idanswer === 2 && seccion_3[x].questions[z].answers[y].check === 1) {
                                    noSection_3 = noSection_3 + 1;
                                }
                            }
                        }
                    }
                    let totalAnsweredSeccion_3 = yesSection_3 + noSection_3;
                    let totalUnanswerSection_3 = unanswerSection_3 - totalAnsweredSeccion_3;
                    if (totalUnanswerSection_3 === 0) {
                        setSection_3Complete(true);
                    }
                    let seccion_4 = [response.survey[3]];
                    let unanswerSection_4 = 0;
                    let yesSection_4 = 0;
                    let noSection_4 = 0;
                    for (let x in seccion_4) {
                        unanswerSection_4 = seccion_4[x].questions.length;
                        for (let z in seccion_4[x].questions) {
                            for (let y in seccion_4[x].questions[z].answers) {
                                if (seccion_4[x].questions[z].answers[y].idanswer === 1 && seccion_4[x].questions[z].answers[y].check === 1) {
                                    yesSection_4 = yesSection_4 + 1;
                                }
                                if (seccion_4[x].questions[z].answers[y].idanswer === 2 && seccion_4[x].questions[z].answers[y].check === 1) {
                                    noSection_4 = noSection_4 + 1;
                                }
                            }
                        }
                    }
                    let totalAnsweredSeccion_4 = yesSection_4 + noSection_4;
                    let totalUnanswerSection_4 = unanswerSection_4 - totalAnsweredSeccion_4;
                    if (totalUnanswerSection_4 === 0) {
                        setSection_4Complete(true);
                    }
                    let seccion_5 = [response.survey[4]];
                    let unanswerSection_5 = 0;
                    let yesSection_5 = 0;
                    let noSection_5 = 0;
                    for (let x in seccion_5) {
                        unanswerSection_5 = seccion_5[x].questions.length;
                        for (let z in seccion_5[x].questions) {
                            for (let y in seccion_5[x].questions[z].answers) {
                                if (seccion_5[x].questions[z].answers[y].idanswer === 1 && seccion_5[x].questions[z].answers[y].check === 1) {
                                    yesSection_5 = yesSection_5 + 1;
                                }
                                if (seccion_5[x].questions[z].answers[y].idanswer === 2 && seccion_5[x].questions[z].answers[y].check === 1) {
                                    noSection_5 = noSection_5 + 1;
                                }
                            }
                        }
                    }
                    let totalAnsweredSeccion_5 = yesSection_5 + noSection_5;
                    // eslint-disable-next-line
                    let totalUnanswerSection_5 = unanswerSection_5 - totalAnsweredSeccion_5;
                    if (noSection_5 === 6) {
                        setSurveyComplete(true);
                        setSection_5Complete(true);
                    }
                    if (yesSection_5 > 0) {
                        setShowSections(true);
                        setSurveyComplete(false);
                    }
                    if (totalUnanswerSection_5 === 0) {
                        setSection_5Complete(true);
                    }
                    setLoading(false);
                } else {
                    switch (response.code) {
                        case 2:
                            window.location.replace(response.url);
                            break;
                        case 3:
                            setShowMessage(true);
                            break;
                        case 4:
                            window.location.replace(response.url);
                            break;
                        case 5:
                            window.location.replace(response.url);
                            break;
                        default:
                            setLoading(false);
                            if (language === 'es') {
                                toastr.error("No se ha podido cargar la encuesta. Recargue la página nuevamente.", "¡Ooops!");
                            } else {
                                toastr.error("The survey could not be loaded. Please reload the page.", "¡Ooops!");
                            }
                            setErrorPage(true);
                            break;
                    }
                }
            } else {
                setLoading(false);
                if (language === 'es') {
                    toastr.error("No se ha podido cargar la encuesta. Recargue la página nuevamente.", "¡Ooops!");
                } else {
                    toastr.error("The survey could not be loaded. Please reload the page.", "¡Ooops!");
                }
                setErrorPage(true);
            }
        });
        // Set format date dd/mm/yyyy hh:mm:ss
        let today = new Date();
        let y = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
        let mm_2 = mm < 10 ? "0" + mm : mm;
        let hours = today.getHours() < 10 ? "0" + today.getHours() : today.getHours();
        let minutes = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
        let seconds = today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds();
        const fechaActual = "" + y + "-" + mm_2 + "-" + dd + " " + hours + ":" + minutes + ":" + seconds;
        setTodayDate(fechaActual);
        // Set browser
        let browser = (function (agent) {
            switch (true) {
                case agent.indexOf("edge") > -1:
                    return "edge";
                case agent.indexOf("edg") > -1:
                    return "chromium based edge (dev or canary)";
                case agent.indexOf("opr") > -1 && !!window.opr:
                    return "opera";
                case agent.indexOf("chrome") > -1 && !!window.chrome:
                    return "chrome";
                case agent.indexOf("trident") > -1:
                    return "ie";
                case agent.indexOf("firefox") > -1:
                    return "firefox";
                case agent.indexOf("safari") > -1:
                    return "safari";
                default:
                    return "other";
            }
        })(window.navigator.userAgent.toLowerCase());
        setBrowser(browser);
        // Set Operative Sistem
        let so = (function (navInfo) {
            switch (true) {
                case navInfo.indexOf("win") !== -1:
                    return "Windows";
                case navInfo.indexOf("linux") !== -1:
                    return "Linux";
                case navInfo.indexOf("mac") !== -1:
                    return "Macintosh";
                default:
                    return "other";
            }
        })(window.navigator.appVersion.toLowerCase());
        setOS(so);
        // Set geolocation
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;
                let coordinates = "" + latitude + "," + longitude + "";
                setCoordinates(coordinates);
            });
        }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        const newSection1 = section_1;
        const newSection2 = section_2;
        const newSection3 = section_3;
        const newSection4 = section_4;
        const newSection5 = section_5;
        if (
            newSection1.length > 0 &&
            newSection2.length > 0 &&
            newSection3.length > 0 &&
            newSection4.length > 0 &&
            newSection5.length > 0
        ) {
            const structureData = (data) => {
                console.log('structureData: ', data);
                const arrQuestions = data[0].questions.map(question => {
                    const arrAnswers = question.answers.map(answer => {
                        return {
                            check: answer.check,
                            description: language === 'es' ? answer.description_es : answer.description_en,
                            idanswer: answer.idanswer,
                            description_es: answer.description_es,
                            description_en: answer.description_en,
                        }
                    });
                    return {
                        idquestion: question.idquestion,
                        description: language === 'es' ? question.description_es : question.description_en,
                        answers: arrAnswers,
                        description_es: question.description_es,
                        description_en: question.description_en,
                    }
                });

                const arrSurvey = {
                    description: language === 'es' ? data[0].description_es : data[0].description_en,
                    idsection: data[0].idsection,
                    questions: arrQuestions,
                    description_es: data[0].description_es,
                    description_en: data[0].description_en,
                }
                console.log('arrSurvey: ', arrSurvey);
                return arrSurvey;
            }

            setSection_1([structureData(newSection1)]);
            setSection_2([structureData(newSection2)]);
            setSection_3([structureData(newSection3)]);
            setSection_4([structureData(newSection4)]);
            setSection_5([structureData(newSection5)]);
        }
    }, [language]);

    const submitSurvey = e => {
        e.preventDefault();
        try {
            if (showSection_5 === true && showSections === false) {
                setLoading(true);
                updFinishSurveyEE(idCollab, idEnterprise, surveyId, idGroup, "2,3,4").then((response) => {
                    if (response) {
                        switch (response.code) {
                            case 1:
                                if (language === 'es') {
                                    toastr.success("Se registró correctamente tus respuestas.", "¡Éxito!");
                                } else {
                                    toastr.success("Your responses have been successfully submitted.", "Success!");
                                }
                                window.location.replace(response.url);
                                setLoading(false);
                                break;
                            case 2:
                                if (language === 'es') {
                                    toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                                } else {
                                    toastr.success("The survey has already been completed.", "Success!");
                                }
                                setShowMessage(true);
                                break;
                            case 3:
                                if (language === 'es') {
                                    toastr.success("Se registró correctamente tus respuestas.", "¡Éxito!");
                                } else {
                                    toastr.success("Your responses have been successfully submitted.", "Success!");
                                }
                                setLoading(false);
                                window.location.replace(response.url);
                                break;
                            case 5:
                                if (language === 'es') {
                                    toastr.success("Se registró correctamente tus respuestas.", "¡Éxito!");
                                } else {
                                    toastr.success("Your responses have been successfully submitted.", "Success!");
                                }
                                setLoading(false);
                                window.location.replace(response.url);
                                break;
                            default:
                                if (language === 'es') {
                                    toastr.error("No se registró correctamente tus respuestas.", "¡Ooops!");
                                } else {
                                    toastr.error("Your responses were not successfully submitted.", "¡Ooops!");
                                }
                                break;
                        }
                    } else {
                        if (language === 'es') {
                            toastr.error("No se registró correctamente tus respuestas.", "¡Ooops!");
                        } else {
                            toastr.error("Your responses were not successfully submitted.", "¡Ooops!");
                        }
                    }
                });
            }
            if (showSections === true && showSection_5 === false) {
                setLoading(true);
                updFinishSurveyEE(idCollab, idEnterprise, surveyId, idGroup, "5").then((response) => {
                    if (response) {
                        switch (response.code) {
                            case 1:
                                if (language === 'es') {
                                    toastr.success("Se registró correctamente tus respuestas.", "¡Éxito!");
                                } else {
                                    toastr.success("Your responses have been successfully submitted.", "Success!");
                                }
                                setLoading(false);
                                window.location.replace(response.url);
                                break;
                            case 2:
                                if (language === 'es') {
                                    toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                                } else {
                                    toastr.success("The survey has already been completed.", "Success!");
                                }
                                setShowMessage(true);
                                break;
                            case 3:
                                if (language === 'es') {
                                    toastr.success("Se registró correctamente tus respuestas.", "¡Éxito!");
                                } else {
                                    toastr.success("Your responses have been successfully submitted.", "Success!");
                                }
                                setLoading(false);
                                window.location.replace(response.url);
                                break;
                            case 5:
                                if (language === 'es') {
                                    toastr.success("Se registró correctamente tus respuestas.", "¡Éxito!");
                                } else {
                                    toastr.success("Your responses have been successfully submitted.", "Success!");
                                }
                                setLoading(false);
                                window.location.replace(response.url);
                                break;
                            default:
                                if (language === 'es') {
                                    toastr.error("No se registró correctamente tus respuestas.", "¡Ooops!");
                                } else {
                                    toastr.error("Your responses were not successfully submitted.", "¡Ooops!");
                                }
                                break;
                        }
                    } else {
                        if (language === 'es') {
                            toastr.error("No se registró correctamente tus respuestas.", "¡Ooops!");
                        } else {
                            toastr.error("Your responses were not successfully submitted.", "¡Ooops!");
                        }
                    }
                });
            }
            if (showSections === true && showSection_5 === true) {
                setLoading(true);
                updFinishSurveyEE(idCollab, idEnterprise, surveyId, idGroup, "").then((response) => {
                    if (response) {
                        switch (response.code) {
                            case 1:
                                if (language === 'es') {
                                    toastr.success("Se registró correctamente tus respuestas.", "¡Éxito!");
                                } else {
                                    toastr.success("Your responses have been successfully submitted.", "Success!");
                                }
                                setLoading(false);
                                window.location.replace(response.url);
                                break;
                            case 2:
                                if (language === 'es') {
                                    toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                                } else {
                                    toastr.success("The survey has already been completed.", "Success!");
                                }
                                setShowMessage(true);
                                break;
                            case 3:
                                if (language === 'es') {
                                    toastr.success("Se registró correctamente tus respuestas.", "¡Éxito!");
                                } else {
                                    toastr.success("Your responses have been successfully submitted.", "Success!");
                                }
                                setLoading(false);
                                window.location.replace(response.url);
                                break;
                            case 5:
                                if (language === 'es') {
                                    toastr.success("Se registró correctamente tus respuestas.", "¡Éxito!");
                                } else {
                                    toastr.success("Your responses have been successfully submitted.", "Success!");
                                }
                                setLoading(false);
                                window.location.replace(response.url);
                                break;
                            default:
                                if (language === 'es') {
                                    toastr.error("No se registró correctamente tus respuestas.", "¡Ooops!");
                                } else {
                                    toastr.error("Your responses were not successfully submitted.", "¡Ooops!");
                                }
                                break;
                        }
                    } else {
                        if (language === 'es') {
                            toastr.error("No se registró correctamente tus respuestas.", "¡Ooops!");
                        } else {
                            toastr.error("Your responses were not successfully submitted.", "¡Ooops!");
                        }
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const privacyCheck = () => {
        if (privacy === true) {
            setPrivacy(false);
        }
        if (privacy === false) {
            setPrivacy(true);
        }
    }

    const handleClickUp = () => {
        // element which needs to be scrolled to
        const element = document.getElementById("start");
        // scroll to element
        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
    }

    if (errorPage) {
        return (
            <div className="container-fluid">
                <SurveyError language={language} />
            </div>
        )
    } else {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                {loading === true
                    ?
                    (
                        showMessage === true
                            ?
                            (
                                <SurveyComplete language={language} />
                            )
                            :
                            (
                                <LoaderSurvey language={language} />
                            )
                    )
                    :
                    (
                        <div className="row">
                            <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center">
                                <LanguageInput setLanguage={setLanguage} language={language} />
                            </div>
                            <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center" id="start">
                                <img src="../img/logo_guay_color.png" alt="logo-guay" style={{ width: '100px' }} />
                            </div>
                            <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center mt-1 font-large-1">
                                <span className="text-base-guay font-weight-bold">{language === 'es' ? 'Estado Emocional' : 'Emotional State'}</span>
                            </div>
                            <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-3">
                                <span>{language === 'es' ? `¡Hola` : `Hi`} <span className="font-weight-bold">{collabName}</span>!</span>
                            </div>
                            <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-3">
                                <span>{language === 'es' ? 'Ayúdanos completando el 100% de las respuestas' : 'Help us by completing 100% of the responses.'}</span>
                            </div>
                            < ATSSection1
                                section_1={section_1}
                                setSection_1={setSection_1}
                                setShowSection_5={setShowSection_5}
                                setShowSections={setShowSections}
                                todayDate={todayDate}
                                coordinates={coordinates}
                                OS={OS}
                                browser={browser}
                                idEnterprise={idEnterprise}
                                idGroup={idGroup}
                                idCollab={idCollab}
                                section_5={section_5}
                                setSection_1Complete={setSection_1Complete}
                                setSection_5Complete={setSection_5Complete}
                                setSurveyComplete={setSurveyComplete}
                                enterpriseName={enterpriseData.short}
                                language={language}
                            />
                            {showSection_5 === true
                                ?
                                (
                                    < ATSSection5
                                        section_5={section_5}
                                        setSection_5={setSection_5}
                                        setShowSections={setShowSections}
                                        setSurveyComplete={setSurveyComplete}
                                        todayDate={todayDate}
                                        coordinates={coordinates}
                                        OS={OS}
                                        browser={browser}
                                        idEnterprise={idEnterprise}
                                        idGroup={idGroup}
                                        idCollab={idCollab}
                                        setSection_5Complete={setSection_5Complete}
                                        language={language}
                                    />
                                )
                                :
                                (
                                    null
                                )
                            }
                            {showSections === true
                                ?
                                (
                                    < ATSSection2
                                        section_2={section_2}
                                        setSection_2={setSection_2}
                                        setSection_2Complete={setSection_2Complete}
                                        todayDate={todayDate}
                                        coordinates={coordinates}
                                        OS={OS}
                                        browser={browser}
                                        idEnterprise={idEnterprise}
                                        idGroup={idGroup}
                                        idCollab={idCollab}
                                        language={language}
                                    />
                                )
                                :
                                (
                                    null
                                )
                            }
                            {showSections === true
                                ?
                                (
                                    < ATSSection3
                                        section_3={section_3}
                                        setSection_3={setSection_3}
                                        setSection_3Complete={setSection_3Complete}
                                        todayDate={todayDate}
                                        coordinates={coordinates}
                                        OS={OS}
                                        browser={browser}
                                        idEnterprise={idEnterprise}
                                        idGroup={idGroup}
                                        idCollab={idCollab}
                                        language={language}
                                    />
                                )
                                :
                                (
                                    null
                                )
                            }
                            {showSections === true
                                ?
                                (
                                    < ATSSection4
                                        section_4={section_4}
                                        setSection_4={setSection_4}
                                        setSection_4Complete={setSection_4Complete}
                                        todayDate={todayDate}
                                        coordinates={coordinates}
                                        OS={OS}
                                        browser={browser}
                                        idEnterprise={idEnterprise}
                                        idGroup={idGroup}
                                        idCollab={idCollab}
                                        language={language}
                                    />
                                )
                                :
                                (
                                    null
                                )
                            }
                            {
                                (section_1Complete === true && showSection_5 === true && surveyComplete15 === true) || (section_1Complete === true && section_2Complete === true && section_3Complete === true && section_4Complete === true && section_5Complete === true) || (section_1Complete === true && section_2Complete === true && section_3Complete === true && section_4Complete === true && showSection_5 === false)
                                    ?
                                    (
                                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-3 d-flex justify-content-center">
                                            <button className="btn btn-sm btn-blue-3 font-medium-1" onClick={submitSurvey}>{linkedsurvey ? language === 'es' ? 'Siguiente' : 'Next' : language === 'es' ? 'Finalizar' : 'Finish'}</button>
                                        </div>
                                    )
                                    :
                                    (
                                        null
                                    )
                            }
                            <button className="arrow-up" onClick={handleClickUp}><i className="ft-arrow-up icon-medium-size" /></button>
                        </div>
                    )
                }
                {/*MODAL*/}
                {/*<!-- Complete information modal -->*/}
                {surveyTailCounter === 0 && (
                    <div className="modal" id="modal-1" style={{ backgroundColor: 'rgba(255,255,255,0.9)' }} data-backdrop="false">
                        <div className="modal-dialog modal-dialog-centered modal-xl">
                            <div className="modal-content col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto" style={{ backgroundColor: 'transparent', border: 'none' }}>

                                <div className="modal-body modal-div-bodered container-style-1 pb-5">
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 d-flex justify-content-center">
                                        <img src="../img/Mensaje-encuesta_1.png" className="w-60" alt="instrucciones"></img>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                        <h1 className="font-weight-bold">Instrucciones</h1>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 font-medium-1 mt-1">
                                        <p className="text-justify">En la primera parte encontrarás el cuestionario donde se identifican los <span className="text-pink-mine font-weight-bold">Acontecimientos Traumáticos Severos (ATS) durante tu jornada o con motivo del trabajo</span> que desempeñas actualmente. Posteriormente te preguntaremos por los ATS pero ocurridos <span className="text-pink-mine font-weight-bold">fuera de tu jornada laboral o en tu trabajo anterior.</span> </p>
                                        <div className="col-12 pl-5">Ambos cuestionarios de Acontecimientos Traumáticos Severos contienen 4 secciones:
                                            <p>1. El tipo de acontecimiento <span className="font-weight-bold">(no importa hace cuanto lo sufriste)</span></p>
                                            <p>2. Recuerdos persistentes sobre el acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                                            <p>3. Circunstancias parecidas o asociadas al acontecimiento  <span className="font-weight-bold">(durante el último mes)</span></p>
                                            <p>4. Las afectaciones <span className="font-weight-bold">(durante el último mes)</span></p>
                                        </div>
                                        <p className="text-justify">En la segunda parte hacemos el <span className="text-pink-mine font-weight-bold">diagnóstico de los Factores de Riesgos Psicosocial</span> en tu centro de trabajo <span className="font-weight-bold">(considera las condiciones de los últimos dos meses).</span></p>
                                        <p className="text-justify">Por último, preguntaremos algunos <span className="text-pink-mine font-weight-bold">datos demográficos</span> para conocerte mejor: edad, tiempo de traslado a tú lugar de trabajo, años en la organización, etc.</p>
                                        <p className="text-justify">Es importante que estés concentrado y <span className="text-pink-mine font-weight-bold">seas sincero</span> al momento de responder, recuerda que <span className="text-pink-mine font-weight-bold">no hay respuestas correctas o incorrectas.</span></p>
                                        <p className="text-justify">Estos cuestionarios dan <span className="text-pink-mine font-weight-bold">cumplimiento a la NOM-035</span> de la Secretaría del Trabajo, son de <span className="text-pink-mine font-weight-bold">carácter obligatorio,</span> la información obtenida será tratada de manera confidencial y los resultados serán exclusivamente con <span className="text-pink-mine font-weight-bold">fines de mejora</span> en las condiciones del ambiente de trabajo.</p>
                                        <p className="text-justify">Es necesario responder todos los reactivos para que el sistema nos permita obtener resultados confiables.</p>
                                        <p className="text-justify">Agradecemos tu colaboración</p>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                        <div className="form-group form-check">
                                            <label className="form-check-label pointer font-medium-1">
                                                <input type="checkbox" className="form-check-input" value="" onChange={() => privacyCheck()} /> Al dar click aceptas nuestro <span className="text-blue font-weight-bold" onClick={() => window.open("https://storage.googleapis.com/guay-api-production/Operacion/Plataforma/Aviso%20Privacidad%20GUAY%20Digital.pdf")}>aviso de privacidad</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                                        {privacy === true ? (<button className="btn btn-sm btn-blue-3 float-right font-medium-1" onClick={() => $('#modal-1').hide()}>Encuesta</button>) : (null)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {surveyTailCounter === 1 && (
                    <ATS
                        checkboxVal={privacy}
                        checkboxFunc={privacyCheck}
                        enterpriseLogo={enterpriseData.logo}
                        language={language}
                        setLanguage={setLanguage}
                        collabName={collabName}
                    />
                )}
                {surveyTailCounter === 2 && (
                    <ATSDEMO
                        checkboxVal={privacy}
                        checkboxFunc={privacyCheck}
                        enterpriseLogo={enterpriseData.logo}
                        language={language}
                        setLanguage={setLanguage}
                        collabName={collabName}
                    />
                )}
                {surveyTailCounter === 3 && (
                    <ATSFRPDEMO
                        checkboxVal={privacy}
                        checkboxFunc={privacyCheck}
                        enterpriseLogo={enterpriseData.logo}
                        language={language}
                        setLanguage={setLanguage}
                        collabName={collabName}
                    />
                )}
                {surveyTailCounter === 4 && (
                    <ATSFRP
                        checkboxVal={privacy}
                        checkboxFunc={privacyCheck}
                        enterpriseLogo={enterpriseData.logo}
                        language={language}
                        setLanguage={setLanguage}
                        collabName={collabName}
                    />
                )}
            </div>
        )
    }
}

export default ATSController;
