import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { validationAccessEL, saveResponseEL, updFinishSurveyEL } from '../Models/Survey_model';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IntroSurvey from './IntroSurvey';
import FRPDEMO from './IntroText/FRPDEMO';
import FRP from './IntroText/FRP';
import SurveyComplete from '../SurveyComplete';
import LoaderSurvey from '../LoaderSurvey';
import SurveyError from '../SurveyError';
import $ from "jquery";
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { getEnterpriseById } from '../enterprises/Model';
import { handleScroll } from '../../helpers/helpers';
import LanguageInput from './LanguageSwitch';

const EntornoLaboral = () => {
    let { identity } = useParams();
    let parser = "0-0-0-0";
    let parameters = "0-0-0-0";

    try {
        parser = atob(identity);
        parameters = atob(identity);
    } catch (error) {
        console.error(error)
    }

    parser = parser.split("-");

    const collaboratorId = parser[0];
    const idEnterprise = parser[1];
    const surveyId = parser[2];
    const idGroup = parser[3];

    const [section1to12, setSection1to12] = useState([]);
    const [showSec13, setShowSec13] = useState({
        value: null,
        showSec: false,
    });
    const [section13, setSection13] = useState([]);
    const [showSec14, setShowSec14] = useState({
        value: null,
        showSec: false,
    });
    const [section14, setSection14] = useState([]);

    const [loading, setLoading] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [errorPage, setErrorPage] = useState(false);

    const [userDate, setUserDate] = useState("");
    const [coordinates, setCoordinates] = useState("");
    const [browser, setBrowser] = useState("");
    const [os, setOs] = useState("");

    const [surveyTailCounter, setSurveyTailCounter] = useState(0)
    const [btnState, setBtnState] = useState(false)
    const [enterpriseData, setEnterpriseData] = useState({})
    const [linkedsurvey, setLinkedSurvey] = useState(false)
    const [language, setLanguage] = useState('es');
    const [collabName, setCollabName] = useState('');

    useEffect(() => {
        try {
            const structureData = (data) => {
                // console.log('structureData: ',data);
                const arrData = data.map(item => {
                    const arrQuestions = item.questions.map(question => {
                        const arrAnswers  = question.answers.map(answer => {
                            return {
                                check: answer.check,
                                description: language === 'es' ? answer.description : answer.description_en,
                                description_es: answer.description,
                                description_en: answer.description_en,
                                idanswer: answer.idanswer
                            }
                        });
                        return {
                            idquestion:question.idquestion,
                            description:language === 'es' ? question.description : question.description_en,
                            description_es: question.description,
                            description_en: question.description_en,
                            answers:arrAnswers
                        }
                    });
                    return {
                        description: language === 'es' ? item.description : item.description_en,
                        description_es: item.description,
                        description_en: item.description_en,
                        idsection: item.idsection,
                        questions: arrQuestions
                    }

                });
                return arrData;
            }
            getEnterpriseById(idEnterprise).then(response => {
                setEnterpriseData(response)
            })
            validationAccessEL(collaboratorId, idEnterprise, surveyId, idGroup).then((response) => {
                if (response) {
                    switch (response?.code) {
                        case 1:
                            setCollabName(response.collabName);
                            if (response.surveysCollab) {
                                let surveysTail = response.surveysCollab.split("-");
                                if (surveysTail.length === 1) {
                                    setSurveyTailCounter(1)
                                }
                                if (surveysTail.length === 2) {
                                    let missedSuv = response.missedSurveys.split("-")
                                    if (missedSuv.length > 1) {
                                        if (surveysTail[0] === 'EL' && surveysTail[1] === 'DEMO') {
                                            setLinkedSurvey(true)
                                            setSurveyTailCounter(2)
                                        }
                                    } else {
                                        setSurveyTailCounter(1)
                                    }
                                }
                                if (surveysTail.length === 3) {
                                    setLinkedSurvey(true)
                                    setSurveyTailCounter(3)
                                }
                            }
                            setLoading(false);
                            if (response.privacity === 0 && !btnState) {
                                $('#modal-1').show();
                            }
                            console.log('structureData: ',structureData(response.survey));
                            const surveyStructure = structureData(response.survey);
                            setSection1to12(surveyStructure.filter((section) => section.idsection !== 13 && section.idsection !== 14));
                            if (surveyStructure.find((section) => section.idsection === 13).questions.find((question) => question.answers.find((answer) => answer.check === 1)) !== undefined) {
                                setShowSec13({
                                    value: "Si",
                                    showSec: true
                                });
                            }
                            setSection13(surveyStructure.filter((section) => section.idsection === 13));
                            if (surveyStructure.find((section) => section.idsection === 14).questions.find((question) => question.answers.find((answer) => answer.check === 1)) !== undefined) {
                                setShowSec14({
                                    value: "Si",
                                    showSec: true
                                });
                            }
                            setSection14(surveyStructure.filter((section) => section.idsection === 14));
                            break;
                        case 2:
                            window.location.replace(response.url);
                            break;
                        case 3:
                            //encuesta cerrada mostrar mensaje de agradecimiento
                            setLoading(false);
                            setShowMessage(true);
                            break;
                        case 5:
                            window.location.replace(response.url);
                            break;
                        default:
                            if(language === 'es'){
                                toastr.error("No se ha podido cargar la encuesta. Recargue la página nuevamente.", "¡Ooops!");
                            }else{
                                toastr.error("The survey could not be loaded. Please reload the page.", "¡Ooops!");
                            }
                            setLoading(false);
                            setErrorPage(true);
                            break;
                    }
                } else {
                    if(language === 'es'){
                        toastr.error("No se ha podido cargar la encuesta. Recargue la página nuevamente.", "¡Ooops!");
                    }else{
                        toastr.error("The survey could not be loaded. Please reload the page.", "¡Ooops!");
                    }
                    setLoading(false);
                    setErrorPage(true);
                }
            });
        } catch (error) {
            console.log(error)
        }
        return () => {
        }
    }, [collaboratorId, idEnterprise, surveyId, idGroup, parameters]);

    useEffect(() => {
        const newSection1to12 = [...section1to12];
        const newSection13 = [...section13];
        const newSection14 = [...section14];
        if(newSection1to12.length > 0){
            const structureData = (data) => {
                const arrData = data.map(item => {
                    const arrQuestions = item.questions.map(question => {
                        const arrAnswers  = question.answers.map(answer => {
                            return {
                                check: answer.check,
                                description: language === 'es' ? answer.description_es : answer.description_en,
                                idanswer: answer.idanswer,
                                description_es: answer.description_es,
                                description_en: answer.description_en,
                            }
                        });
                        return {
                            idquestion:question.idquestion,
                            description:language === 'es' ? question.description_es : question.description_en,
                            answers:arrAnswers,
                            description_es: question.description_es,
                            description_en: question.description_en,
                        }
                    });
                    return {
                        description: language === 'es' ? item.description_es : item.description_en,
                        idsection: item.idsection,
                        questions: arrQuestions,
                        description_es: item.description_es,
                        description_en: item.description_en,
                    }
    
                });
                // console.log('arrData: ',arrData);
                return arrData;
            }
            setSection1to12(structureData(newSection1to12));
            if(newSection13.length > 0){
                setSection13(structureData(newSection13))
            }
            if(newSection14.length > 0){
                setSection14(structureData(newSection14))
            }

        }
       
        
    }, [language])
    

    const data = () => {
        // fecha, horas, minutos y segundos
        let fechaActual = new Date();
        let today = new Date();
        let y = today.getFullYear();
        let mm = today.getMonth() + 1;
        let dd = today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
        let mm_2 = mm < 10 ? "0" + mm : mm;
        let hours = today.getHours() < 10 ? "0" + today.getHours() : today.getHours();
        let minutes = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
        let seconds = today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds();
        fechaActual = "" + y + "-" + mm_2 + "-" + dd + " " + hours + ":" + minutes + ":" + seconds;
        setUserDate(fechaActual);
        // navegador
        const brow = (function (agent) {
            switch (true) {
                case agent.indexOf("edge") > -1:
                    return "edge";
                case agent.indexOf("edg") > -1:
                    return "chromium based edge (dev or canary)";
                case agent.indexOf("opr") > -1 && !!window.opr:
                    return "opera";
                case agent.indexOf("chrome") > -1 && !!window.chrome:
                    return "chrome";
                case agent.indexOf("trident") > -1:
                    return "ie";
                case agent.indexOf("firefox") > -1:
                    return "firefox";
                case agent.indexOf("safari") > -1:
                    return "safari";
                default:
                    return "";
            }
        })(window.navigator.userAgent.toLowerCase());
        setBrowser(brow);
        // sistema operativo
        let so = (function (navInfo) {
            switch (true) {
                case navInfo.indexOf("win") !== -1:
                    return "Windows";
                case navInfo.indexOf("linux") !== -1:
                    return "Linux";
                case navInfo.indexOf("mac") !== -1:
                    return "Macintosh";
                default:
                    return "";
            }
        })(window.navigator.appVersion.toLowerCase());
        setOs(so);
        // coordenadas geograficas
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let latitude = position.coords.latitude;
                let longitude = position.coords.longitude;
                let coordinates = "" + latitude + "," + longitude + "";
                setCoordinates(coordinates);
            });
        }
    }

    const handleChange = (event, indiceAnswer, indiceQuestion, indiceSection) => {
        let newArr = [...section1to12]; // copying the old datas array
        for (let index = 0; index < newArr[indiceSection].questions[indiceQuestion].answers.length; index++) {
            if (index === indiceAnswer) {
                /* newArr[indiceSection].questions[indiceQuestion].answers[index].check = 1;
                const checkQuestionSectionsLength = newArr[indiceSection].questions.length;
                const checkSectionsLength = newArr.length;
                console.log(checkSectionsLength)
                console.log(`indiceSection: ${indiceSection}`, `indiceQuestion: ${indiceQuestion}`, `checkquestionSectiolength: ${checkQuestionSectionsLength}`, `indiceQuestion+1: ${indiceQuestion + 1}`, `indiceSection: ${indiceSection + 1}`);
                if (indiceQuestion + 1 >= checkQuestionSectionsLength) {
                    if (indiceSection + 1 >= checkSectionsLength) {
                        // element which needs to be scrolled to
                        const element = document.getElementById("QuestionToSection13");
                        // scroll to element
                        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                    } else {
                        // element which needs to be scrolled to
                        const element = document.getElementById(`${indiceSection + 1}-${0}`);
                        // scroll to element
                        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                    }
                } else {
                    // element which needs to be scrolled to
                    const element = document.getElementById(`${indiceSection}-${indiceQuestion + 1}`);
                    // scroll to element
                    element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                }
                data(); */
                saveResponseEL(newArr[indiceSection].questions[indiceQuestion].answers[index].idanswer, newArr[indiceSection].questions[indiceQuestion].idquestion, idEnterprise, idGroup, collaboratorId, JSON.stringify(userDate), JSON.stringify(coordinates), JSON.stringify(browser), JSON.stringify(os)).then((response) => {
                    if (response) {
                        switch (response?.code) {
                            case 1:
                                //console.log("respuesta guardada");
                                newArr[indiceSection].questions[indiceQuestion].answers[index].check = 1;
                                const checkQuestionSectionsLength = newArr[indiceSection].questions.length;
                                const checkSectionsLength = newArr.length;
                                //console.log(checkSectionsLength)
                                //console.log(`indiceSection: ${indiceSection}`, `indiceQuestion: ${indiceQuestion}`, `checkquestionSectiolength: ${checkQuestionSectionsLength}`, `indiceQuestion+1: ${indiceQuestion + 1}`, `indiceSection: ${indiceSection + 1}`);
                                if (indiceQuestion + 1 >= checkQuestionSectionsLength) {
                                    if (indiceSection + 1 >= checkSectionsLength) {
                                        // element which needs to be scrolled to
                                        const element = document.getElementById("QuestionToSection13");
                                        // scroll to element
                                        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                                    } else {
                                        // element which needs to be scrolled to
                                        const element = document.getElementById(`${indiceSection + 1}-${0}`);
                                        // scroll to element
                                        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                                    }
                                } else {
                                    // element which needs to be scrolled to
                                    const element = document.getElementById(`${indiceSection}-${indiceQuestion + 1}`);
                                    // scroll to element
                                    element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                                }
                                data();
                                break;
                            case 99:
                                if(language === 'es'){
                                    toastr.error("No se guardó su respuesta, recargue la página.");
                                }else{
                                    toastr.error("Your response was not saved, please reload the page.");
                                }
                                break;
                            default:
                                if(language === 'es'){
                                    toastr.warning("No se guardó su respuesta. Verifique su conexión a internet. Si el problema persiste, comuníquese con el equipo de soporte.");
                                }else{
                                    toastr.warning("Your response was not saved. Check your internet connection. If the problem persists, contact the support team.");
                                }
                                console.log("error")
                                break;
                        }
                    } else {
                        if(language === 'es'){
                            toastr.warning("No se guardó su respuesta. Verifique su conexión a internet. Si el problema persiste, comuníquese con el equipo de soporte.");
                        }else{
                            toastr.warning("Your response was not saved. Check your internet connection. If the problem persists, contact the support team.");
                        }
                    }
                });
            } else {
                newArr[indiceSection].questions[indiceQuestion].answers[index].check = 0;
            }
        }
        setSection1to12(newArr);
    };

    const handleChangeSec13 = (event, indiceAnswer, indiceQuestion, indiceSection) => {
        let newArr = [...section13]; // copying the old datas array
        for (let index = 0; index < newArr[indiceSection].questions[indiceQuestion].answers.length; index++) {//finding the specific question
            if (index === indiceAnswer) {//find the index that match the selected radio
                /* newArr[indiceSection].questions[indiceQuestion].answers[index].check = 1; //checking the answer
                const checkQuestionSectionsLength = newArr[indiceSection].questions.length;
                const checkSectionsLength = newArr.length;
                console.log(checkSectionsLength)
                console.log(`indiceSection: ${indiceSection}`, `indiceQuestion: ${indiceQuestion}`, `checkquestionSectiolength: ${checkQuestionSectionsLength}`, `indiceQuestion+1: ${indiceQuestion + 1}`, `indiceSection: ${indiceSection + 1}`);
                if (indiceQuestion + 1 >= checkQuestionSectionsLength) {
                    if (indiceSection + 1 >= checkSectionsLength) {
                        // element which needs to be scrolled to
                        const element = document.getElementById("QuestionToSection14");
                        // scroll to element
                        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                    }
                } else {
                    // element which needs to be scrolled to
                    const element = document.getElementById(`13-${indiceSection}-${indiceQuestion + 1}`);
                    // scroll to element
                    element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                }
                data(); */
                saveResponseEL(newArr[indiceSection].questions[indiceQuestion].answers[index].idanswer, newArr[indiceSection].questions[indiceQuestion].idquestion, idEnterprise, idGroup, collaboratorId, JSON.stringify(userDate), JSON.stringify(coordinates), JSON.stringify(browser), JSON.stringify(os)).then((response) => {
                    if (response) {
                        switch (response?.code) {
                            case 1:
                                //console.log("respuesta guardada");
                                newArr[indiceSection].questions[indiceQuestion].answers[index].check = 1; //checking the answer
                                const checkQuestionSectionsLength = newArr[indiceSection].questions.length;
                                const checkSectionsLength = newArr.length;
                                //console.log(checkSectionsLength)
                                //console.log(`indiceSection: ${indiceSection}`, `indiceQuestion: ${indiceQuestion}`, `checkquestionSectiolength: ${checkQuestionSectionsLength}`, `indiceQuestion+1: ${indiceQuestion + 1}`, `indiceSection: ${indiceSection + 1}`);
                                if (indiceQuestion + 1 >= checkQuestionSectionsLength) {
                                    if (indiceSection + 1 >= checkSectionsLength) {
                                        // element which needs to be scrolled to
                                        const element = document.getElementById("QuestionToSection14");
                                        // scroll to element
                                        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                                    }
                                } else {
                                    // element which needs to be scrolled to
                                    const element = document.getElementById(`13-${indiceSection}-${indiceQuestion + 1}`);
                                    // scroll to element
                                    element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                                }
                                data();
                                break;
                            case 99:
                                if(language === 'es'){
                                    toastr.error("No se guardó su respuesta, recargue la página.");
                                }else{
                                    toastr.error("Your response was not saved, please reload the page.");
                                }
                                break;
                            default:
                                if(language === 'es'){
                                    toastr.warning("No se guardó su respuesta. Verifique su conexión a internet. Si el problema persiste, comuníquese con el equipo de soporte.");
                                }else{
                                    toastr.warning("Your response was not saved. Check your internet connection. If the problem persists, contact the support team.");
                                }
                                console.log("error")
                                break;
                        }
                    } else {
                        if(language === 'es'){
                            toastr.warning("No se guardó su respuesta. Verifique su conexión a internet. Si el problema persiste, comuníquese con el equipo de soporte.");
                        }else{
                            toastr.warning("Your response was not saved. Check your internet connection. If the problem persists, contact the support team.");
                        }
                    }
                });
            } else {
                newArr[indiceSection].questions[indiceQuestion].answers[index].check = 0; //uncheck all other answers
            }
        }
        setSection13(newArr);
    };

    const handleChangeSec14 = (event, indiceAnswer, indiceQuestion, indiceSection) => {
        let newArr = [...section14]; // copying the old datas array
        for (let index = 0; index < newArr[indiceSection].questions[indiceQuestion].answers.length; index++) {
            if (index === indiceAnswer) {
                /* newArr[indiceSection].questions[indiceQuestion].answers[index].check = 1;
                const checkQuestionSectionsLength = newArr[indiceSection].questions.length;
                const checkSectionsLength = newArr.length;
                console.log(checkSectionsLength)
                console.log(`indiceSection: ${indiceSection}`, `indiceQuestion: ${indiceQuestion}`, `checkquestionSectiolength: ${checkQuestionSectionsLength}`, `indiceQuestion+1: ${indiceQuestion + 1}`, `indiceSection: ${indiceSection + 1}`);
                if (indiceQuestion + 1 >= checkQuestionSectionsLength) {
                    if (indiceSection + 1 >= checkSectionsLength) {
                        //fin de encuesta
                    }
                } else {
                    // element which needs to be scrolled to
                    const element = document.getElementById(`14-${indiceSection}-${indiceQuestion + 1}`);
                    // scroll to element
                    element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                }
                data(); */
                saveResponseEL(newArr[indiceSection].questions[indiceQuestion].answers[index].idanswer, newArr[indiceSection].questions[indiceQuestion].idquestion, idEnterprise, idGroup, collaboratorId, JSON.stringify(userDate), JSON.stringify(coordinates), JSON.stringify(browser), JSON.stringify(os)).then((response) => {
                    if (response) {
                        switch (response?.code) {
                            case 1:
                                //console.log("respuesta guardada");
                                newArr[indiceSection].questions[indiceQuestion].answers[index].check = 1;
                                const checkQuestionSectionsLength = newArr[indiceSection].questions.length;
                                const checkSectionsLength = newArr.length;
                                //console.log(checkSectionsLength)
                                //console.log(`indiceSection: ${indiceSection}`, `indiceQuestion: ${indiceQuestion}`, `checkquestionSectiolength: ${checkQuestionSectionsLength}`, `indiceQuestion+1: ${indiceQuestion + 1}`, `indiceSection: ${indiceSection + 1}`);
                                if (indiceQuestion + 1 >= checkQuestionSectionsLength) {
                                    if (indiceSection + 1 >= checkSectionsLength) {
                                        //fin de encuesta
                                    }
                                } else {
                                    // element which needs to be scrolled to
                                    const element = document.getElementById(`14-${indiceSection}-${indiceQuestion + 1}`);
                                    // scroll to element
                                    element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                                }
                                data();
                                break;
                            case 99:
                                if(language === 'es'){
                                    toastr.error("No se guardó su respuesta, recargue la página.");
                                }else{
                                    toastr.error("Your response was not saved, please reload the page.");
                                }
                                break;
                            default:
                                if(language === 'es'){
                                    toastr.warning("No se guardó su respuesta. Verifique su conexión a internet. Si el problema persiste, comuníquese con el equipo de soporte.");
                                }else{
                                    toastr.warning("Your response was not saved. Check your internet connection. If the problem persists, contact the support team.");
                                }
                                console.log("error")
                                break;
                        }
                    } else {
                        if(language === 'es'){
                            toastr.warning("No se guardó su respuesta. Verifique su conexión a internet. Si el problema persiste, comuníquese con el equipo de soporte.");
                        }else{
                            toastr.warning("Your response was not saved. Check your internet connection. If the problem persists, contact the support team.");
                        }
                    }
                });
            } else {
                newArr[indiceSection].questions[indiceQuestion].answers[index].check = 0;
            }
        }
        setSection14(newArr);
    };

    const handleChangeSection13 = (e) => {
        if (e.target.value === "Si") {
            setShowSec13({
                value: e.target.value,
                showSec: true
            });
            try {
                setTimeout(() => {
                    // element which needs to be scrolled to
                    const element = document.getElementById("13-0-0");
                    // scroll to element
                    element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                }, 500);
            } catch (error) {
                console.warn("El renderizado es lento");
            }
        } else {
            setShowSec13({
                value: e.target.value,
                showSec: false
            });
            try {
                setTimeout(() => {
                    // element which needs to be scrolled to
                    const element = document.getElementById("QuestionToSection14");
                    // scroll to element
                    element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                }, 500);
            } catch (error) {
                console.warn("El renderizado es lento");
            }
        }
    }

    const handleChangeSection14 = (e) => {
        if (e.target.value === "Si") {
            setShowSec14({
                value: e.target.value,
                showSec: true
            });
            try {
                setTimeout(() => {
                    // element which needs to be scrolled to
                    const element = document.getElementById("14-0-0");
                    // scroll to element
                    element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
                }, 500);
            } catch (error) {
                console.warn("El renderizado es lento");
            }
        } else {
            setShowSec14({
                value: e.target.value,
                showSec: false
            });
        }
    }

    const handleClickFinish = () => {
        let total = 0;
        if (showSec13.showSec === false && showSec14.showSec === false) {
            let local1to12 = section1to12;
            let preguntaFaltante = 0;
            let seccion = 0;
            let preguntaFal = 0;
            for (let index = 0; index < local1to12.length; index++) {
                const element = local1to12[index];
                let pregunta = 0;
                for (let index = 0; index < element.questions.length; index++) {
                    pregunta = pregunta + 1;
                    const element2 = element.questions[index];
                    let contadorRespuestas = 0;
                    for (let index = 0; index < element2.answers.length; index++) {
                        const element3 = element2.answers[index];
                        if (element3.check === 1) {
                            total = total + 1;
                            contadorRespuestas = 1;
                            break;
                        }
                    }
                    if (contadorRespuestas === 0 && seccion === 0) {
                        preguntaFaltante = element2.idquestion;
                        seccion = element.idsection;
                        preguntaFal = pregunta;
                        break;
                    }
                }
            }
            if(preguntaFaltante > 0){
                handleScroll(`${seccion - 1}-${preguntaFal - 1}`);
            }
            if (total === 64) {
                updFinishSurveyEL(collaboratorId, idEnterprise, surveyId, idGroup, "13,14").then((response) => {
                    if (response) {
                        switch (response?.code) {
                            case 1:
                                if(language === 'es'){
                                    toastr.success("Se registró correctamente tus respuestas.", "¡Éxito!");
                                }else{
                                    toastr.success("Your responses have been successfully submitted.", "Success!");
                                }
                                window.location.replace(response.url);
                                break;
                            case 2:
                                if(language === 'es'){
                                    toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                                }else{
                                    toastr.success("The survey has already been completed.", "Success!");
                                }
                                //mandar mensaje de agradecimiento
                                setShowMessage(true);
                                break;
                            case 5:
                                window.location.replace(response.url);
                                break;
                            default:
                                if(language === 'es'){
                                    toastr.error("No se pudo finalizar la encuesta, no te preocupes las respuestas están guardas, recarga tu página");
                                }else{
                                    toastr.error("The survey could not be completed, but don't worry, your responses are saved. Please reload the page.");
                                }
                                break;
                        }
                    } else {
                        if(language === 'es'){
                            toastr.error("No se registró correctamente tus respuestas.", "¡Ooops!");
                        }else{
                            toastr.error("Your responses were not successfully submitted.", "¡Ooops!");
                        }
                    }
                });
            } else {
                if(language === 'es'){
                    toastr.info("Aún hay preguntas sin responder");
                }else{
                    toastr.info("There are still unanswered questions.");
                }
            }
        } else if (showSec13.showSec !== false && showSec14.showSec === false) {
            //let local1to13 = section1to12;
            //local1to13.push(section13[0]);
            let preguntaFaltante = 0;
            let seccion = 0;
            let preguntaFal = 0;
            for (let index = 0; index < section1to12.length; index++) {
                const element = section1to12[index];
                let pregunta = 0;
                for (let index = 0; index < element.questions.length; index++) {
                    pregunta = pregunta + 1;
                    const element2 = element.questions[index];
                    let contadorRespuestas = 0;
                    for (let index = 0; index < element2.answers.length; index++) {
                        const element3 = element2.answers[index];
                        if (element3.check === 1) {
                            total = total + 1;
                            contadorRespuestas = 1;
                            break;
                        }
                    }
                    if (contadorRespuestas === 0 && seccion === 0) {
                        preguntaFaltante = element2.idquestion;
                        seccion = element.idsection;
                        preguntaFal = pregunta;
                        break;
                    }
                }
            }
            for (let index = 0; index < section13.length; index++) {
                const element = section13[index];
                let pregunta = 0;
                for (let index = 0; index < element.questions.length; index++) {
                    pregunta = pregunta + 1;
                    const element2 = element.questions[index];
                    let contadorRespuestas = 0;
                    for (let index = 0; index < element2.answers.length; index++) {
                        const element3 = element2.answers[index];
                        if (element3.check === 1) {
                            total = total + 1;
                            contadorRespuestas = 1;
                            break;
                        }
                    }
                    if (contadorRespuestas === 0 && seccion === 0) {
                        preguntaFaltante = element2.idquestion;
                        seccion = element.idsection;
                        preguntaFal = pregunta;
                        break;
                    }
                }
            }
            if (preguntaFaltante > 0) {
                if (seccion === 13) {
                    handleScroll(`${seccion}-0-${preguntaFal - 1}`);
                } else {
                    handleScroll(`${seccion - 1}-${preguntaFal - 1}`);
                }
            }
            if (total === 68) {
                updFinishSurveyEL(collaboratorId, idEnterprise, surveyId, idGroup, "14").then((response) => {
                    if (response) {
                        switch (response?.code) {
                            case 1:
                                if(language === 'es'){
                                    toastr.success("Se registró correctamente tus respuestas.", "¡Éxito!");
                                }else{
                                    toastr.success("Your responses have been successfully submitted.", "Success!");
                                }
                                window.location.replace(response.url);
                                break;
                            case 2:
                                if(language === 'es'){
                                    toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                                }else{
                                    toastr.success("The survey has already been completed.", "Success!");
                                }
                                //mandar mensaje de agradecimiento
                                setShowMessage(true);
                                break;
                            case 5:
                                window.location.replace(response.url);
                                break;
                            default:
                                if(language === 'es'){
                                    toastr.error("No se pudo finalizar la encuesta, no te preocupes las respuestas están guardas, recarga tu página");
                                }else{
                                    toastr.error("The survey could not be completed, but don't worry, your responses are saved. Please reload the page.");
                                }
                                break;
                        }
                    } else {
                        if(language === 'es'){
                            toastr.error("No se registró correctamente tus respuestas.", "¡Ooops!");
                        }else{
                            toastr.error("Your responses were not successfully submitted.", "¡Ooops!");
                        }
                    }
                });
            } else {
                if(language === 'es'){
                    toastr.info("Aún hay preguntas sin responder");
                }else{
                    toastr.info("There are still unanswered questions.");
                }
            }
        } else if (showSec13.showSec === false && showSec14.showSec !== false) {
            //let local1to14 = section1to12;
            //local1to14.push(section14[0]);
            let preguntaFaltante = 0;
            let seccion = 0;
            let preguntaFal = 0;
            for (let index = 0; index < section1to12.length; index++) {
                const element = section1to12[index];
                let pregunta = 0;
                for (let index = 0; index < element.questions.length; index++) {
                    pregunta = pregunta + 1;
                    const element2 = element.questions[index];
                    let contadorRespuestas = 0;
                    for (let index = 0; index < element2.answers.length; index++) {
                        const element3 = element2.answers[index];
                        if (element3.check === 1) {
                            total = total + 1;
                            contadorRespuestas = 1;
                            break;
                        }
                    }
                    if (contadorRespuestas === 0 && seccion === 0) {
                        preguntaFaltante = element2.idquestion;
                        seccion = element.idsection;
                        preguntaFal = pregunta;
                        break;
                    }
                }
            }
            for (let index = 0; index < section14.length; index++) {
                const element = section14[index];
                let pregunta = 0;
                for (let index = 0; index < element.questions.length; index++) {
                    pregunta = pregunta + 1;
                    const element2 = element.questions[index];
                    let contadorRespuestas = 0;
                    for (let index = 0; index < element2.answers.length; index++) {
                        const element3 = element2.answers[index];
                        if (element3.check === 1) {
                            total = total + 1;
                            contadorRespuestas = 1;
                            break;
                        }
                    }
                    if (contadorRespuestas === 0 && seccion === 0) {
                        preguntaFaltante = element2.idquestion;
                        seccion = element.idsection;
                        preguntaFal = pregunta;
                        break;
                    }
                }
            }
            if (preguntaFaltante > 0) {
                if (seccion === 14) {
                    handleScroll(`${seccion}-0-${preguntaFal - 1}`);
                } else {
                    handleScroll(`${seccion - 1}-${preguntaFal - 1}`);
                }
            }
            if (total === 68) {
                updFinishSurveyEL(collaboratorId, idEnterprise, surveyId, idGroup, "13").then((response) => {
                    if (response) {
                        switch (response?.code) {
                            case 1:
                                if(language === 'es'){
                                    toastr.success("Se registró correctamente tus respuestas.", "¡Éxito!");
                                }else{
                                    toastr.success("Your responses have been successfully submitted.", "Success!");
                                }
                                window.location.replace(response.url);
                                break;
                            case 2:
                                if(language === 'es'){
                                    toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                                }else{
                                    toastr.success("The survey has already been completed.", "Success!");
                                }
                                //mandar mensaje de agradecimiento
                                setShowMessage(true);
                                break;
                            case 5:
                                window.location.replace(response.url);
                                break;
                            default:
                                if(language === 'es'){
                                    toastr.error("No se pudo finalizar la encuesta, no te preocupes las respuestas están guardas, recarga tu página");
                                }else{
                                    toastr.error("The survey could not be completed, but don't worry, your responses are saved. Please reload the page.");
                                }
                                break;
                        }
                    } else {
                        if(language === 'es'){
                            toastr.error("No se registró correctamente tus respuestas.", "¡Ooops!");
                        }else{
                            toastr.error("Your responses were not successfully submitted.", "¡Ooops!");
                        }
                    }
                });
            } else {
                if(language === 'es'){
                    toastr.info("Aún hay preguntas sin responder");
                }else{
                    toastr.info("There are still unanswered questions.");
                }
            }
        } else if (showSec13.showSec !== false && showSec14.showSec !== false) {
            //let local = section1to12;
            //local.push(section13[0]);
            //local.push(section14[0]);
            let preguntaFaltante = 0;
            let seccion = 0;
            let preguntaFal = 0;
            for (let index = 0; index < section1to12.length; index++) {
                const element = section1to12[index];
                let pregunta = 0;
                for (let index = 0; index < element.questions.length; index++) {
                    pregunta = pregunta + 1;
                    const element2 = element.questions[index];
                    let contadorRespuestas = 0;
                    for (let index = 0; index < element2.answers.length; index++) {
                        const element3 = element2.answers[index];
                        if (element3.check === 1) {
                            total = total + 1;
                            contadorRespuestas = 1;
                            break;
                        }
                    }
                    if (contadorRespuestas === 0 && seccion === 0) {
                        preguntaFaltante = element2.idquestion;
                        seccion = element.idsection;
                        preguntaFal = pregunta;
                        break;
                    }
                }
            }
            for (let index = 0; index < section13.length; index++) {
                const element = section13[index];
                let pregunta = 0;
                for (let index = 0; index < element.questions.length; index++) {
                    pregunta = pregunta + 1;
                    const element2 = element.questions[index];
                    let contadorRespuestas = 0;
                    for (let index = 0; index < element2.answers.length; index++) {
                        const element3 = element2.answers[index];
                        if (element3.check === 1) {
                            total = total + 1;
                            contadorRespuestas = 1;
                            break;
                        }
                    }
                    if (contadorRespuestas === 0 && seccion === 0) {
                        preguntaFaltante = element2.idquestion;
                        seccion = element.idsection;
                        preguntaFal = pregunta;
                        break;
                    }
                }
            }
            for (let index = 0; index < section14.length; index++) {
                const element = section14[index];
                let pregunta = 0;
                for (let index = 0; index < element.questions.length; index++) {
                    pregunta = pregunta + 1;
                    const element2 = element.questions[index];
                    let contadorRespuestas = 0;
                    for (let index = 0; index < element2.answers.length; index++) {
                        const element3 = element2.answers[index];
                        if (element3.check === 1) {
                            total = total + 1;
                            contadorRespuestas = 1;
                            break;
                        }
                    }
                    if (contadorRespuestas === 0 && seccion === 0) {
                        preguntaFaltante = element2.idquestion;
                        seccion = element.idsection;
                        preguntaFal = pregunta;
                        break;
                    }
                }
            }
            if (preguntaFaltante > 0) {
                if (seccion === 13) {
                    handleScroll(`${seccion}-0-${preguntaFal - 1}`);
                } else if (seccion === 14) {
                    handleScroll(`${seccion}-0-${preguntaFal - 1}`);
                } else {
                    handleScroll(`${seccion - 1}-${preguntaFal - 1}`);
                }
            }
            if (total === 72) {
                updFinishSurveyEL(collaboratorId, idEnterprise, surveyId, idGroup, "").then((response) => {
                    if (response) {
                        switch (response?.code) {
                            case 1:
                                if(language === 'es'){
                                    toastr.success("Se registró correctamente tus respuestas.", "¡Éxito!");
                                }else{
                                    toastr.success("Your responses have been successfully submitted.", "Success!");
                                }
                                window.location.replace(response.url);
                                break;
                            case 2:
                                if(language === 'es'){
                                    toastr.success("La encuesta ya fue contestada.", "¡Éxito!");
                                }else{
                                    toastr.success("The survey has already been completed.", "Success!");
                                }
                                //mandar mensaje de agradecimiento
                                setShowMessage(true);
                                break;
                            case 5:
                                window.location.replace(response.url);
                                break;
                            default:
                                if(language === 'es'){
                                    toastr.error("No se pudo finalizar la encuesta, no te preocupes las respuestas están guardas, recarga tu página");
                                }else{
                                    toastr.error("The survey could not be completed, but don't worry, your responses are saved. Please reload the page.");
                                }
                                break;
                        }
                    } else {
                        if(language === 'es'){
                            toastr.error("No se registró correctamente tus respuestas.", "¡Ooops!");
                        }else{
                            toastr.error("Your responses were not successfully submitted.", "¡Ooops!");
                        }
                    }
                });
            } else {
                if(language === 'es'){
                    toastr.info("Aún hay preguntas sin responder");
                }else{
                    toastr.info("There are still unanswered questions.");
                }
            }
        }
    }

    const handleClickUp = () => {
        // element which needs to be scrolled to
        const element = document.getElementById("start");
        // scroll to element
        element.scrollIntoView({ behavior: "smooth", inline: "center", block: "center" });
    }

    const showMessageBtn = () => {
        setBtnState(!btnState)
    }

    if (loading) {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-5">
                <LoaderSurvey language={language}/>
            </div>
        )
    } else if (showMessage) {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-2">
                <SurveyComplete language={language} />
            </div>
        )
    } else if (errorPage) {
        return (
            <div className="container-fluid">
                <SurveyError language={language}/>
            </div>
        )
    } else {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                {surveyTailCounter === 0 && (
                    <IntroSurvey />
                )}
                {surveyTailCounter === 1 && (
                    <FRP
                        checkboxVal={btnState}
                        checkboxFunc={showMessageBtn}
                        enterpriseLogo={enterpriseData.logo}
                        language={language}
                        setLanguage={setLanguage}
                        collabName={collabName}
                    />
                )}
                {surveyTailCounter === 2 && (
                    <FRPDEMO
                        checkboxVal={btnState}
                        checkboxFunc={showMessageBtn}
                        enterpriseLogo={enterpriseData.logo}
                        language={language}
                        setLanguage={setLanguage}
                        collabName={collabName}
                    />
                )}
                <div className="row">
                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center">
                        <LanguageInput setLanguage={setLanguage} language={language}/>
                    </div>
                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center" id="start">
                        <img src="../img/logo_guay_color.png" alt="logo-guay" style={{ width: '100px' }} />
                    </div>
                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center mt-1 font-large-1">
                        <span className="text-base-guay font-weight-bold">{language === 'es' ? 'Entorno Laboral' : 'Work Environment'}</span>
                    </div>
                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-3">
                        <span>{language === 'es' ? `¡Hola` : `Hi`} <span className="font-weight-bold">{collabName}</span>!</span>
                    </div>
                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-3">
                        <span>{language === 'es' ? 'Ayúdanos completando el 100% de las respuestas' : 'Help us by completing 100% of the responses.'}</span>
                    </div>
                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 mt-1">
                        {
                            section1to12.map((data, indiceSection) => (
                                <div key={indiceSection} className="row">
                                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto font-medium-3">
                                        <span className="text-base font-weight-bold">{data.description}</span>
                                    </div>
                                    {data.questions.map((question, indiceQuestion) => (
                                        <div key={indiceQuestion} className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-1" id={`${indiceSection}-${indiceQuestion}`}>
                                            <div className="card shadow-card-1">
                                                <div className="card-header bg-blue-guay-2 py-1">
                                                    <span>{question.description}</span>
                                                </div>
                                                <div className="card-body">
                                                    <RadioGroup>
                                                        {question.answers.map((answer, indiceAnswer) => (
                                                            <div key={indiceAnswer} className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                <FormControlLabel value={answer.idanswer} onChange={(e) => handleChange(e, indiceAnswer, indiceQuestion, indiceSection)}
                                                                    control={
                                                                        <Radio icon={<i className="ft ft-circle text-base align-middle" />}
                                                                            checkedIcon={<i className="ft ft-check-circle text-base-guay align-middle" />}
                                                                            checked={answer.check === 1 ? true : false}
                                                                            size="small" />
                                                                    }
                                                                    label={answer.description} />
                                                            </div>
                                                        ))}
                                                    </RadioGroup>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))
                        }
                        <div className="row">
                            <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto font-medium-3">
                                <span className="text-base font-weight-bold">{language === 'es' ? 'Las siguientes preguntas están relacionadas con la atención a clientes y usuarios.' : 'The following questions are related to customer and user service.'}</span>
                            </div>
                            <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-1" id="QuestionToSection13">
                                <div className="card shadow-card-1">
                                    <div className="card-header bg-blue-guay-2">
                                        <span>{language === 'es' ? 'En mi trabajo debo brindar servicio a clientes o usuarios' : 'In my job, I must provide service to customers or users.'}</span>
                                    </div>
                                    <div className="card-body">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                            <RadioGroup value={showSec13.value} onChange={handleChangeSection13}>
                                                <FormControlLabel value="No" control={<Radio icon={<i className="ft ft-circle align-middle" />}
                                                    checkedIcon={<i className="ft ft-check-circle text-base-guay align-middle" />} />} label="No" />
                                                <FormControlLabel value="Si" control={<Radio icon={<i className="ft ft-circle align-middle" />}
                                                    checkedIcon={<i className="ft ft-check-circle text-base-guay align-middle" />} />} label={language == 'es' ? "Si" : "Yes"} />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showSec13.showSec ?
                            section13.map((data, indiceSection) => (
                                <div key={indiceSection} className="row">
                                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto font-medium-3">
                                        <span className="text-base font-weight-bold">{data.description}</span>
                                    </div>
                                    {data.questions.map((question, indiceQuestion) => (
                                        <div key={indiceQuestion} className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-1" id={`13-${indiceSection}-${indiceQuestion}`}>
                                            <div className="card shadow-card-1">
                                                <div className="card-header bg-blue-guay-2">
                                                    <span>{question.description}</span>
                                                </div>
                                                <div className="card-body">
                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                        <RadioGroup>
                                                            {question.answers.map((answer, indiceAnswer) => (
                                                                <FormControlLabel key={indiceAnswer} value={answer.idanswer} onChange={(e) => handleChangeSec13(e, indiceAnswer, indiceQuestion, indiceSection)}
                                                                    control={<Radio icon={<i className="ft ft-circle align-middle" />}
                                                                        checkedIcon={<i className="ft ft-check-circle text-base-guay align-middle" />} checked={answer.check === 1 ? true : false} />}
                                                                    label={answer.description} />
                                                            ))}
                                                        </RadioGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))
                            :
                            null
                        }
                        <div className="row">
                            <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto font-medium-3">
                                <span className="text-base font-weight-bold">{language === 'es' ? 'Las siguientes preguntas están relacionadas con las actitudes de los trabajadores que supervisa.' : 'The following questions are related to the attitudes of the workers you supervise.'}</span>
                            </div>
                            <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-1" id="QuestionToSection14">
                                <div className="card shadow-card-1">
                                    <div className="card-header bg-blue-guay-2">
                                        <span>{language === 'es' ? 'Soy jefe de otros trabajadores' : 'I am the supervisor of other workers.'}</span>
                                    </div>
                                    <div className="card-body">
                                        <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                            <RadioGroup value={showSec14.value} onChange={handleChangeSection14}>
                                                <FormControlLabel value="No" control={<Radio icon={<i className="ft ft-circle align-middle" />}
                                                    checkedIcon={<i className="ft ft-check-circle text-base-guay align-middle" />} />} label="No" />
                                                <FormControlLabel value="Si" control={<Radio icon={<i className="ft ft-circle align-middle" />}
                                                    checkedIcon={<i className="ft ft-check-circle text-base-guay align-middle" />} />} label={language === 'es' ? "Si" : "Yes"} />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showSec14.showSec ?
                            section14.map((data, indiceSection) => (
                                <div key={indiceSection} className="row">
                                    <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto font-medium-3">
                                        <span className="text-base font-weight-bold">{data.description}</span>
                                    </div>
                                    {data.questions.map((question, indiceQuestion) => (
                                        <div key={indiceQuestion} className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-1" id={`14-${indiceSection}-${indiceQuestion}`}>
                                            <div className="card shadow-card-1">
                                                <div className="card-header bg-blue-guay-2">
                                                    <span>{question.description}</span>
                                                </div>
                                                <div className="card-body">
                                                    <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                        <RadioGroup>
                                                            {question.answers.map((answer, indiceAnswer) => (
                                                                <FormControlLabel key={indiceAnswer} value={answer.idanswer} onChange={(e) => handleChangeSec14(e, indiceAnswer, indiceQuestion, indiceSection)}
                                                                    control={<Radio icon={<i className="ft ft-circle align-middle" />}
                                                                        checkedIcon={<i className="ft ft-check-circle text-base-guay align-middle" />} checked={answer.check === 1 ? true : false} />}
                                                                    label={answer.description} />
                                                            ))}
                                                        </RadioGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))
                            :
                            null
                        }
                        <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto mt-1 font-medium-3 d-flex justify-content-center">
                            <button onClick={handleClickFinish} className="btn btn-small btn-blue-3">{linkedsurvey ? language === 'es' ? 'Siguiente' : 'Next' : language === 'es' ? 'Finalizar' : 'Finish'}</button>
                        </div>
                    </div>
                    <button className="arrow-up" onClick={handleClickUp}><i className="ft-arrow-up icon-medium-size" /></button>
                </div>
            </div>
        )

    }

}

export default EntornoLaboral
