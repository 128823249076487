import React, { useState, useEffect } from 'react'
// TODO: Components
import LanguageInput from '../LanguageSwitch';
// TODO: Generic Functions
import { textATSFRP } from './TextCatalog';

const ATSFRP = ({
    checkboxVal,
    checkboxFunc,
    enterpriseLogo,
    language,
    setLanguage,
    collabName
}) => {
    // TODO: state
    const [translatedText, setTranslatedText] = useState({});
    // TODO: useEffect
    useEffect(() => {
        const translationText = textATSFRP(language, checkboxFunc, checkboxVal, collabName);
        setTranslatedText(translationText);
    }, [language, checkboxFunc, checkboxVal]);
    return (
        <div
            className="modal"
            id="modal-1"
            style={{ backgroundColor: 'rgba(255,255,255,0.9)' }}
            data-backdrop="false"
        >
            <div className="modal-dialog modal-dialog-centered modal-xl">
                <div
                    className="modal-content col-sx-10 mx-auto"
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                    }}
                >
                    <div className="modal-body modal-div-bodered container-style-1 pb-5">
                        <div className="col-12 d-flex justify-content-end">
                            <img
                                src={enterpriseLogo}
                                className="w-25"
                                alt='logo'
                            />
                        </div>
                        <div className="col-12 d-flex justify-content-center">
                            <img
                                src={language === "es" ? "../img/Mensaje-encuesta_1.png" : "../img/Ingles_Introduction.png"}
                                className="w-60"
                                alt='logo'
                            />
                        </div>
                        <LanguageInput
                            language={language}
                            setLanguage={setLanguage}
                        />
                        {translatedText?.section1}
                        {translatedText?.section2}
                        {translatedText?.section3}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ATSFRP