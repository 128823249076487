import React, { useState, useEffect, useMemo } from 'react';
import { getJwt } from '../../../../lib/auth';
import { getCollabsToCanalization, setCollabsToCanalization, getResumeCanalization, setFinishCanalization, setCancelExerciseToCanalization } from '../../Models/TrackingModel';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import swal from 'sweetalert';
import $ from 'jquery';
import * as toastr from 'toastr';
import 'toastr/build/toastr.css';
import { setStringNumberFormatter } from '../../../helpers/helpers';
import FilterColumns from './FilterColumns';
import { getActiveProductsByEnterprise } from '../../Models/Enterprise_model';
import { ModalCanalizedTable } from './ModalCanalizedTable';
import  ModalChangeEmail  from './ModalChangeEmail';

export const NextSteps = ({ step_1 }) => {

    const enterpriseId = getJwt('enterprise');
    const [services, setServices] = useState([]);
    const [serviceValue, setServiceValue] = useState('-1');
    const [exercises, setExercises] = useState([]);
    const [machoteExercises, setMachoteExercises] = useState([]);
    const [exerciseValue, setExerciseValue] = useState('-1');
    const [collabsLists, setCollabsLists] = useState({
        collabPrivateList: [],
        collabPublicList: [],
        collabguayList: [],
        collabs: [],
        collabsToCanalize: [],
        collabMDOList: []
    });

    const { collabPrivateList, collabPublicList, collabguayList, collabs, collabsToCanalize, collabMDOList } = collabsLists;
    const [catalogs, setCatalogs] = useState({
        catalogBranches: [],
        catalogAreas: [],
        catalogBoss: [],
        catalogCategories: [],
        catalogJobs: [],
        catalogTraumaticEvents: [],
        catalogFilters: {
            idBranch: false,
            areaEntId: false,
            bossEntId: true,
            jobEntId: true,
            email: false,
            categoryEntId: false,
            customA: true,
            customB: true,
            CustomC: true,
            CustomD: true,
            CustomE: true,
            CustomF: true,
            CustomG: true,
            dateOfAddmision: true,
            keyPlayer: true,
            leader: true,
            afection: true,
        }
    });
    const { catalogBranches, catalogAreas, catalogBoss, catalogCategories, catalogJobs, catalogFilters } = catalogs;
    const [resumeData, setResumeData] = useState({
        canalized: false,
        countPrivate: 0,
        countPublic: 0,
        countguay: 0,
        numCanalized: 0,
        countMDO: 0
    });
    const { canalized, countPrivate, countPublic, countguay, numCanalized, countMDO } = resumeData;
    const [closedCanalization, setClosedCanalization] = useState(true);

    const [modalData, setModalData] = useState({
        typeModal: 0,
        canalizedCollabs: [],
        showModal: false,
        catalogAreas: [],
        catalogBranches: [],
        catalogCategories: []
    });

    const [modalChangeEmailCollab, setModalChangeEmailCollab] = useState({
        typeModal: 0,
        dataCollab: [],
        dataCollabAux:[],
        showModalEmail: false
    });

    const { showModal } = modalData;

    const { showModalEmail } = modalChangeEmailCollab;

    const [setFinish, setSetFinish] = useState(false);
    const [refreshTriggerToCanalizationData, setRefreshTriggerToCanalizationData] = useState(0);

    useEffect(() => {
        getActiveProductsByEnterprise(enterpriseId).then((response) => {
            switch (response?.code) {
                case 1:
                    const getActiveProducts = response.listProducts.filter(product => product.idCategory === 1 && product.idSubcategory === 1 && product.acquired === 1);
                    const getActiveProducts2 = response.listProducts.filter(product => product.idCategory === 6 && product.acquired === 1);
                    setServices([...getActiveProducts, ...getActiveProducts2]);
                    break;
                default:
                    console.error('error');
                    break;
            }
        });

        getCollabsToCanalization(enterpriseId, getJwt('jtw'), getJwt('rol')).then((response) => {
            switch (response?.code) {
                case 1:
                    setMachoteExercises(response.exercises);
                    setExercises(response.exercises);
                    setCatalogs(prevState => {
                        return {
                            ...prevState,
                            catalogBranches: response.catalogBranch,
                            catalogAreas: response.catalogAreas,
                            catalogBoss: response.catalogBoss,
                            catalogCategories: response.catalogCategories,
                            catalogJobs: response.catalogJobs,
                            catalogTraumaticEvents: response.catalogTraumaticEvents
                        }
                    });

                    //Tomar primer ejercicio
                    setServiceValue(sessionStorage.getItem('idc'));
                    const exerciseToLook = response.exercises[0];
                    setExerciseValue(response.exercises[0].idGroup);
                    const { collabPrivateList, collabPublicList, collabguayList, collabMDOList } = exerciseToLook;
                    const privateList = collabPrivateList.filter(collab => collab.active === 99);
                    const publicList = collabPublicList.filter(collab => collab.active === 99);
                    const guayList = collabguayList.filter(collab => collab.active === 99);
                    const mdoList = collabMDOList.filter(collab => collab.active === 99);
                    setCollabsLists(prevState => {
                        return {
                            ...prevState,
                            collabPrivateList: privateList,
                            collabPublicList: publicList,
                            collabguayList: guayList,
                            collabs: exerciseToLook.collabs,
                            collabMDOList: mdoList
                        }
                    });
                    setClosedCanalization(exerciseToLook.closedCanalization);
                    $(".react-bs-container-body").addClass("table-NextSteps");
                    break;
                case 99:
                    toastr.error("Error al traer los ejercicios");
                    break;
                case 400:
                    toastr.error("No se pudo concretar la petición");
                    break;
                case 404:
                    toastr.error("no se encontró método");
                    break;
                case 1001:
                    toastr.error("Empresa no activa");
                    break;
                case 1002:
                    toastr.info("La empresa no tiene colaboradores para canalizar.");
                    break;

                default:
                    toastr.error("Error desconocido");
                    // console.error(response);
                    break;
            }
        });

        $(".react-bs-container-body").addClass("table-NextSteps");

    }, [enterpriseId, setFinish, refreshTriggerToCanalizationData]);

    useEffect(() => {

        $(".react-bs-container-body").addClass("table-NextSteps");

    }, [exerciseValue])

    const handleServiceSelect = function ({ target }) {
        setServiceValue(target.value);
        const exercisesService = machoteExercises.filter(exercise => exercise.idCategory === parseInt(target.value, 10));
        setExercises(exercisesService);
        setExerciseValue('-1');

        setCollabsLists({
            collabPrivateList: [],
            collabPublicList: [],
            collabguayList: [],
            collabs: [],
            collabsToCanalize: [],
            collabMDOList: []
        });
        setClosedCanalization(true);
    }

    const handleChangeSelectExercise = function ({ target }) {
        setExerciseValue(target.value);
        const targetValue = target.value;
        if (targetValue !== '-1') {
            const exerciseToLook = exercises.find(exercise => exercise.idGroup === parseInt(targetValue, 10));
            if (exerciseToLook.isOld === true) {
                swal({
                    text: '¿Detectamos que este es un ejercicio pasado aun así deseas canalizar?',
                    icon: 'warning',
                    buttons: {
                        concluir: {
                            text: "Concluir ejercicio",
                            value: "concluir",
                        },
                        canalizar: {
                            text: "Canalizar",
                            value: "canalizar",
                        },
                    },
                    dangerMode: true,
                }).then((value) => {
                    switch (value) {
                        case "canalizar":
                            const { collabPrivateList, collabPublicList, collabguayList, collabMDOList } = exerciseToLook;
                            const privateList = collabPrivateList.filter(collab => collab.active === 99);
                            const publicList = collabPublicList.filter(collab => collab.active === 99);
                            const guayList = collabguayList.filter(collab => collab.active === 99);
                            const mdoList = collabMDOList.filter(collab => collab.active === 99);
                            setCollabsLists({
                                collabPrivateList: privateList,
                                collabPublicList: publicList,
                                collabguayList: guayList,
                                collabs: exerciseToLook.collabs,
                                collabsToCanalize: [],
                                collabMDOList: mdoList
                            });
                            setClosedCanalization(exerciseToLook.closedCanalization);
                            break;
                        case "concluir":
                            setCancelExerciseToCanalization(enterpriseId, targetValue).then((response) => {
                                switch (response?.code) {
                                    case 1:
                                        setExerciseValue('-1');
                                        setClosedCanalization(true);
                                        setCollabsLists({
                                            collabPrivateList: [],
                                            collabPublicList: [],
                                            collabguayList: [],
                                            collabs: [],
                                            collabsToCanalize: [],
                                            collabMDOList: []
                                        });
                                        const newExercises = exercises.filter(exercise => exercise.idGroup !== parseInt(targetValue, 10));
                                        setExercises([...newExercises]);
                                        setMachoteExercises([...newExercises]);
                                        toastr.success('¡Se concluyó el ejercicio correctamente!');
                                        break;

                                    default:
                                        setClosedCanalization(true);
                                        setCollabsLists({
                                            collabPrivateList: [],
                                            collabPublicList: [],
                                            collabguayList: [],
                                            collabs: [],
                                            collabsToCanalize: [],
                                            collabMDOList: []
                                        });
                                        toastr.error('¡Ocurrio un error desconocido');
                                        break;
                                }
                            });
                            break;

                        default:
                            break;
                    }
                });
                $(".swal-icon").addClass("my-5");
                $(".swal-modal").addClass("bg-gray-light");
                $(".swal-text").addClass("font-medium-1 text-center text-blue-gray font-weight-bold mt-0 mb-3");
                $(".swal-footer").addClass("d-flex justify-content-around");
                $(".swal-button--concluir").addClass("btn-blue-3");
                $(".swal-button--canalizar").addClass("btn-pink");
            } else {
                const { collabPrivateList, collabPublicList, collabguayList, collabMDOList } = exerciseToLook;
                const privateList = collabPrivateList.filter(collab => collab.active === 99);
                const publicList = collabPublicList.filter(collab => collab.active === 99);
                const guayList = collabguayList.filter(collab => collab.active === 99);
                const mdoList = collabMDOList.filter(collab => collab.active === 99);
                setCollabsLists({
                    collabPrivateList: privateList,
                    collabPublicList: publicList,
                    collabguayList: guayList,
                    collabs: exerciseToLook.collabs,
                    collabsToCanalize: [],
                    collabMDOList: mdoList
                });
                setClosedCanalization(exerciseToLook.closedCanalization);
            }
        } else {
            setClosedCanalization(true);
            setCollabsLists({
                collabPrivateList: [],
                collabPublicList: [],
                collabguayList: [],
                collabs: [],
                collabsToCanalize: [],
                collabMDOList: []
            });
        }
    };

    const handleCheckboxChange = function ({ target }) {
        let boolean;
        if (target.checked) {
            boolean = false;
        } else {
            boolean = true;
        }
        setCatalogs(prevState => {
            return {
                ...prevState,
                catalogFilters: {
                    ...prevState.catalogFilters,
                    [target.name]: boolean
                }
            }
        });
    }

    const handleSelectCanalizationChange = function ({ target }) {
        const optionSelected = target.value;
        if (optionSelected === '2') {
            swal({
                title: '¿Deseas recomendar algún lugar en particular?',
                text: 'En caso contrario deja vacío el siguiente campo:',
                input: "text",
                content: "input",
                button: 'Guardar y continuar',
            }).then((value) => {
                if (value !== null) {
                    value = value.trim();
                    if (value) {
                        if (optionSelected !== '-1') {
                            let newCollabs = [];
                            setCollabsToCanalization(enterpriseId, JSON.stringify(collabsToCanalize), optionSelected, value).then((response) => {
                                switch (response.code) {
                                    case 1:
                                        switch (optionSelected) {
                                            case '1': //guay
                                                newCollabs = collabs.filter((item) => {
                                                    return !collabsToCanalize.includes(item);
                                                });
                                                setCollabsLists(prevState => {
                                                    return {
                                                        ...prevState,
                                                        collabs: newCollabs,
                                                        collabguayList: [...collabguayList, ...collabsToCanalize],
                                                        collabsToCanalize: []
                                                    }
                                                });
                                                break;
                                            case '2': //privada
                                                newCollabs = collabs.filter((item) => {
                                                    return !collabsToCanalize.includes(item);
                                                });
                                                setCollabsLists(prevState => {
                                                    return {
                                                        ...prevState,
                                                        collabs: newCollabs,
                                                        collabPrivateList: [...collabPrivateList, ...collabsToCanalize],
                                                        collabsToCanalize: []
                                                    }
                                                });
                                                break;
                                            case '3': //publica
                                                newCollabs = collabs.filter((item) => {
                                                    return !collabsToCanalize.includes(item);
                                                });
                                                setCollabsLists(prevState => {
                                                    return {
                                                        ...prevState,
                                                        collabs: newCollabs,
                                                        collabPublicList: [...collabPublicList, ...collabsToCanalize],
                                                        collabsToCanalize: []
                                                    }
                                                });
                                                break;

                                            case '4': //teleasistencia
                                                newCollabs = collabs.filter((item) => {
                                                    return !collabsToCanalize.includes(item);
                                                });
                                                setCollabsLists(prevState => {
                                                    return {
                                                        ...prevState,
                                                        collabs: newCollabs,
                                                        collabMDOList: [...collabMDOList, ...collabsToCanalize],
                                                        collabsToCanalize: []
                                                    }
                                                });
                                                break;


                                            default:
                                                console.error('error')
                                                break;
                                        }
                                        toastr.success("Colaboradores canalizados");
                                        break;
                                    case 99:
                                        toastr.error("Los colaboradores seleccionados no se guardaron");
                                        break;
                                    case 404:
                                        toastr.error("No se encontraron los colaboradores que se requieren canalizar");
                                        break;
                                    case 1001:
                                        toastr.error("La empresa no existe o se encuentra desactivada");
                                        break;
                                    default:
                                        toastr.error("Error desconocido");
                                        // console.error(response);
                                        break;
                                }
                            })
                        }
                    } else {
                        if (optionSelected !== '-1') {
                            let newCollabs = [];
                            setCollabsToCanalization(enterpriseId, JSON.stringify(collabsToCanalize), optionSelected).then((response) => {
                                switch (response.code) {
                                    case 1:
                                        switch (optionSelected) {
                                            case '1': //guay
                                                newCollabs = collabs.filter((item) => {
                                                    return !collabsToCanalize.includes(item);
                                                });
                                                setCollabsLists(prevState => {
                                                    return {
                                                        ...prevState,
                                                        collabs: newCollabs,
                                                        collabguayList: [...collabguayList, ...collabsToCanalize],
                                                        collabsToCanalize: []
                                                    }
                                                });
                                                break;
                                            case '2': //privada
                                                newCollabs = collabs.filter((item) => {
                                                    return !collabsToCanalize.includes(item);
                                                });
                                                setCollabsLists(prevState => {
                                                    return {
                                                        ...prevState,
                                                        collabs: newCollabs,
                                                        collabPrivateList: [...collabPrivateList, ...collabsToCanalize],
                                                        collabsToCanalize: []
                                                    }
                                                });
                                                break;
                                            case '3': //publica
                                                newCollabs = collabs.filter((item) => {
                                                    return !collabsToCanalize.includes(item);
                                                });
                                                setCollabsLists(prevState => {
                                                    return {
                                                        ...prevState,
                                                        collabs: newCollabs,
                                                        collabPublicList: [...collabPublicList, ...collabsToCanalize],
                                                        collabsToCanalize: []
                                                    }
                                                });
                                                break;

                                            case '4': //teleasistencia
                                                newCollabs = collabs.filter((item) => {
                                                    return !collabsToCanalize.includes(item);
                                                });
                                                setCollabsLists(prevState => {
                                                    return {
                                                        ...prevState,
                                                        collabs: newCollabs,
                                                        collabMDOList: [...collabMDOList, ...collabsToCanalize],
                                                        collabsToCanalize: []
                                                    }
                                                });
                                                break;

                                            default:
                                                console.log('error')
                                                break;
                                        }
                                        toastr.success("Colaboradores canalizados");
                                        break;
                                    case 99:
                                        toastr.error("Los colaboradores seleccionados no se guardaron");
                                        break;
                                    case 404:
                                        toastr.error("No se encontraron los colaboradores que se requieren canalizar");
                                        break;
                                    case 1001:
                                        toastr.error("La empresa no existe o se encuentra desactivada");
                                        break;
                                    default:
                                        toastr.error("Error desconocido");
                                        // console.error(response);
                                        break;
                                }
                            })
                        }
                    }
                }
            });
            $(".swal-modal").addClass("bg-gray-light");
            $(".swal-title").addClass("font-medium-1 text-blue-gray font-weight-bold mt-4 mb-0");
            $(".swal-text").addClass("font-medium-1 text-blue-gray font-weight-bold mb-3 mt-0");
            $(".swal-button-container").addClass("d-flex justify-content-center mt-2 mb-1");
            $(".swal-button").addClass("btn-pink");
        } else if (optionSelected === '4') {
            const v = collabsToCanalize.find(collabs => collabs.customA === '' || collabs.customA === '2');
            if (optionSelected === '4' && v === undefined) {
                if (optionSelected !== '-1') {
                    let newCollabs = [];
                    setCollabsToCanalization(enterpriseId, JSON.stringify(collabsToCanalize), optionSelected).then((response) => {
                        switch (response.code) {
                            case 1:
                                switch (optionSelected) {
                                    case '1': //guay
                                        newCollabs = collabs.filter((item) => {
                                            return !collabsToCanalize.includes(item);
                                        });
                                        setCollabsLists(prevState => {
                                            return {
                                                ...prevState,
                                                collabs: newCollabs,
                                                collabguayList: [...collabguayList, ...collabsToCanalize],
                                                collabsToCanalize: []
                                            }
                                        });
                                        break;
                                    case '2': //privada
                                        newCollabs = collabs.filter((item) => {
                                            return !collabsToCanalize.includes(item);
                                        });
                                        setCollabsLists(prevState => {
                                            return {
                                                ...prevState,
                                                collabs: newCollabs,
                                                collabPrivateList: [...collabPrivateList, ...collabsToCanalize],
                                                collabsToCanalize: []
                                            }
                                        });
                                        break;
                                    case '3': //publica
                                        newCollabs = collabs.filter((item) => {
                                            return !collabsToCanalize.includes(item);
                                        });
                                        setCollabsLists(prevState => {
                                            return {
                                                ...prevState,
                                                collabs: newCollabs,
                                                collabPublicList: [...collabPublicList, ...collabsToCanalize],
                                                collabsToCanalize: []
                                            }
                                        });
                                        break;

                                    case '4': //teleasistencia
                                        newCollabs = collabs.filter((item) => {
                                            return !collabsToCanalize.includes(item);
                                        });
                                        setCollabsLists(prevState => {
                                            return {
                                                ...prevState,
                                                collabs: newCollabs,
                                                collabMDOList: [...collabMDOList, ...collabsToCanalize],
                                                collabsToCanalize: []
                                            }
                                        });
                                        break;

                                    default:
                                        console.log('error')
                                        break;
                                }
                                toastr.success("Colaboradores canalizados");
                                break;
                            case 99:
                                toastr.error("Los colaboradores seleccionados no se guardaron");
                                break;
                            case 404:
                                toastr.error("No se encontraron los colaboradores que se requieren canalizar");
                                break;
                            case 1001:
                                toastr.error("La empresa no existe o se encuentra desactivada");
                                break;
                            default:
                                toastr.error("Error desconocido");
                                // console.error(response);
                                break;
                        }
                    });
                }
            } else {
                toastr.warning('Se selecciono al menos 1 colaborador que no tiene acceso a teleasistencia');
            }
        } else {
            if (optionSelected !== '-1') {
                let newCollabs = [];
                setCollabsToCanalization(enterpriseId, JSON.stringify(collabsToCanalize), optionSelected).then((response) => {
                    switch (response.code) {
                        case 1:
                            switch (optionSelected) {
                                case '1': //guay
                                    newCollabs = collabs.filter((item) => {
                                        return !collabsToCanalize.includes(item);
                                    });
                                    setCollabsLists(prevState => {
                                        return {
                                            ...prevState,
                                            collabs: newCollabs,
                                            collabguayList: [...collabguayList, ...collabsToCanalize],
                                            collabsToCanalize: []
                                        }
                                    });
                                    break;
                                case '2': //privada
                                    newCollabs = collabs.filter((item) => {
                                        return !collabsToCanalize.includes(item);
                                    });
                                    setCollabsLists(prevState => {
                                        return {
                                            ...prevState,
                                            collabs: newCollabs,
                                            collabPrivateList: [...collabPrivateList, ...collabsToCanalize],
                                            collabsToCanalize: []
                                        }
                                    });
                                    break;
                                case '3': //publica
                                    newCollabs = collabs.filter((item) => {
                                        return !collabsToCanalize.includes(item);
                                    });
                                    setCollabsLists(prevState => {
                                        return {
                                            ...prevState,
                                            collabs: newCollabs,
                                            collabPublicList: [...collabPublicList, ...collabsToCanalize],
                                            collabsToCanalize: []
                                        }
                                    });
                                    break;

                                case '4': //teleasistencia
                                    newCollabs = collabs.filter((item) => {
                                        return !collabsToCanalize.includes(item);
                                    });
                                    setCollabsLists(prevState => {
                                        return {
                                            ...prevState,
                                            collabs: newCollabs,
                                            collabMDOList: [...collabMDOList, ...collabsToCanalize],
                                            collabsToCanalize: []
                                        }
                                    });
                                    break;

                                default:
                                    console.log('error')
                                    break;
                            }
                            toastr.success("Colaboradores canalizados");
                            break;
                        case 99:
                            toastr.error("Los colaboradores seleccionados no se guardaron");
                            break;
                        case 404:
                            toastr.error("No se encontraron los colaboradores que se requieren canalizar");
                            break;
                        case 1001:
                            toastr.error("La empresa no existe o se encuentra desactivada");
                            break;
                        default:
                            toastr.error("Error desconocido");
                            // console.error(response);
                            break;
                    }
                });
            }
        }
    }

    const handleClickResumeCanalization = function () {
        $('#canalizationResume').show();
        getResumeCanalization(enterpriseId, exerciseValue).then((response) => {
            switch (response?.code) {
                case 1:
                    const { canalized, countPrivate, countPublic, countguay, numCanalized, countMDO } = response;
                    setResumeData({
                        canalized,
                        countPrivate,
                        countPublic,
                        countguay,
                        numCanalized,
                        countMDO
                    });
                    break;

                default:
                    console.error('Error no manejado');
                    break;
            }
        });
    }

    const handleClickFinishCanalization = function () {

        $('#canalizationResume').hide();
        setFinishCanalization(enterpriseId, exerciseValue).then((response) => {
            switch (response?.code) {
                case 1:
                    toastr.success('Se guardo la  canalización correctamente');
                    setSetFinish(!setFinish);
                    if (collabs.length <= 0) {
                        setClosedCanalization(true);
                        setCollabsLists({
                            collabPrivateList: [],
                            collabPublicList: [],
                            collabguayList: [],
                            collabs: [],
                            collabsToCanalize: [],
                            collabMDOList: []
                        });
                    } else {
                        setCollabsLists({
                            ...collabsLists,
                            collabPrivateList: [],
                            collabPublicList: [],
                            collabguayList: [],
                            collabsToCanalize: [],
                            collabMDOList: []
                        });
                    }
                    break;

                default:
                    console.error('Error handle');
                    break;
            }
        });
    }

    const setTextMessage = function (number) {
        if (number === 1) {
            return `Existe ${number} colaborador que resulto con afecciones, no aparece en esta lista pues se encuentra ya canalizado y en proceso.`;
        } else {
            return `Existen ${number} colaboradores que resultaron con afecciones, no aparecen en esta lista pues se encuentran ya canalizados y en proceso.`;
        }
    }

    const memoSetNumberPrivate = useMemo(() => setStringNumberFormatter(collabPrivateList.length), [collabPrivateList]);
    const memoSetNumberguay = useMemo(() => setStringNumberFormatter(collabguayList.length), [collabguayList]);
    const memoSetNumberPublic = useMemo(() => setStringNumberFormatter(collabPublicList.length), [collabPublicList]);
    const memoSetTextMessage = useMemo(() => setTextMessage(numCanalized), [numCanalized]);
    const memoSetNumberTelemetry = useMemo(() => setStringNumberFormatter(collabMDOList.length), [collabMDOList]);

    /**
     * Table styles
     */

    const options = {
        noDataText: 'No se encontraron colaboradores',
        sortIndicator: true,
    };

    /**
     * Function that set all data in dataCollab to show it in the modal
     * @param {*} row 
     */
    const setDataInModal = (data) => {
        try {
            console.log('data: ',data);
            setModalChangeEmailCollab({
                typeModal: 0,
                dataCollab: data,
                dataCollabAux:data,
                showModalEmail: true
            });
            
        } catch (error) {
            console.log(error);
        }
    }

    /**
   * Function that show an icon in BootstrapTable
   * @param {*} row 
   * @returns 
   */
    const setEditIcon = (cell, row, enumObject, rowIndex) => {
        return (
            <div>
                <i
                // onClick={() => setDataInModal(data)}
                onClick={(e) => {
                    e.stopPropagation(); // Evita que el clic afecte al selectRow
                    setDataInModal(row); // Llama a la función setEdition con la fila actual
                }}
                className='ft ft-edit-2 icon-medium-size text-blue-sec pointer'
                data-toggle="tooltip"
                data-placement="top"
                title="Editar correo"
                />
            </div>
        )
    }

    const branchFormater = function (cell, row) {
        const branch = catalogBranches.find(branch => branch.branchEntId === cell);
        let branchName = 'Sin centro';
        if (branch !== undefined) {
            branchName = branch.description;
        }
        return `${branchName}`;
    }

    const areaFormater = function (cell, row) {
        const area = catalogAreas.find(area => area.areaEntId === cell);
        let areaName = 'Sin área';
        if (area !== undefined) {
            areaName = area.description;
        }
        return `${areaName}`;
    }

    const bossFormater = function (cell, row) {
        const boss = catalogBoss.find(boss => boss.bossEntId === cell);
        let bossName = 'Sin jefe directo';
        if (boss !== undefined) {
            bossName = boss.description;
        }
        return `${bossName}`;
    }

    const categoriesFormater = function (cell, row) {
        const category = catalogCategories.find(boss => boss.categoryEntId === cell);
        let categoryName = '-';
        if (category !== undefined) {
            categoryName = category.description;
        }
        return `${categoryName}`;
    }

    const jobsFormater = function (cell, row) {
        const job = catalogJobs.find(boss => boss.jobEntId === cell);
        let jobName = '-';
        if (job !== undefined) {
            jobName = job.description;
        }
        return `${jobName}`;
    }

    const teleasistenciaFormater = function (cell, row) {
        let teleAsis;
        if (cell === '1') {
            teleAsis = 'Si';
        } else {
            teleAsis = 'No';
        }
        return `${teleAsis}`;
    }
    const afectionFormater = function (cell, row) {
        for (let x in catalogs.catalogTraumaticEvents) {
            if (catalogs.catalogTraumaticEvents[x].idTypeTraumaticEvent === cell) {
                return `${catalogs.catalogTraumaticEvents[x].description}`;
            }
        }
    }
    /**
     * Table functions
     */
    const selectRowProp = {
        mode: 'checkbox',
        hideSelectColumn: false,
        clickToSelect: true,
        onSelect: (row, isSelected) => handleSelectionCollab(row, isSelected),
        // onSelect: (row, isSelected, e) => {
        //     console.log('row.email: ',row.email);
            
        //     if (!row.email) {
        //         e.preventDefault(); // Evita que se seleccione la fila
        //         alert('No se puede seleccionar este colaborador porque no tiene correo electrónico.');
        //     }else{
        //         handleSelectionCollab(row, isSelected);
        //     }
            
        // },
        onSelectAll: (selectedAll, rows) => handleSelectionAll(selectedAll, rows)
    };

    const handleSelectionCollab = function (row, isSelected) {
        if (isSelected === true) { // se selecciono
            setCollabsLists(prevState => {
                return {
                    ...prevState,
                    collabsToCanalize: [...prevState.collabsToCanalize, row]
                }
            });
        } else { // se deselecciono
            const arrayCollabs = collabsToCanalize.filter(collab => collab.idCollab !== row.idCollab);
            setCollabsLists(prevState => {
                return {
                    ...prevState,
                    collabsToCanalize: arrayCollabs
                }
            });
        }
    }

    const handleSelectionAll = function (selectedAll, rows) {
        if (selectedAll === true) {
            setCollabsLists(prevState => {
                return {
                    ...prevState,
                    collabsToCanalize: rows
                }
            });
        } else {
            setCollabsLists(prevState => {
                return {
                    ...prevState,
                    collabsToCanalize: []
                }
            });
        }
    }

    return (
        <div className="row">
            <div className="col-12 mx-auto">
                <div className="col-12 mx-auto">
                    <div className="row d-flex justify-content-around">
                        <div className="col-6" hidden>
                            <div className="d-flex align-items-center">
                                <h5 className="font-weight-bold text-base-light-gray m-0 p-0">Servicio:</h5>
                                <select className="form-control form-control-sm ml-1 bg-gray-white" value={serviceValue} onChange={handleServiceSelect}>
                                    <option value="-1" className="select-option">Servicio</option>
                                    {
                                        services.map((service) => {
                                            return <option value={service.idCategory} key={`${service.idCategory}-${service.idSubcategory}`}>{service.nameCategory}</option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="d-flex align-items-center">
                                <h5 className="font-weight-bold text-base-light-gray m-0 p-0">Nombre del ejercicio:</h5>
                                <select className="form-control form-control-sm ml-1 w-50 bg-gray-white" value={exerciseValue} onChange={handleChangeSelectExercise}>
                                    <option value="-1">Nombre del ejercicio</option>
                                    {exercises.map(exe => <option key={exe.idGroup} value={exe.idGroup}>{exe.descLaunch}</option>)}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {closedCanalization ?
                (
                    <>
                        <div className="col-12 mx-auto my-2">
                            <div className="col-12 mx-auto">
                                <div className="row d-flex justify-content-around">
                                    <div className="col-7">
                                        <h4 className="font-weight-bold text-blue-sec">Canaliza</h4>
                                        <h5 className="font-medium-2 text-blue-sec">Elije el tipo de canalización que tendrá cada uno de tus colaboradores:</h5>
                                        <h5 className="font-medium-2 text-blue-sec">Colaboradores sin canalizar</h5>
                                    </div>
                                    <div className="col-5 text-center">
                                        <p className="font-small-5 text-blue-sec">Colaboradores asignados</p>
                                        <div className="d-flex justify-content-center">
                                            <div className="d-flex justify-content-around rounded-pill align-items-center bg-gray-guay overflow-visible w-100">
                                                <div className="shape-circle bg-blue-iam text-white d-flex justify-content-center align-items-center with-box-shadow-hover">
                                                    {memoSetNumberTelemetry}
                                                </div>
                                                <div className="shape-circle bg-yellow-mine text-white d-flex justify-content-center align-items-center with-box-shadow-hover">
                                                    {memoSetNumberPrivate}
                                                </div>
                                                <div className="shape-circle bg-pink-mine text-white d-flex justify-content-center align-items-center with-box-shadow-hover">
                                                    {memoSetNumberguay}
                                                </div>
                                                <div className="shape-circle bg-green-mine text-white d-flex justify-content-center align-items-center with-box-shadow-hover">
                                                    {memoSetNumberPublic}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="d-flex justify-content-around align-items-center mt-1 w-100">
                                                <div className="row w-100">
                                                    <div className="col-3">
                                                        <small className="text-blue-sec">Teleasistencia</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <small className="text-blue-sec">Privado</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <small className="text-blue-sec">guay</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <small className="text-blue-sec">Público</small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mx-auto">
                            <div className="col-12 mx-auto">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="m-5 p-5 text-center">
                                            <i className="font-medium-5 text-base-light-gray">Sin colaboradores pendientes para canalizar</i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
                :
                (
                    <>
                        <div className="col-12 mx-auto my-2">
                            <div className="col-12 mx-auto">
                                <div className="row d-flex justify-content-around">
                                    <div className="col-7">
                                        <h4 className="font-weight-bold text-blue-sec">Canaliza</h4>
                                        <h5 className="font-medium-2 text-blue-sec">Elije el tipo de canalización que tendrá cada uno de tus colaboradores:</h5>
                                        <h5 className="font-medium-2 text-blue-sec">Colaboradores sin canalizar</h5>
                                    </div>
                                    <div className="col-5 text-center">
                                        <p className="font-small-5 text-blue-sec">Colaboradores asignados</p>
                                        <div className="d-flex justify-content-center">
                                            <div className="d-flex justify-content-around rounded-pill align-items-center bg-gray-guay overflow-visible w-100">
                                                <div className="shape-circle bg-blue-iam text-white d-flex justify-content-center align-items-center with-box-shadow-hover" onClick={() => {
                                                    setModalData({
                                                        typeModal: 4,
                                                        canalizedCollabs: collabMDOList,
                                                        showModal: true,
                                                        catalogAreas,
                                                        catalogBranches,
                                                        catalogCategories
                                                    });
                                                }}>
                                                    {memoSetNumberTelemetry}
                                                </div>
                                                <div className="shape-circle bg-yellow-mine text-white d-flex justify-content-center align-items-center with-box-shadow-hover" onClick={() => {
                                                    setModalData({
                                                        typeModal: 2,
                                                        canalizedCollabs: collabPrivateList,
                                                        showModal: true,
                                                        catalogAreas,
                                                        catalogBranches,
                                                        catalogCategories
                                                    });
                                                }}>
                                                    {memoSetNumberPrivate}
                                                </div>
                                                <div className="shape-circle bg-pink-mine text-white d-flex justify-content-center align-items-center with-box-shadow-hover" onClick={() => {
                                                    setModalData({
                                                        typeModal: 1,
                                                        canalizedCollabs: collabguayList,
                                                        showModal: true,
                                                        catalogAreas,
                                                        catalogBranches,
                                                        catalogCategories
                                                    });
                                                }}>
                                                    {memoSetNumberguay}
                                                </div>
                                                <div className="shape-circle bg-green-mine text-white d-flex justify-content-center align-items-center with-box-shadow-hover" onClick={() => {
                                                    setModalData({
                                                        typeModal: 3,
                                                        canalizedCollabs: collabPublicList,
                                                        showModal: true,
                                                        catalogAreas,
                                                        catalogBranches,
                                                        catalogCategories
                                                    });
                                                }}>
                                                    {memoSetNumberPublic}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="d-flex justify-content-around align-items-center mt-1 w-100">
                                                <div className="row w-100">
                                                    <div className="col-3">
                                                        <small className="text-blue-sec">Teleasistencia</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <small className="text-blue-sec">Privado</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <small className="text-blue-sec">guay</small>
                                                    </div>
                                                    <div className="col-3">
                                                        <small className="text-blue-sec">Público</small>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mx-auto">
                            <div className="col-12 mx-auto">
                                <div className="d-flex justify-content-between">
                                    {collabsToCanalize.length >= 1 &&
                                        <select className="form-control form-control-sm w-25 select-pink font-small-5 py-0 rounded-0" value='-1' onChange={handleSelectCanalizationChange}>
                                            <option value="-1" className="select-option">Asignar a</option>
                                            <option value="4" className="select-option">Teleasistencia</option>
                                            <option value="3" className="select-option">Institución publica</option>
                                            <option value="2" className="select-option">Institución privada</option>
                                            <option value="1" className="select-option">guay</option>
                                        </select>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mx-auto">
                            <div className="col-12 mx-auto">
                                <div className="d-flex">
                                    <div className="w-100 mt-1 mr-0 pr-0">
                                        <BootstrapTable
                                            selectRow={selectRowProp}
                                            data={collabs}
                                            trClassName='tr-data-table table-checkbox-2'
                                            tableHeaderClass='table-checkbox-2'
                                            tableStyle={{ border: '0px', padding: '0px', fontSize: '1.1rem', color: '#798a94' }}
                                            containerStyle={{ border: '#9cb0d1 2.5px solid' }}
                                            options={options}
                                        >
                                            <TableHeaderColumn isKey dataField="idCollab" dataAlign="center" hidden>ID</TableHeaderColumn>
                                            <TableHeaderColumn dataField="idCollab" dataAlign="center" dataFormat={setEditIcon} tdStyle={{ fontSize: '1.1rem', padding: '5px' }} width="50px"></TableHeaderColumn>
                                            <TableHeaderColumn dataField="nameCollab" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} hidden={catalogFilters.email} dataSort>Colaborador</TableHeaderColumn>
                                            <TableHeaderColumn dataField="email" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} hidden={catalogFilters.email}  dataSort>Correo</TableHeaderColumn>
                                            <TableHeaderColumn dataField="areaEntId" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} dataFormat={areaFormater} hidden={catalogFilters.areaEntId} dataSort>Área</TableHeaderColumn>
                                            <TableHeaderColumn dataField="categoryEntId" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} dataFormat={categoriesFormater} hidden={catalogFilters.categoryEntId} dataSort>Cargo</TableHeaderColumn>
                                            <TableHeaderColumn dataField="jobEntId" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} dataFormat={jobsFormater} hidden={catalogFilters.jobEntId} dataSort>Puesto</TableHeaderColumn>
                                            <TableHeaderColumn dataField="idBranch" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} dataFormat={branchFormater} hidden={catalogFilters.idBranch} dataSort>Centro de trabajo</TableHeaderColumn>
                                            <TableHeaderColumn dataField="bossEntId" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} dataFormat={bossFormater} hidden={catalogFilters.bossEntId} dataSort>Jefe</TableHeaderColumn>
                                            <TableHeaderColumn dataField="customA" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} dataFormat={teleasistenciaFormater} hidden={catalogFilters.customA} dataSort>Teleasistencia</TableHeaderColumn>
                                            <TableHeaderColumn dataField="customB" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} hidden={catalogFilters.customB} dataSort>Personalizado 1</TableHeaderColumn>
                                            <TableHeaderColumn dataField="customC" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} hidden={catalogFilters.CustomC} dataSort>Personalizado 2</TableHeaderColumn>
                                            <TableHeaderColumn dataField="customD" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} hidden={catalogFilters.CustomD} dataSort>Personalizado 3</TableHeaderColumn>
                                            <TableHeaderColumn dataField="customE" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} hidden={catalogFilters.CustomE} dataSort>Personalizado 4</TableHeaderColumn>
                                            <TableHeaderColumn dataField="customF" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} hidden={catalogFilters.CustomF} dataSort>Personalizado 5</TableHeaderColumn>
                                            <TableHeaderColumn dataField="customG" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} hidden={catalogFilters.CustomG} dataSort>Personalizado 6</TableHeaderColumn>
                                            <TableHeaderColumn dataField="dateOfAdmission" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} hidden={catalogFilters.dateOfAddmision} dataSort>Fecha de Admisión</TableHeaderColumn>
                                            <TableHeaderColumn dataField="keyPlayer" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} hidden={catalogFilters.keyPlayer} dataSort>Key Player</TableHeaderColumn>
                                            <TableHeaderColumn dataField="leader" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} hidden={catalogFilters.leader} dataSort>Líder</TableHeaderColumn>
                                            <TableHeaderColumn dataField="idTypeTraumaticEvent" width="250px" dataAlign="center" tdStyle={{ fontSize: '1.1rem', padding: '5px' }} dataFormat={afectionFormater} hidden={catalogFilters.afection} dataSort>Afección</TableHeaderColumn>
                                        </BootstrapTable>
                                    </div>
                                    <div className="mx-0 px-0 mt-1">
                                        <FilterColumns
                                            handleCheckboxChange={handleCheckboxChange}
                                            catalogFilters={catalogFilters}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mx-auto mt-3 mb-2">
                            <div className="col-12 mx-auto">
                                {
                                    !step_1 && (
                                        <div className="d-flex justify-content-end">
                                            <button className="btn btn-sm font-medium-2 btn-blue-3 px-3" onClick={handleClickResumeCanalization}>Siguiente</button>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        {showModal && <ModalCanalizedTable setModalData={setModalData} modalData={modalData} setCollabsLists={setCollabsLists} step_1={step_1} />}
                        {showModalEmail && <ModalChangeEmail setModalChangeEmailCollab={setModalChangeEmailCollab} modalChangeEmailCollab={modalChangeEmailCollab} setRefreshTriggerToCanalizationData={setRefreshTriggerToCanalizationData}/>}
                    </>
                )
            }

            <div
                className="modal"
                id="canalizationResume"
                style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
                data-backdrop="false"
            >
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div
                        className="modal-content col-12"
                        style={{ backgroundColor: 'transparent', border: 'none' }}
                    >
                        <div className="btn-close-1 d-flex justify-content-center flex-wrap align-content-center">
                            <button
                                type="button"
                                className="close"
                                onClick={() => $('#canalizationResume').hide()}
                            >
                                &times;
                            </button>
                        </div>

                        <div className="modal-body modal-div-style-1 container-style-1" style={{ backgroundColor: '#f4f4f6' }}>
                            <div className="row">
                                <div className="col-12 mb-2">
                                    <h2 className="font-weight-bold text-pink-mine text-center">Resumen</h2>
                                </div>
                                <div className="col-12 mb-1">
                                    <h3 className="font-weight-bold text-blue-gray text-center">Los colaboradores se canalizarán de la siguiente manera:</h3>
                                </div>
                                <div className="col-12 my-2">
                                    <div className="col-12 text-center">
                                        <div className="d-flex justify-content-center">
                                            <div className="d-flex justify-content-around rounded-pill align-items-center bg-white overflow-visible w-100">
                                                <div className="shape-circle  bg-blue-iam text-white d-flex justify-content-center align-items-center with-box-shadow-hover">
                                                    {countMDO}
                                                </div>
                                                <div className="shape-circle bg-yellow-mine text-white d-flex justify-content-center align-items-center with-box-shadow-hover">
                                                    {countPrivate}
                                                </div>
                                                <div className="shape-circle bg-pink-mine text-white d-flex justify-content-center align-items-center with-box-shadow-hover">
                                                    {countguay}
                                                </div>
                                                <div className="shape-circle bg-green-mine text-white d-flex justify-content-center align-items-center with-box-shadow-hover">
                                                    {countPublic}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <div className="d-flex justify-content-around align-items-center mt-1 w-100">
                                                <div className="font-medium-2 text-blue-gray text-center w-100">Teleasistencia</div>
                                                <div className="font-medium-2 text-blue-gray text-center w-100">Externa Privada</div>
                                                <div className="font-medium-2 text-blue-gray text-center w-100">guay</div>
                                                <div className="font-medium-2 text-blue-gray text-center w-100">Externa pública</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mx-auto">
                                    {canalized && <p className="text-green text-center"> {memoSetTextMessage} </p>}
                                </div>
                                <div className="col-10 mx-auto d-flex justify-content-between mt-2">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-blue-3 font-medium-2 px-3"
                                        data-dismiss="modal"
                                        onClick={() => $('#canalizationResume').hide()}
                                    >
                                        Regresar
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-pink font-medium-2 px-3"
                                        onClick={handleClickFinishCanalization}
                                    >
                                        Guardar
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    );
}
