import React, {
    useEffect,
    useState
} from 'react';
import { useParams } from 'react-router-dom';
// TODO: API Calls
import {
    validationAccessFCHTK,
    saveResponseHabits,
    updFinishSurveyHabits,
} from '../../Models/ClinicalFilterHTK_model';
// TODO: Dependencies
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import * as toastr from 'toastr';
import $ from 'jquery';
// TODO: Functions
import {
    handleScroll,
    convertDateToYYYYMMDD,
} from '../../../helpers/helpers';
// TODO: Local Components
import LoaderSurvey from '../../LoaderSurvey';
import ModalLoaderGen from '../../ModalLoaderGen';

const Habits = () => {
    // State
    // Array
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [answerAnswered, setAnswerAnswered] = useState([]);
    const [questionsOpacity, setQuestionsOpacity] = useState([]);
    // Object
    const [generalData, setGeneralData] = useState({});
    // Boolean
    const [loading, setLoading] = useState(true);
    const [inProcess, setInProcess] = useState(false);
    // Number
    const [percentageBar, setPercentageBar] = useState(0);
    const [collabName, setCollabName] = useState('');
    // Obtener parametros de la liga
    const { identity } = useParams();
    useEffect(() => {
        let parser = "0-0-0-0";
        // Desencriptar Base64
        try {
            parser = atob(identity);
        } catch (error) {
            console.error(error);
        }
        parser = parser.split("-");
        // Asignar valores
        const idCollaborator = parser[0];
        const idEnterprise = parser[1];
        // Obtener estructura de evaluación
        validationAccessFCHTK(idCollaborator, idEnterprise).then(response => {
            switch (response.code) {
                case 1:
                    setCollabName(response.collabName);
                    if (
                        (response.statusCompleteFC === 1 && response.statusCompleteHTK === 1) &&
                        (response.statusCompleteHabits === null || response.statusCompleteHabits === -1)
                    ) {
                        const flagResponses = response.responses.length === 0 ? false : true;
                        /**
                         * Función para obtener las respuestas guardadas en BD
                         * @param {*} responsesArray 
                         * @param {*} answersArray 
                         * @returns array
                         */
                        const getAnsweredQuestions = (responsesArray, answersArray) => {
                            // Obtener los id de las respuestas guardadas
                            const getResponsesId = responsesArray.map(responseKey => responseKey.idAnswer);
                            // Filtrar los objetos de answersArray que coincidan con los valores de getResponsesId
                            const answeredQuestions = answersArray.filter(objeto => getResponsesId.includes(objeto.idAnswer));
                            return answeredQuestions;
                        }
                        const answeredQuestions = getAnsweredQuestions(response.responses, response.answers);
                        //console.table(answeredQuestions);
                        /**
                         * Función para obtener la estructura base que controla la habilitación de preguntas
                         * @param {*} questionsArray 
                         * @param {*} flag 
                         * @param {*} answerAnsweredArray 
                         * @returns array
                         */
                        const questionOpacity = (questionsArray, flag, answerAnsweredArray) => {
                            let structureArray = [];
                            // Se crea la estructura base
                            for (let i = 0; i < questionsArray.length; i++) {
                                const element = questionsArray[i];
                                structureArray.push({
                                    idQuestion: element.idQuestion,
                                    isAnswered: false,
                                });
                            }
                            // Se valida si tiene respuestas guardadas
                            if (flag) {
                                let newStructureArray = [];
                                structureArray.forEach(structureKey => {
                                    let flagMatch = false;
                                    answerAnsweredArray.forEach(answerKey => {
                                        if (structureKey.idQuestion === answerKey.idQuestion) {
                                            flagMatch = true;
                                        }
                                    });
                                    if (flagMatch) {
                                        newStructureArray.push({
                                            ...structureKey,
                                            isAnswered: true,
                                        });
                                    } else {
                                        newStructureArray.push(structureKey);
                                    }
                                });
                                return newStructureArray;
                            } else {
                                return structureArray;
                            }
                        }
                        const getQuestionOpacityStructure = questionOpacity(response.questions, flagResponses, answeredQuestions);
                        //console.table(getQuestionOpacityStructure);
                        /**
                         * Función que devuelve el número de la pregunta pendiente
                         * @param {*} questionsArray 
                         * @param {*} answerAnsweredArray 
                         * @returns int
                         */
                        const scrollQuestion = (questionsArray, answerAnsweredArray) => {
                            if (questionsArray.length === answerAnsweredArray.length) {
                                return questionsArray.length
                            }
                            // Filtrar los objetos de questionsArray que no coincidan con answerAnsweredArray
                            const resultados = questionsArray.filter(objeto =>
                                !answerAnsweredArray.some(answer => answer.idQuestion === objeto.idQuestion)
                            );
                            const question = resultados[0].idQuestion;
                            return question;
                        }
                        //console.log(scrollQuestion(response.questions, answeredQuestions));
                        /**
                         * Función para calcular el progreso de la evaluación
                         * @param {*} questionsArray 
                         * @param {*} answerAnsweredArray 
                         * @returns int
                         */
                        const getbarProgress = (questionsArray, answerAnsweredArray) => {
                            const numeroTotalPreguntas = questionsArray.length;
                            const numeroPreguntasContestadas = answerAnsweredArray.length;
                            const porcentajeContestado = (numeroPreguntasContestadas / numeroTotalPreguntas) * 100;
                            return Math.round(porcentajeContestado)
                        }
                        const barprogress = getbarProgress(response.questions, answeredQuestions);
                        // Guarda los datos generales del colaborador en el state
                        setGeneralData({
                            idEnterprise: parseInt(idEnterprise),
                            idCollabSurveyControlFCHTK: response.idCollabSurveyControlFCHTK,
                            idCollaborator: parseInt(idCollaborator),
                            userDate: convertDateToYYYYMMDD(new Date()),
                        });
                        // Guarda las preguntas base en el state
                        setQuestions(response.questions);
                        // Guarda las respuestas base en el state
                        setAnswers(response.answers);
                        // Valida si existen respuestas guardadas
                        if (flagResponses) {
                            // Guarda las respuestas contestadas base en el state
                            setAnswerAnswered(answeredQuestions);
                            // Maneja el scroll para  mostrar la pregunta pendiente
                            setTimeout(() => {
                                handleScroll(`section-${scrollQuestion(response.questions, answeredQuestions)}`)
                            }, 1000);
                        }
                        // Guarda la estructura base que maneja la habilitación de las preguntas en el state
                        setQuestionsOpacity(getQuestionOpacityStructure);
                        // Guarda el porcentaje de avance en el state
                        setPercentageBar(barprogress);
                        setLoading(false);
                    } else if (response.statusCompleteFC === null || response.statusCompleteFC - 1) {
                        //Se redirecciona a FC
                        console.log('se redirecciona a FC');
                        window.location = "/filtro-Clinico/" + identity;
                    } else if ((response.statusCompleteFC === 1) && (response.statusCompleteHTK === null || response.statusCompleteHTK === -1)) {
                        //Se redirecciona a HTK
                        console.log('se redirecciona a HTK');
                        window.location = "/health-tracking/" + identity;
                    }
                    break;
                case 1001:
                    toastr.info("La empresa o el colaborador no están activos. Por favor verificar que ambos casos estén activos.", "¡Ooops!");
                    setLoading(false);
                    break;
                default:
                    toastr.error(`Error: ${response.code}. Intente nuevamente. Si el error persiste contactar al equipo de soporte.`, "¡Ooops!");
                    setLoading(false);
                    break;
            }
        });
    }, [identity]);

    const barOptions = (percentage = 0) => {
        let width = 0;
        let backgroundColor = "#c3c3c3";
        if (percentage > 0) {
            //green
            width = `${percentage}%`;
            backgroundColor = "#9cb537";
        }

        return {
            width,
            backgroundColor
        }
    };
    const handleChange = (answerData) => {
        //console.log(answerData)
        const copyAnswerAnswered = answerAnswered;
        const copyQuestionsOpacity = questionsOpacity;
        const requestObj = {
            ...generalData, // idEnterprise, idCollaborator, idCollabSurveyFCHTK, userDate
            idAnswer: answerData.idAnswer,
            idQuestion: answerData.idQuestion,
            coordinates: '',
            os: '',
            browser: '',
        };
        //console.log(requestObj);
        saveResponseHabits(requestObj).then(response => {
            if (response) {
                if (response.validation) {
                    /**
                     * Función para calcular el progreso de la evaluación
                     * @param {*} questionsArray 
                     * @param {*} answerAnsweredArray 
                     * @returns int
                     */
                    const getbarProgress = (questionsArray, answerAnsweredArray) => {
                        const numeroTotalPreguntas = questionsArray.length;
                        const numeroPreguntasContestadas = answerAnsweredArray.length;
                        const porcentajeContestado = (numeroPreguntasContestadas / numeroTotalPreguntas) * 100;
                        return Math.round(porcentajeContestado)
                    }
                    // Valida si existen preguntas contestadas
                    if (copyAnswerAnswered.length === 0) {
                        let newAnswerAnswered = copyAnswerAnswered;
                        newAnswerAnswered.push(answerData);
                        const barprogress = getbarProgress(questions, newAnswerAnswered);
                        setAnswerAnswered(newAnswerAnswered);
                        checkQuestionsOpacity(copyQuestionsOpacity, answerData.idQuestion);
                        setPercentageBar(barprogress);
                    } else {
                        // Obtiene la respuesta de la pregunta actual si es que existe
                        const getPreviuosAnswer = copyAnswerAnswered.filter(answerKey => answerKey.idQuestion === answerData.idQuestion);
                        // Valida si la respuesta de la pregunta actual existe
                        if (getPreviuosAnswer.length > 0) {
                            const newAnswerAnswered = copyAnswerAnswered.map(answerKey => {
                                if (answerKey.idQuestion === answerData.idQuestion) {
                                    const answerObj = {
                                        ...answerKey,
                                        idAnswer: answerData.idAnswer
                                    };
                                    return answerObj;
                                } else {
                                    return answerKey;
                                }
                            });
                            const barprogress = getbarProgress(questions, newAnswerAnswered);
                            setAnswerAnswered(newAnswerAnswered);
                            checkQuestionsOpacity(copyQuestionsOpacity, answerData.idQuestion);
                            setPercentageBar(barprogress);
                        } else {
                            let newAnswerAnswered = copyAnswerAnswered;
                            newAnswerAnswered.push(answerData);
                            const barprogress = getbarProgress(questions, newAnswerAnswered);
                            setAnswerAnswered(newAnswerAnswered);
                            checkQuestionsOpacity(copyQuestionsOpacity, answerData.idQuestion);
                            setPercentageBar(barprogress);
                        }
                    }
                }
            }
        })
    }
    /**
     * Función para terminar la evaluación
     * @returns 
     */
    const handleClickEndSurvey = () => {
        // Validación que no permite hacer otra petición mientras una está en proceso
        if (inProcess) {
            return;
        }
        // Valida que todas las preguntas estén contestadas
        if (answerAnswered.length < questions.length) {
            toastr.info('Debes contestar todas las preguntas', 'Ooops!');
            return;
        }
        $('#modalLoaderGen').show();
        updFinishSurveyHabits(generalData).then(response => {
            if (response) {
                if (response.validation) {
                    toastr.success('Se guardo correctamente la evaluación.', '¡Éxito!');
                    // Redirecciona a Preguntas Extra
                      window.location = `/diagnosis/${btoa(`${generalData.idCollaborator}-${generalData.idEnterprise}-${generalData.idCollabSurveyControlFCHTK}`)}`;
                } else {
                    $('#modalLoaderGen').hide();
                    setInProcess(false);
                }
            } else {
                $('#modalLoaderGen').hide();
                setInProcess(false);
            }
        });
        
    }
    /**
     * Función para habilitar las preguntas
     * @param {*} questionArray 
     * @param {*} idQuestion 
     */
    function checkQuestionsOpacity(questionArray, idQuestion) {
        const getPreviousQuestion = questionArray.filter(questionKey => questionKey.idQuestion === idQuestion);
        if (getPreviousQuestion[0].isAnswered) {
            if (questionArray.length > idQuestion) {
                handleScroll(`section-${idQuestion + 1}`);
            }
        } else {
            const newQuestionOpacity = questionArray.map(questionKey => {
                if (questionKey.idQuestion === idQuestion) {
                    const questionObj = {
                        ...questionKey,
                        isAnswered: true,
                    };
                    return questionObj;
                } else {
                    return questionKey;
                }
            });
            setQuestionsOpacity(newQuestionOpacity);
            handleScroll(`section-${idQuestion + 1}`);
        }
    }
    if (loading) {
        return (
            <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12 py-5">
                <LoaderSurvey />
            </div>
        )
    }else {
        return (
            <div className="col-12 bg-white-smoke" >
                <ModalLoaderGen />
                <div className='row'>
                    <div className='col-sm-12 col-md-11 col-lg-8 col-xl-8 mx-auto sticky-top bg-white-smoke'>
                        <div className="row align-self-center">
                            <div className="col-sx-10 col-sm-10 col-md-10 col-lg-10 mx-auto d-flex justify-content-center font-medium-5">
                                <span className="text-blue-sec font-weight-normal fs-1-3em">Hábitos</span>
                            </div>
                            <div className="col-12 mx-auto ">
                                <div
                                    className="progress"
                                    style={{ backgroundColor: '#c3c3c3', height: '.5rem' }}
                                >
                                    <div
                                        className="progress-bar"
                                        style={barOptions(percentageBar)}
                                    ></div>
                                </div>
                            </div>
                            <div className="col-12 mx-auto my-0 d-flex justify-content-end">
                                {percentageBar > 0 &&
                                    <span className="text-blue-sec fs-0-95em">{`${percentageBar}%`}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-11 col-lg-8 col-xl-8 mx-auto'>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <p className="text-center fs-1-5em">¡Hola <span className='font-weight-bold'>{collabName}</span>!</p>
                                    </div>
                                    <div className='col-12'>
                                        <img
                                            className='img-fluid'
                                            src='../img/filtroClinico/5-UltimasPreguntas.png'
                                            alt='Ultimas Preguntas'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-11 col-lg-8 col-xl-8 mx-auto'>
                        {
                            questions.map((question, indiceQuestion) => {
                                return (
                                    <div
                                        key={indiceQuestion}
                                        className={`${indiceQuestion === 0 ? 'col-12 mt-1 px-0' : questionsOpacity.some(questionOpacity => questionOpacity.idQuestion === question.idQuestion - 1 && !questionOpacity.isAnswered) ? 'col-12 mt-1 px-0 div-dissable-style-2' : 'col-12 mt-1 px-0'}`}
                                        id={`section-${question.idQuestion}`}
                                    >
                                        <div className={`${indiceQuestion === 0 ? 'card shadow-card-2' : questionsOpacity.some(questionOpacity => questionOpacity.idQuestion === question.idQuestion - 1 && !questionOpacity.isAnswered) ? 'card' : 'card shadow-card-2'}`}>
                                            <div className="card-header bg-white-guay font-weight-bold fs-1-2em border-bottom-black-opacity py-1" id={`Q-${question.idQuestion}`}>
                                                <span>{question.description}</span>
                                            </div>
                                            <div className="card-body">
                                                <RadioGroup>
                                                    {answers.map((answer, indiceAnswer) => (
                                                        answer.idQuestion === question.idQuestion && (
                                                            <div key={indiceAnswer}>
                                                                <div className="col-sx-12 col-sm-12 col-md-12 col-lg-12">
                                                                    <FormControlLabel
                                                                        value={answer.idAnswer}
                                                                        onChange={() => handleChange(answer)}
                                                                        control={
                                                                            <Radio
                                                                                icon={<i className="ft ft-circle text-blue-sec align-middle" />}
                                                                                checkedIcon={<i className="ft ft-check-circle text-blue-sec align-middle" />}
                                                                                disabled={indiceQuestion === 0 ? false : questionsOpacity.some(questionOpacity => questionOpacity.idQuestion === question.idQuestion - 1 && !questionOpacity.isAnswered) ? true : false}
                                                                                checked={answerAnswered.some(answerAns => answerAns.idAnswer === answer.idAnswer)}
                                                                                size="small"
                                                                            />
                                                                        }
                                                                        label={<span className='fs-1-2em'>{answer.description}</span>}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    ))}
                                                </RadioGroup>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div
                        className={
                            `${percentageBar === 100
                                ? 'col-sm-12 col-md-11 col-lg-8 col-xl-8 mx-auto my-2 font-medium-3 d-flex justify-content-center'
                                : 'col-sm-12 col-md-11 col-lg-8 col-xl-8 mx-auto my-2 font-medium-3 d-flex justify-content-center div-dissable-style-2'}`
                        }
                    >
                        <button
                            onClick={() => {
                                setInProcess(true);
                                handleClickEndSurvey();
                            }}
                            className={
                                `${percentageBar === 100
                                    ? 'border-primary-1 bg-primary-1 text-white px-3 rounded-pill-1 float-right pointer'
                                    : 'border-primary-1 bg-primary-1 text-white px-3 rounded-pill-1 float-right'}`
                            }
                        >Terminar</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Habits;
